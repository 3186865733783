import {
  Card,
  Checkbox,
  Collapse,
  List,
  Typography,
  Divider,
  Col,
  Row,
  Empty,
} from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";

import {
  convertIntoUtcLocalTime,
  epochTimeToDashUSFormat,
} from "utilities/dateUtility";

import K from "utilities/constants";
import VBDStaticComponent from "./vbdStaticComponent";
import LayoutCss from "layout/layout.module.scss";

const { Panel } = Collapse;
const { Title, Text } = Typography;
export default function VBDHistoryRule({
  historyRules,
  getSelectedTiers,
  tierListing,
  getSelectedCandidateTypes,
}) {
  return (
    <>
      {historyRules?.length > 0 ? (
        historyRules.map((item) => (
          <div key={item.id}>
            <Collapse
              expandIconPosition="end"
              bordered={false}
              className={LayoutCss.appListingCardPanel + " appListingCardPanel"}
            >
              <Panel
                header={
                  <>
                    <Text className="upcoming-date">
                      <span>Created Date:</span>
                      {convertIntoUtcLocalTime(item.createdAt)}
                    </Text>
                    <Divider type="vertical" />
                    <Text className="upcoming-date">
                      <span>Start Date:</span>
                      {epochTimeToDashUSFormat(item.effectiveSince)}
                    </Text>
                  </>
                }
                className={LayoutCss.appListingCardPanelBorder}
              >
                <Card className={styles.clientBillingRulesCard}>
                  <Card
                    title="Settings"
                    bodyStyle={{ paddingBottom: 0 }}
                    className={"appCard mb-3 " + LayoutCss.appCard}
                  >
                    <Row>
                      <Col md={12} className="tier-wrap">
                        <Title level={5} className="mb-4">
                          Tier Listings
                        </Title>

                        <Checkbox.Group
                          className="w-100"
                          disabled
                          value={getSelectedTiers(item.tierIds ?? []).map(
                            ({ id }) => id,
                          )}
                          //onChange={handleTierSetting}
                        >
                          <Row gutter={[0, 20]}>
                            {tierListing?.map((item, index) => (
                              <Col xs={24} sm={12} md={8} key={item.id}>
                                <Checkbox
                                  // key={index}
                                  checked
                                  id={item.id}
                                  value={item.id}
                                >
                                  {item.name}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      </Col>
                      <Col className="tier-wrap" md={12}>
                        <Title level={5} className="mb-4">
                          Candidate Type
                        </Title>
                        <Checkbox.Group
                          disabled
                          value={getSelectedCandidateTypes(item.hireTypes)}
                        >
                          <Row gutter={[0, 20]}>
                            {K.hireType.map((type, index) => (
                              <Col xs={24} sm={12} md={8} key={type.value}>
                                <Checkbox
                                  checked={true}
                                  id={type.value}
                                  value={type.value}
                                >
                                  {type.label}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      </Col>
                    </Row>
                  </Card>

                  {getSelectedTiers(item?.tierIds).length > 0 ? (
                    <div className="threshold-wrap">
                      <List
                        className="teir-list"
                        dataSource={getSelectedTiers(item?.tierIds) ?? []}
                        renderItem={(item) => (
                          <List.Item key={item.name}>{item.name}</List.Item>
                        )}
                      />

                      {getSelectedTiers(item?.tierIds).length > 0 &&
                        item.vbdThresholds.map((threshold, index) => (
                          <VBDStaticComponent
                            key={threshold.id}
                            threshold={threshold}
                            selectedTierId={item?.tierIds}
                            getSelectedTiers={getSelectedTiers}
                            data={item.vbdThresholds ?? []}
                            index={index}
                          />
                        ))}
                    </div>
                  ) : (
                    <Empty />
                  )}
                </Card>
              </Panel>
            </Collapse>
          </div>
        ))
      ) : (
        <Empty />
      )}
    </>
  );
}
