import { Divider, PageHeader, Tabs } from "antd";
import LayoutCss from "../../layout/layout.module.scss";
import { pick } from "lodash";
import { TabsConfig } from "./tabsConfig";
import { ParseString, useQueryState } from "../../hooks/useQueryState";
import { EngagementsContainer } from "./engagementsContainer/engagementsContainer";

export const Engagements = () => {
  const [tab, setTab] = useQueryState(
    "tab",
    ParseString,
    TabsConfig.thisWeek.key,
  );

  const activeTab = TabsConfig[tab];

  const tabItems = Object.values(TabsConfig).map((t) =>
    pick(t, ["key", "label"]),
  );

  return (
    <>
      <PageHeader
        ghost={false}
        title="Engagements"
        footer={
          <>
            <Divider className={LayoutCss.appPageHeaderDivider} />
            <Tabs
              activeKey={activeTab.key}
              onChange={setTab}
              className={LayoutCss.appPageHeaderTabs}
              items={tabItems}
            />
          </>
        }
        className={LayoutCss.appPageHeader}
      />
      <EngagementsContainer
        getData={activeTab.getData}
        getConfig={activeTab.getConfig}
        saveConfig={activeTab.saveConfig}
        onExport={activeTab.onExport}
        renderTable={activeTab.renderComponent}
      />
    </>
  );
};
