import { useSelector } from "react-redux";
import { selectSelectedClientIds } from "../../../../redux/slices/globalSelectorSlice";
import {
  selectAccessibleClientsBySelection,
  selectUserId,
} from "../../../../redux/slices/userSlice";
import { ServerSideDataGrid } from "../../../../common/components/serverSideDataGrid/serverSideDataGrid";
import { useCallback, useMemo, useRef, useState } from "react";
import User from "../../../../redux/models/user";
import K from "../../../../utilities/constants";
import { Card, message } from "antd";
import LayoutCss from "../../../../layout/layout.module.scss";
import { getColumns, DefaultColDef } from "./columns";
import { ClientTableauConfigs } from "../../../../redux/models/clientTableauConfigs";
import { CreateConfigButton } from "./createConfigButton/createConfigButton";
import { differenceWith } from "lodash";

export const ClientReportConfigsContainer = () => {
  const selectedClientIds = useSelector(selectSelectedClientIds);
  const accessibleClients = useSelector(selectAccessibleClientsBySelection);
  const userId = useSelector(selectUserId);

  const gridRef = useRef(null);

  const [clientIdsWithConfigs, setClientIdsWithConfigs] = useState([]);
  const [loading, setLoading] = useState(true);

  const clientsWithoutConfig = differenceWith(
    accessibleClients,
    clientIdsWithConfigs,
    (a, b) => a.id === b,
  );

  const isCreateButtonDisabled = loading || !clientsWithoutConfig.length;

  const onConfigCreated = (config) => {
    if (!clientIdsWithConfigs.length) {
      gridRef.current.api.hideOverlay();
    }

    setClientIdsWithConfigs((data) => [...data, config.clientId]);
    gridRef.current.api.applyServerSideTransaction({
      add: [config],
    });
  };

  const onConfigUpdated = (config) => {
    gridRef.current.api.applyServerSideTransaction({
      update: [config],
    });

    gridRef.current.api.refreshCells({ force: true });
  };

  const columns = useMemo(
    () => getColumns(accessibleClients, onConfigUpdated),
    [accessibleClients],
  );

  const getServerSideData = useCallback(
    (options) => {
      return ClientTableauConfigs.getAll({
        ...options,
        client: selectedClientIds,
      });
    },
    [selectedClientIds],
  );

  const getConfig = useCallback(() => {
    return User.getConfigrations(
      K.AgGridTable.Keys.ClientTableauReportsConfigsVisibleColumn,
    );
  }, []);

  const saveColumnConfig = useCallback(
    (config) => {
      return User.saveColumnSort({
        usersId: userId,
        configJson: JSON.stringify(config),
        tableName: K.AgGridTable.Keys.ClientTableauReportsConfigsVisibleColumn,
      });
    },
    [userId],
  );

  const onDataFetchSuccess = useCallback(
    (data) => {
      setLoading(false);
      setClientIdsWithConfigs(data.rowData.map((c) => c.clientId));
    },
    [setClientIdsWithConfigs],
  );

  const onDataFetchFailed = useCallback(
    (err) => message.error(err.message),
    [],
  );

  return (
    <Card
      className={LayoutCss.appCard}
      title={"Client Reports Configurations"}
      extra={
        <CreateConfigButton
          clients={clientsWithoutConfig}
          disabled={isCreateButtonDisabled}
          onSuccess={onConfigCreated}
        />
      }
    >
      <ServerSideDataGrid
        ref={gridRef}
        getData={getServerSideData}
        getColumnConfig={getConfig}
        saveColumnConfig={saveColumnConfig}
        defaultColumnConfig={columns}
        defaultColDef={DefaultColDef}
        onDataFetchSuccess={onDataFetchSuccess}
        onDataFetchFailed={onDataFetchFailed}
      />
    </Card>
  );
};
