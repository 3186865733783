import Engagement from "../../redux/models/engagement";
import User from "../../redux/models/user";
import K from "../../utilities/constants";
import Export from "../../redux/models/export";
import { ServerSideDataGrid } from "../../common/components/serverSideDataGrid/serverSideDataGrid";

export const TabsConfig = {
  thisWeek: {
    key: "thisWeek",
    label: "Due This Week",

    getData: (params) =>
      Engagement.get({
        ...params,
        timeFrame: "thisWeek",
        state: "pending",
        candidateJobStatusId: 9,
      }),

    getConfig: () =>
      User.getConfigrations(
        K.AgGridTable.Keys.ThisWeekEngagementsVisibleColumn,
      ),

    saveConfig: (params) =>
      User.saveColumnSort({
        ...params,
        tableName: K.AgGridTable.Keys.ThisWeekEngagementsVisibleColumn,
      }),

    onExport: (params) =>
      Export.getReportEngagements({
        ...params,
        timeFrame: "thisWeek",
        state: "pending",
        candidateJobStatusId: 9,
      }),

    renderComponent: (props) => (
      <ServerSideDataGrid key={"thisWeek"} {...props} />
    ),
  },
  pastDue: {
    key: "pastDue",
    label: "Past Due",

    getData: (params) =>
      Engagement.get({
        ...params,
        timeFrame: "pastDue",
        state: "pending",
        candidateJobStatusId: 9,
      }),

    getConfig: () =>
      User.getConfigrations(K.AgGridTable.Keys.PastDueEngagementsVisibleColumn),

    saveConfig: (params) =>
      User.saveColumnSort({
        ...params,
        tableName: K.AgGridTable.Keys.PastDueEngagementsVisibleColumn,
      }),

    onExport: (params) =>
      Export.getReportEngagements({
        ...params,
        timeFrame: "pastDue",
        state: "pending",
        candidateJobStatusId: 9,
      }),

    renderComponent: (props) => (
      <ServerSideDataGrid key={"pastDue"} {...props} />
    ),
  },
  future: {
    key: "future",
    label: "Future",

    getData: (params) =>
      Engagement.get({
        ...params,
        timeFrame: "future",
        state: "pending",
        candidateJobStatusId: 9,
      }),

    getConfig: () =>
      User.getConfigrations(K.AgGridTable.Keys.FutureEngagementsVisibleColumn),

    saveConfig: (params) =>
      User.saveColumnSort({
        ...params,
        tableName: K.AgGridTable.Keys.FutureEngagementsVisibleColumn,
      }),

    onExport: (params) =>
      Export.getReportEngagements({
        ...params,
        timeFrame: "future",
        state: "pending",
        candidateJobStatusId: 9,
      }),

    renderComponent: (props) => (
      <ServerSideDataGrid key={"future"} {...props} />
    ),
  },
};
