import NetworkCall from "network/networkCall";
import Request from "network/request";

export class ClientTableauReports {
  static async getToken(clientId, reportType) {
    return NetworkCall.fetch(
      Request.v2.getClientTableauReportToken(clientId, reportType),
      false,
      false,
    );
  }
}
