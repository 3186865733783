import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";
import { JobBillingRulesForm } from "features/clients/create/jobBillingRulesForm";
import LayoutCss from "layout/layout.module.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BillingJob from "redux/models/billingJobs";
import Client from "redux/models/client";
import { selectConfigration } from "redux/slices/configrationSlice";
import K from "utilities/constants";
import {
  convertIntoDashUSFormat,
  epochTimeToDashUSFormat,
} from "utilities/dateUtility";
import {
  formatJobRulesEntries,
  isPermissionPresent,
} from "utilities/generalUtility";
import ClientAndJobBillingComponent from "./clientAndJobBillingComponent";
import JobHistoryRule from "./jobHistoryRule";
import JobUpcomingRule from "./jobUpcomingRule";
import LookupTable from "redux/models/lookupTable";
import EditTierComponent from "./editTierComponent";
import { selectUser } from "redux/slices/userSlice";
import moment from "moment";

const { Title, Paragraph } = Typography;
const { Lookup } = K.Network.URL;

export default function JobBillingRulesDetail({ setCurrentPage, tierId }) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const userSlice = useSelector(selectUser);
  const [editTierForm] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditVisible, setIsEditModalVisible] = useState(false);
  const configrationSlice = useSelector(selectConfigration);
  const [billingRule, setBillingRule] = useState("upcoming");
  const [pageStates, setPageStates] = useState({
    render: false,
    tierData: null,
    isLoading: false,
    isModalVisible: false,
    isBackDateModalVisible: false,
  });
  const showModal = () => {
    setPageStates((prev) => ({
      ...prev,
      isModalVisible: true,
    }));
  };

  const disabledEditStartDateDate = (current) => {
    const historyRules = pageStates.tierData?.histroyRules;
    // * Can not select days before today and today
    const historyDate = historyRules.length
      ? moment.unix(historyRules[0].startDate).format(K.DateFormat.Response)
      : null;

    return (
      (current && current < moment(historyDate).endOf("day")) ||
      (current && current > moment().endOf("day"))
    );
  };
  const showEditModal = () => {
    populateFormValues();
    setPageStates((prev) => ({
      ...prev,
      isModalVisible: true,
    }));
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setPageStates((prev) => ({
      ...prev,
      isModalVisible: false,
    }));
  };

  const handleBackDateCancel = () => {
    setPageStates((prev) => ({
      ...prev,
      isBackDateModalVisible: false,
    }));
  };
  const showEditStartDate = () => {
    populateFormValues();
    setPageStates((prev) => ({ ...prev, isBackDateModalVisible: true }));
  };

  const populateFormValues = () => {
    const formValues = formatJobRulesEntries(pageStates.tierData.currentRule);
    form.setFieldsValue(formValues);
  };

  const onFinish = async (values, isBackDate = false) => {
    setPageStates((prev) => ({ ...prev, isLoading: true }));

    const jobLevelBilligRulesValues = values.billingTypes.map((v) => {
      const ruleObj = { isInfinite: 0, ...values[v] };
      return {
        ...ruleObj,
        endAfterOccurances:
          ruleObj.isInfinite === 1 ? 0 : ruleObj.endAfterOccurances,
        jobBillingTypesId: v,
      };
    });

    const body = {
      clientId: id,
      tierId: tierId.current,
      effectiveSince: values["effectiveSince"].unix(),
      jobLevelBillingRules: [
        {
          guaranteeDays: values["guaranteeDays"],
          isWorkingDays: values["isWorkingDays"] === true ? 1 : 0,
          jobLevelBilligRulesValues,
        },
      ],
    };

    if (isBackDate) {
      const payload = {
        ...body,
        billingRuleId: pageStates.tierData.currentRule.id,
        latestPreviousRuleId:
          pageStates.tierData.histroyRules.length > 0
            ? pageStates.tierData.histroyRules[0].id
            : null,
      };

      await Client.editExistigJobLevelBillingRuleDate(payload);
      setPageStates((prev) => ({
        ...prev,
        render: !prev.render,
        isLoading: false,
        isBackDateModalVisible: false,
      }));
    } else {
      try {
        await Client.addJobLevelBillingRules(body);
        setPageStates((prev) => ({
          ...prev,
          render: !prev.render,
          isLoading: false,
          isModalVisible: false,
        }));
      } catch (error) {
        setPageStates((prev) => ({ ...prev, isLoading: false }));
        console.error(error);
      }
    }
  };
  const getAllCurrencies = async () => {
    try {
      await dispatch(BillingJob.getCurrencies());
    } catch (err) {
      console.error(err);
    }
  };

  const getLookupData = async () => {
    try {
      await dispatch(
        LookupTable.getData(K.Redux.JobTitle, `${Lookup.JobTitle}/${id}`),
      );
    } catch (err) {
      console.error(err);
    }
  };

  const fetchBillings = async () => {
    try {
      const res = await BillingJob.getJobTierDetails(tierId.current, id);
      await dispatch(BillingJob.getAll());
      setPageStates((prev) => ({ ...prev, tierData: res }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBillings();
  }, [pageStates.render]);

  useEffect(() => {
    getAllCurrencies();
    getLookupData();
  }, []);

  const backHandler = () => {
    setCurrentPage(0);
  };

  const handleChange = (value) => {
    setBillingRule(value);
  };

  const billingRules = {
    upcoming: (
      <JobUpcomingRule
        tierData={pageStates.tierData}
        setPageStates={setPageStates}
        configrationSlice={configrationSlice}
      />
    ),
    history: <JobHistoryRule tierData={pageStates.tierData} />,
  };

  const editHandler = () => {
    setIsEditModalVisible(true);
  };

  const onOk = () => {
    editTierForm.submit();
  };

  const cancelHandler = () => {
    setIsEditModalVisible(false);
  };

  const afterAddModalClose = () => {
    form.resetFields();
  };
  const afterModalClose = () => {
    editTierForm.resetFields();
  };

  const updateTierDetails = async (body) => {
    try {
      const res = await Client.updateTierDetails(body);
      setIsLoading(false);
      setPageStates((prev) => ({
        ...prev,
        tierData: {
          ...prev.tierData,
          name: res.name,
          jobTierTitle: res.jobTierTitle,
        },
      }));
      setIsEditModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onFinishEdit = (values) => {
    setIsLoading(true);
    const body = {
      tierId: tierId.current,
      tierName: values.tierName,
      titleAssociated: values.titleAssociated
        .filter((item) => {
          return item?.jobTitleId !== undefined;
        })
        .map((obj) => {
          return obj.jobTitleId && { jobTitleId: obj.jobTitleId };
        }),
    };
    updateTierDetails(body);
  };

  return (
    <>
      <Card className={styles.clientBillingRulesCard}>
        <div className={styles.cbrHeader}>
          <Button type="link" className="backButton" onClick={backHandler}>
            <i className="icon-back-arrow"></i>Back
          </Button>
        </div>

        <Card
          title="Tier Detail"
          bodyStyle={{ paddingTop: 0 }}
          className="tierdetal-card"
          extra={
            <Button
              type="link"
              className={styles.editButton}
              onClick={editHandler}
            >
              <i className="icon-edit" onClick={showEditStartDate}></i>
            </Button>
          }
        >
          <Row gutter={15}>
            <Col md={8} lg={6}>
              <Title level={5} className={styles.cbrLabel}>
                Tier Name:
              </Title>
              <Paragraph className={styles.cbrValue + " cbrValue mb-0"}>
                {pageStates.tierData?.name}
              </Paragraph>
            </Col>

            <Col lg={18}>
              <Title level={5} className={styles.cbrLabel}>
                Title Associated
              </Title>
              <div className={styles.cbrTags + " cbrTags"}>
                {pageStates.tierData &&
                  pageStates.tierData.jobTierTitle.map((item) => (
                    <Tag title={item.jobTitle.name} key={item.id}>
                      {item.jobTitle.name}
                    </Tag>
                  ))}
              </div>
            </Col>
          </Row>
        </Card>

        <div className={styles.currentBillingRule}>
          <div className={styles.cbrTitle}>
            <Title level={5}>Current Billing Rule</Title>
            <Paragraph>
              <i className="icon-calendar"></i>
              {epochTimeToDashUSFormat(
                pageStates.tierData?.currentRule.startDate,
              )}
              &nbsp; &nbsp;
              <i className="icon-edit" onClick={showEditStartDate}></i>
            </Paragraph>

            <Paragraph>
              <i className="icon-user"></i>
              Updated by :{" "}
              {`${
                pageStates.tierData?.currentRule?.user?.name ?? "N/A"
              } at ${convertIntoDashUSFormat(
                pageStates.tierData?.currentRule?.createdAt,
              )}`}
            </Paragraph>
          </div>
          <Divider className={styles.dividerLine} plain />
          {/* {
            <Button className={styles.addButton} onClick={showModal}>
              Add New
            </Button>
          } */}

          <div className={styles.cbrTitle}>
            <Title level={5}>Current Guarantee Period</Title>
            <Paragraph>
              {`${pageStates.tierData?.currentRule.guaranteeDays ?? 0} ${
                pageStates.tierData?.currentRule.isWorkingDays
                  ? "working days"
                  : "calendar days"
              }`}
            </Paragraph>
          </div>
          {isPermissionPresent(
            K.Permissions.EditJobBillingRule,
            userSlice.roles,
          ) && (
            <Button className={styles.addButton} onClick={showEditModal}>
              Edit
            </Button>
          )}
        </div>
        <ClientAndJobBillingComponent
          currentRule={pageStates.tierData?.currentRule}
        />
      </Card>

      <div className={LayoutCss.invoicesButton}>
        <Select
          showSearch={false}
          defaultValue="upcoming"
          onChange={handleChange}
          className={LayoutCss.invoicesButtonSet}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          options={[
            { value: "history", label: "History" },
            { value: "upcoming", label: "Upcoming" },
          ]}
        />
      </div>

      {billingRules[billingRule]}

      <Modal
        width={568}
        centered
        className="s2-theme-style edit-billing-modal"
        open={isEditVisible}
        closeIcon={<i className="icon-closeable"></i>}
        okText="Update"
        title="Edit Job Billing"
        wrapClassName="vertical-center-modal"
        okButtonProps={{ loading: isLoading }}
        onOk={onOk}
        onCancel={cancelHandler}
        afterClose={afterModalClose}
        destroyOnClose
      >
        <EditTierComponent
          editForm={editTierForm}
          tierData={pageStates.tierData}
          onFinishEdit={onFinishEdit}
          clientId={id}
        />
      </Modal>

      <Modal
        width={568}
        centered
        className={styles.addButtonModule + " modal-overflow-auto"}
        open={pageStates.isModalVisible}
        closable={false}
        afterClose={afterAddModalClose}
        onCancel={handleCancel}
        destroyOnClose
        footer={
          <>
            <Button
              className={styles.cancelBillingRules}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              loading={pageStates.isLoading}
              onClick={handleOk}
            >
              Save
            </Button>
          </>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <JobBillingRulesForm
            form={form}
            styles={styles}
            currencyList={configrationSlice.currencies}
            showEffectiveSince={true}
            billingJobsList={configrationSlice.billingJobs.filter(
              ({ isAssignable }) => isAssignable,
            )}
          />
        </Form>
      </Modal>

      <Modal
        width={568}
        centered
        className={styles.addButtonModule}
        open={pageStates.isBackDateModalVisible}
        closable={false}
        afterClose={afterAddModalClose}
        onCancel={handleBackDateCancel}
        destroyOnClose
        footer={
          <>
            <Button
              className={styles.cancelBillingRules}
              onClick={handleBackDateCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              loading={pageStates.isLoading}
              onClick={handleOk}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => onFinish(values, true)}
        >
          <JobBillingRulesForm
            form={form}
            styles={styles}
            currencyList={configrationSlice.currencies}
            showEffectiveSince={true}
            billingJobsList={configrationSlice.billingJobs.filter(
              ({ isAssignable }) => isAssignable,
            )}
            isBackDate={true}
            disabledStartDate={disabledEditStartDateDate}
          />
        </Form>
      </Modal>
    </>
  );
}
