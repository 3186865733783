import { useState } from "react";
import { Button, message, Tooltip } from "antd";
import { ClientTableauConfigs } from "../../../../../redux/models/clientTableauConfigs";
import { PlusOutlined } from "@ant-design/icons";
import { CreateConfigModal } from "./createConfigModal";

export const CreateConfigButton = ({
  clients,
  onSuccess,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const availableClients = clients.map((c) => ({ label: c.name, value: c.id }));

  const tooltipTitle = !availableClients.length
    ? "No available clients to add configuration."
    : "";

  const onFinish = async (values) => {
    const { clientId, ...data } = values;

    try {
      setLoading(true);
      const res = await ClientTableauConfigs.create(clientId, data);

      onSuccess(res.data);
      setOpen(false);
      message.success("Reports configuration successfully created");
    } catch (e) {
      message.error(e.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title={tooltipTitle} placement={"left"}>
        <span>
          <Button
            type="primary"
            icon={<PlusOutlined style={{ fontSize: "0.8em" }} />}
            disabled={loading || disabled}
            onClick={() => setOpen(true)}
          >
            Add new
          </Button>
        </span>
      </Tooltip>
      {open && (
        <CreateConfigModal
          open={open}
          onCancel={() => setOpen(false)}
          loading={loading}
          onFinish={onFinish}
          availableClients={availableClients}
        />
      )}
    </>
  );
};
