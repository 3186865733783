import NetworkCall from "network/networkCall";
import Request from "network/request";

export class ClientTableauConfigs {
  static async create(clientId, data) {
    return NetworkCall.fetch(
      Request.v2.createClientTableauConfig(clientId, data),
      true,
      false,
    );
  }

  static async update(clientId, data) {
    return NetworkCall.fetch(
      Request.v2.updateClientTableauConfig(clientId, data),
      true,
      false,
    );
  }

  static async getAll(params) {
    return NetworkCall.fetch(
      Request.v2.getClientTableauConfigs(params),
      true,
      false,
    );
  }
}
