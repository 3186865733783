import React, { useEffect, useState } from "react";
import { PageHeader, Button, Card, Form, Divider, Popconfirm } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import LayoutCss from "layout/layout.module.scss";
import styles from "./commissionPlans.module.scss";
import { useHistory, useParams } from "react-router-dom";
import CreateCommissionPlanComponent from "./createCommissionPlanComponent";
import ClientException from "./clientException";
import CommissionPlan from "redux/models/commissionPlan";
import Client from "redux/models/client";

export default function CreateCommissionPlans() {
  const { planId } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [clientListing, setClientListing] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const getClientExceptions = (exceptions) => {
    return exceptions.map((ex) => ({
      clientId: ex.clientId,
      data: {
        appliedHire: ex.data.appliedHire,
        cancellations: ex.data.cancellations,
        hiringEvent: ex.data.hiringEvent,
        referral: ex.data.referral,
        sourceHired: ex.data.sourceHired,
        reHireReferral: ex.data.reHireReferral,
        reHireApplied: ex.data.reHireApplied,
        reHireSourced: ex.data.reHireSourced,
        internal: ex.data.internal,
      },
    }));
  };

  const createCommissionPlan = async (payload) => {
    const clientExceptions = getClientExceptions(payload?.clientExceptions);
    const body = {
      planName: payload.planName,
      commissionPlan: payload.commissionPlan,
      clientExceptions,
    };

    try {
      await CommissionPlan.create(body);
      form.resetFields();
      history.replace("/configurations/commission-plans");
    } catch (error) {
      console.error(error);
    }
  };
  const addNewCommissionPlan = async (values, payload) => {
    const clientExceptions = getClientExceptions(payload?.clientExceptions);

    const startDate = values.startDate.unix();
    const body = {
      startDate,
      commissionId: planId,
      commissionPlan: payload.commissionPlan,
      clientExceptions,
    };

    try {
      await CommissionPlan.addNewCommissionPlan(body);
      form.resetFields();
      history.replace(`/configurations/commission-plans/detail/${planId}`);
    } catch (error) {
      console.error(error);
    }
  };

  const onFinish = async (values) => {
    const { planName, clientExceptions, ...commissionPlan } = values;
    const clientBody = [];
    clientExceptions &&
      clientExceptions.forEach((item) => {
        const { clientId, ...data } = item;
        clientBody.push({ clientId, data });
      });
    const payload = { planName, commissionPlan, clientExceptions: clientBody };
    if (planId) {
      addNewCommissionPlan(values, payload, clientBody);
    } else {
      createCommissionPlan(payload);
    }
  };

  const getAllClientName = async () => {
    try {
      const res = await Client.getAllClientName();
      setClientListing(res);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllClientName();
  }, []);

  return (
    <>
      <PageHeader
        className={`${LayoutCss.appPageHeader} ${LayoutCss.pageHeader} `}
        ghost={false}
        title="Commission Plans"
        extra={
          <>
            <Button onClick={history.goBack} className={LayoutCss.appHeaderBtn}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={form.submit}
              className={LayoutCss.appHeaderBtn}
            >
              Save
            </Button>
          </>
        }
      />
      <CreateCommissionPlanComponent form={form} planId={planId} />
      <Card
        title="Client Exception"
        className={`${styles.cardWrap} ${LayoutCss.appCard}`}
        style={{ marginTop: "24px", marginBottom: "24px" }}
      >
        <Form
          form={form}
          layout="vertical"
          className={styles.commissionForm}
          onFinish={onFinish}
        >
          <Form.List name="clientExceptions">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div
                    className={"billingUnitForm " + styles.billingUnitForm}
                    key={index}
                  >
                    <div className={styles.billingUnitRemoveBtn}>
                      <Divider className={styles.componentDivider} />
                      <Popconfirm
                        title="Are you sure ?"
                        onConfirm={() => {
                          remove(field.name);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          danger
                          className={styles.deleteBtn}
                          icon={<i className="icon-delet"></i>}
                        ></Button>
                      </Popconfirm>
                    </div>
                    <ClientException
                      form={form}
                      parentIndex={index}
                      isDisabled={isDisabled}
                      clientListing={clientListing}
                      setIsDisabled={setIsDisabled}
                    />
                  </div>
                ))}
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className={styles.addClient}
                  onClick={add}
                >
                  Add Client Exception
                </Button>
              </>
            )}
          </Form.List>
        </Form>
      </Card>
    </>
  );
}
