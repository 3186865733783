import { Col, Form, InputNumber, Row, Select } from "antd";
import K from "../../../utilities/constants";
import LayoutCss from "../../../layout/layout.module.scss";

export const CommissionPlanRule = ({ label, itemKey, parentIndex }) => {
  const getName = (key) =>
    parentIndex !== undefined ? [parentIndex, key] : [key];
  return (
    <Col sm={12} xs={24}>
      <Row gutter={16} align="bottom">
        <Col sm={16} xs={24}>
          <Form.Item
            label={label}
            name={[...getName(itemKey), "value"]}
            rules={[{ required: true, message: `${label} is required.` }]}
          >
            <InputNumber placeholder="Enter" size="medium" min={0} />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            name={[...getName(itemKey), "unit"]}
            initialValue={K.Format.Dollar}
          >
            <Select
              showSearch={false}
              className={LayoutCss.appListingCardStatusSelect}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              options={[
                { label: "$", value: K.Format.Dollar },
                { label: "%", value: K.Format.Percentage },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
};
