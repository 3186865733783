import K from "utilities/constants";
import { Divider, PageHeader, Tabs } from "antd";
import { ParseString, useQueryState } from "../../hooks/useQueryState";
import { TableauReportContainer } from "./TableauReportsContainer/tableauReportContainer";
import { pick } from "lodash";
import LayoutCss from "../../layout/layout.module.scss";

const TabsConfig = {
  weekly: {
    key: "weekly",
    label: "Weekly",
    reportType: K.Clients.TableauReports.Type.Weekly,
  },
  monthly: {
    key: "monthly",
    label: "Monthly",
    reportType: K.Clients.TableauReports.Type.Monthly,
  },
};

export const ClientReporting = () => {
  const [tab, setTab] = useQueryState(
    "tab",
    ParseString,
    TabsConfig.weekly.key,
  );

  const activeTab = TabsConfig[tab];

  const tabItems = Object.values(TabsConfig).map((t) =>
    pick(t, ["key", "label"]),
  );

  return (
    <>
      <PageHeader
        ghost={false}
        title="Client Reporting"
        footer={
          <>
            <Divider className={LayoutCss.appPageHeaderDivider} />
            <Tabs
              activeKey={activeTab.key}
              onChange={setTab}
              className={LayoutCss.appPageHeaderTabs}
              items={tabItems}
            />
          </>
        }
        className={LayoutCss.appPageHeader}
      />
      <TableauReportContainer type={activeTab.reportType} />
    </>
  );
};
