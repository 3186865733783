import { Card, Checkbox, Col, Form, InputNumber, Row, Select } from "antd";
import K from "utilities/constants";
import styles from "./recursiveComponent.module.scss";

const { RecurringType } = K;
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const options = Array.from({ length: 28 }).map((_, i) => ({
  label: i + 1,
  value: i + 1,
}));

export default function RecursiveComponent({
  fieldObj,
  parentIndex,
  currencyList,
}) {
  const formItemName =
    parentIndex !== undefined ? [parentIndex, fieldObj.id] : [fieldObj.id];

  const shouldUpdate = (prevValues, currValues) => {
    if (parentIndex !== undefined) {
      return (
        prevValues.jobLevelBillingRules[parentIndex][fieldObj.id]
          .recurringType !==
        currValues.jobLevelBillingRules[parentIndex][fieldObj.id].recurringType
      );
    }
    return (
      prevValues[fieldObj.id].recurringType !==
      currValues[fieldObj.id].recurringType
    );
  };

  const recurringComponent = {
    [RecurringType.Week]: (
      <>
        <Form.Item
          name={[...formItemName, "selectedDays"]}
          rules={[
            {
              required: true,
              message: "Please select week day!",
            },
          ]}
        >
          <Checkbox.Group
            className={`recurringCheckboxGroup ${styles.recurringCheckboxGroup}`}
          >
            {days.map((checkbox, i) => (
              <Checkbox className={styles.recurringCheckbox} key={i} value={i}>
                {checkbox}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </>
    ),
    [RecurringType.Month]: (
      <Row gutter={[12, 0]}>
        <Col xs={24} xl={16}>
          <Form.Item
            className={styles.formItemDate}
            label="On this date"
            name={[...formItemName, "repetitiveMonthDay"]}
            rules={[
              {
                required: true,
                message: "Please select month day!",
              },
              {
                pattern: new RegExp("^[0-9]*$", "g"),
                message: "Decimal values are not allowed!",
              },
            ]}
          >
            <Select
              showSearch
              className={styles.recurringDateSelect}
              placeholder="Select"
              optionFilterProp="label"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              options={options}
            />
          </Form.Item>
        </Col>
      </Row>
    ),
  };

  return (
    <>
      <Card title={fieldObj.name} className={styles.fieldSet}>
        <Row align="bottom" gutter={[12, 0]}>
          <Col xs={24} md={24} xl={12} className={styles.recursiveAmount}>
            <Form.Item
              className={styles.formItemRepeat}
              name={[...formItemName, "amount"]}
              label="Amount"
              rules={[{ required: true, message: "Please enter amount!" }]}
            >
              <InputNumber placeholder="Amount" min={0} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              name={[...formItemName, "currency"]}
              initialValue={"USD"}
            >
              <Select
                showSearch={false}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="Currency"
                options={currencyList.map(({ code }) => ({
                  value: code,
                  label: code,
                }))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              name={[...formItemName, "amountType"]}
              initialValue={K.Format.Dollar}
            >
              <Select
                showSearch={true}
                disabled={!fieldObj.isDependentOn}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                options={[
                  { value: K.Format.Dollar, label: "$" },
                  { value: K.Format.Percentage, label: "%" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Referral Fee"
              name={[...formItemName, "referral"]}
              rules={[
                {
                  required: true,
                  message: "Please enter referral fee!",
                },
              ]}
            >
              <InputNumber placeholder="Referral Fee" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Rehire Fee"
              name={[...formItemName, "reHired"]}
              rules={[
                {
                  required: true,
                  message: "Please enter rehire fee!",
                },
              ]}
            >
              <InputNumber placeholder="Rehire Fee" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Internal Fee"
              name={[...formItemName, "internal"]}
              rules={[
                {
                  required: true,
                  message: "Please enter internal fee!",
                },
              ]}
            >
              <InputNumber placeholder="Internal" />
            </Form.Item>
          </Col>
        </Row>
        <Row align="bottom" gutter={[12, 0]}>
          <Col xs={24} xl={8} className={styles.recursiveAmount}>
            <Form.Item
              className={styles.formItemRepeat}
              name={[...formItemName, "repetitiveQuantity"]}
              label="Repeat every"
              rules={[
                { required: true, message: "Enter repeat every value!" },
                {
                  pattern: new RegExp("^[0-9]*$", "g"),
                  message: "Decimal values are not allowed!",
                },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8} className={styles.recursiveAmount}>
            <Form.Item
              name={[...formItemName, "recurringType"]}
              initialValue={RecurringType.Week}
            >
              <Select
                showSearch={true}
                placeholder="Week"
                optionFilterProp="label"
                className={styles.repeatSelector}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                options={[
                  { value: RecurringType.Week, label: "Week" },
                  { value: RecurringType.Month, label: "Month" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className="mb-0" shouldUpdate={shouldUpdate}>
          {({ getFieldValue }) =>
            parentIndex !== undefined
              ? recurringComponent[
                  getFieldValue("jobLevelBillingRules")[parentIndex][
                    fieldObj.id
                  ].recurringType
                ]
              : recurringComponent[getFieldValue(fieldObj.id).recurringType]
          }
        </Form.Item>

        <Form.Item
          label="Repeat Occurrences"
          name={[...formItemName, "endAfterOccurances"]}
          rules={[
            { required: true, message: "Repeat Occurrences is required." },
            {
              pattern: new RegExp("^[0-9]*$", "g"),
              message: "Decimal values are not allowed!",
            },
          ]}
        >
          <InputNumber placeholder="Repeat Occurrences" min={1} />
        </Form.Item>

        <Form.Item
          initialValue={false}
          valuePropName="checked"
          name={[...formItemName, "isCommissionable"]}
        >
          <Checkbox>Is Commissionable</Checkbox>
        </Form.Item>
      </Card>
    </>
  );
}
