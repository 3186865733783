import { useEffect, useState } from "react";
import { ClientTableauReports } from "../../../../redux/models/clientTableauReports";
import { ReportContent } from "./reportContent";

export const TableauReport = ({ type, clientId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);

  const getToken = async () => {
    try {
      setError(null);
      setLoading(true);
      const response = await ClientTableauReports.getToken(clientId, type);
      setToken(response.data);
    } catch (err) {
      setError(err);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getToken();
  }, [type, clientId]);

  return (
    <ReportContent
      loading={loading}
      error={error?.message}
      token={token?.token}
      url={token?.url}
    />
  );
};
