import { Result, Skeleton } from "antd";
import { useEffect, useState } from "react";

export const ReportContent = ({ loading, error, token, url }) => {
  const [embeddingError, setEmbeddingError] = useState(null);

  const errorToShow = error || embeddingError;

  const handleVizLoadError = (e) => {
    const errorCode = e.detail.errorCode;
    const message = JSON.parse(e.detail.message);
    const errorMessage = JSON.parse(message.errorMessage || "{}");

    const statusCode = errorMessage?.result?.errors[0]?.code || errorCode;

    setEmbeddingError(
      statusCode
        ? `Error occurs during embedding report. Error code: ${statusCode}`
        : "Something went wrong",
    );
  };

  const renderContent = () => {
    if (errorToShow) {
      return (
        <Result
          status={"error"}
          title="Report Loading Failed"
          subTitle={errorToShow}
        />
      );
    }

    return (
      <tableau-viz id="tableau-viz" src={url} token={token} toolbar="hidden" />
    );
  };

  useEffect(() => {
    const vizElement = document.getElementById("tableau-viz");

    if (vizElement) {
      vizElement.addEventListener("vizloaderror", handleVizLoadError);

      return () => {
        vizElement.removeEventListener("vizloaderror", handleVizLoadError);
      };
    }
  }, [url, token]);

  return (
    <div style={{ flexGrow: 1 }}>
      <Skeleton active loading={loading} paragraph={{ rows: 21 }}>
        {renderContent()}
      </Skeleton>
    </div>
  );
};
