import {
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Checkbox,
  Typography,
} from "antd";
import K from "utilities/constants";
import { disabledDate } from "utilities/dateUtility";
import OneTimeComponent from "./billingComponents/oneTimeComponent";
import RecursiveComponent from "./billingComponents/recursiveComponent";
import RetentionComponent from "./billingComponents/retentionComponent";
import TimeBasedComponent from "./billingComponents/timeBasedComponent";

const { Title } = Typography;

export function JobBillingRulesForm({
  form,
  styles,
  parentIndex,
  currencyList,
  billingJobsList,
  showEffectiveSince = false,
  isBackDate = false,
  disabledStartDate,
}) {
  const shouldUpdate = (prevValues, currValues) => {
    if (parentIndex !== undefined) {
      return (
        prevValues.jobLevelBillingRules[parentIndex]?.billingTypes !==
        currValues.jobLevelBillingRules[parentIndex]?.billingTypes
      );
    } else {
      return prevValues.billingTypes !== currValues.billingTypes;
    }
  };

  const billingComponents = {
    [K.BillingTypes.OneTime]: OneTimeComponent,
    [K.BillingTypes.Recursive]: RecursiveComponent,
    [K.BillingTypes.Retention]: RetentionComponent,
    [K.BillingTypes.TimeBased]: TimeBasedComponent,
  };

  return (
    <fieldset className={styles.fieldSet}>
      <legend className={styles.fieldTitle}>Billing Rules</legend>
      {showEffectiveSince && (
        <>
          <Row>
            <Col span={24}>
              <Form.Item
                className={styles.brFormItems}
                label="Effective Date"
                name="effectiveSince"
                rules={[
                  { required: true, message: "Please select effective date!" },
                ]}
              >
                <DatePicker
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  className={styles.datePicker}
                  disabledDate={isBackDate ? disabledStartDate : disabledDate}
                  format={K.DateFormat.DashUSFormat}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Row align="bottom" gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            label="Guarantee Days"
            hidden={isBackDate}
            name={
              parentIndex !== undefined
                ? [parentIndex, "guaranteeDays"]
                : "guaranteeDays"
            }
            rules={
              !isBackDate && [
                {
                  required: true,
                  message: "Enter No of Days!",
                },
                {
                  pattern: new RegExp("^[0-9]*$", "g"),
                  message: "Decimal values are not allowed!",
                },
              ]
            }
          >
            <InputNumber placeholder="Enter Guarantee Days" min={0} />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            initialValue={false}
            valuePropName="checked"
            hidden={isBackDate}
            name={
              parentIndex !== undefined
                ? [parentIndex, "isWorkingDays"]
                : "isWorkingDays"
            }
          >
            <Checkbox>Working Days</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Billing Type"
            hidden={isBackDate}
            name={
              parentIndex !== undefined
                ? [parentIndex, "billingTypes"]
                : "billingTypes"
            }
            rules={[
              { required: true, message: "Please select billing type !" },
            ]}
            initialValue={billingJobsList
              .filter(({ isRequired }) => isRequired)
              .map(({ id }) => id)}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              showArrow
              showSearch={true}
              mode="multiple"
              optionFilterProp="label"
              placeholder="Select Billing Type"
              className="billingTypeTitle"
              options={billingJobsList.map((item) => ({
                value: item.id,
                label: item.name,
                disabled: item.isRequired,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        shouldUpdate={shouldUpdate}
        className="mb-0"
        hidden={isBackDate}
      >
        {({ getFieldValue }) =>
          parentIndex !== undefined
            ? getFieldValue("jobLevelBillingRules")[parentIndex].billingTypes &&
              getFieldValue("jobLevelBillingRules")[
                parentIndex
              ].billingTypes.map((value, i) => {
                const filtered = billingJobsList.filter(
                  ({ id }) => value === id,
                );
                const Component = billingComponents[filtered[0].type];
                return (
                  <Component
                    key={i}
                    fieldObj={filtered[0]}
                    index={i}
                    parentIndex={parentIndex}
                    form={form}
                    styles={styles}
                    currencyList={currencyList}
                    retentionId={filtered[0].id}
                  />
                );
              })
            : getFieldValue("billingTypes") &&
              getFieldValue("billingTypes").map((value, i) => {
                const filtered = billingJobsList.filter(
                  ({ id }) => value === id,
                );
                const Component = billingComponents[filtered[0].type];
                return (
                  <Component
                    key={i}
                    fieldObj={filtered[0]}
                    index={i}
                    parentIndex={parentIndex}
                    form={form}
                    currencyList={currencyList}
                    styles={styles}
                  />
                );
              })
        }
      </Form.Item>
    </fieldset>
  );
}
