import { useState } from "react";
import {
  Button,
  Form,
  Grid,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "./sider.module.scss";
import {
  selectAccessibleClients,
  selectAccessibleClientsBySelection,
  selectUserRoles,
} from "redux/slices/userSlice";
import {
  selectClientSelection,
  selectSelectedClientIds,
  setClientSelectionType,
  setGlobalClients,
} from "redux/slices/globalSelectorSlice";
import { isPermissionPresent } from "utilities/generalUtility";
import K from "utilities/constants";

const { useBreakpoint } = Grid;

export default function SiderPopover() {
  const screens = useBreakpoint();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const userRoles = useSelector(selectUserRoles);
  const assignedClients = useSelector(selectAccessibleClients);

  const clientsBySelection = useSelector(selectAccessibleClientsBySelection);

  const selectedClientIds = useSelector(selectSelectedClientIds);
  const clientSelection = useSelector(selectClientSelection);

  const isSuperAdmin = isPermissionPresent(K.Permissions.SuperAdmin, userRoles);

  const options = assignedClients.map((c) => ({ value: c.id, label: c.name }));

  const handleOpenChange = (newVisible) => {
    setOpen(newVisible);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onFinish = (values) => {
    dispatch(setClientSelectionType(values.clientSelection));
    dispatch(setGlobalClients(values.selectedClients));
    setOpen(false);
  };

  const PopoverContent = () => {
    const [form] = Form.useForm();

    const initialValues = {
      clientSelection,
      selectedClients: selectedClientIds,
    };

    const selectionType =
      Form.useWatch("clientSelection", form) ?? initialValues.clientSelection;

    return (
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Space direction={"vertical"} size={24}>
          <Space direction={"vertical"} size={8}>
            {isSuperAdmin && (
              <Form.Item name={"clientSelection"} style={{ margin: 0 }}>
                <Radio.Group
                  onChange={() => {
                    form.setFieldValue("selectedClients", []);
                    form.validateFields();
                  }}
                >
                  <Radio value={K.Clients.SelectionType.All}>
                    Select All Clients
                  </Radio>
                  <Radio value={K.Clients.SelectionType.None}>
                    Select Specific
                  </Radio>
                </Radio.Group>
              </Form.Item>
            )}
            <Form.Item
              name="selectedClients"
              label="Select Client"
              rules={
                selectionType !== K.Clients.SelectionType.All
                  ? [{ required: true, message: "Client is required." }]
                  : []
              }
              style={{ margin: 0, minWidth: 250 }}
            >
              <Select
                size="medium"
                optionFilterProp="label"
                placeholder="Select Clients"
                mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                showArrow
                allowClear
                disabled={
                  selectionType === K.Clients.SelectionType.All && isSuperAdmin
                }
                showSearch={true}
                options={options}
              />
            </Form.Item>
          </Space>

          <Row justify={"end"}>
            <Space direction={"horizontal"}>
              <Button onClick={handleCancel}>Cancel</Button>
              <Form.Item style={{ margin: 0 }}>
                <Button type="primary" htmlType="submit">
                  Apply
                </Button>
              </Form.Item>
            </Space>
          </Row>
        </Space>
      </Form>
    );
  };

  return (
    <Popover
      overlayClassName={styles.siderPopoverForm}
      placement={
        screens["lg"] === undefined || screens["lg"] ? "right" : "bottom"
      }
      content={<PopoverContent />}
      trigger="click"
      arrow={{ pointAtCenter: false }}
      open={open}
      onOpenChange={handleOpenChange}
    >
      {screens["lg"] === undefined || screens["lg"] ? (
        <Button className={styles.siderPopover + " sider-popover"}>
          <i className={"icon-document " + styles.documentIcon}></i>

          {!selectedClientIds.length ? (
            <span className="sider-text">All Clients</span>
          ) : (
            <>
              <span
                title={clientsBySelection[0]?.name}
                className="sider-text"
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "20ch",
                  fontSize: 12,
                }}
              >
                {clientsBySelection[0]?.name}
              </span>
            </>
          )}
          {selectedClientIds.length > 1 && (
            <Tag className="sider-tag">{`+${
              selectedClientIds.length - 1
            }`}</Tag>
          )}
        </Button>
      ) : (
        <Button
          size="large"
          className={
            styles.siderPopover +
            " " +
            styles.siderPopoverResponsive +
            " sider-popover"
          }
        >
          <i className={"icon-document " + styles.documentIcon}></i>
        </Button>
      )}
    </Popover>
  );
}
