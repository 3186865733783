import { ReportConfigForm } from "../reportConfigForm";
import { Form, Modal } from "antd";
import { useState } from "react";

export const EditConfigModal = ({
  open,
  loading,
  onFinish,
  availableClients,
  configuration,
  ...props
}) => {
  const [isDirty, setIsDirty] = useState(false);

  const [form] = Form.useForm();

  return (
    <Modal
      centered
      open={open}
      title="Edit Reports Configuration"
      className="s2-theme-style modal-overflow-auto"
      closeIcon={<i className="icon-closeable"></i>}
      okText={"Save"}
      onOk={form.submit}
      okButtonProps={{ loading, disabled: !isDirty }}
      {...props}
    >
      <ReportConfigForm
        form={form}
        onFinish={onFinish}
        clients={availableClients}
        disableClientSelection={true}
        defaultValues={configuration}
        onDirtyChange={setIsDirty}
      />
    </Modal>
  );
};
