import { useEffect, useState } from "react";
import { Button, Card, Row, Table } from "antd";
import EditEngagementNotes from "./editEngagementNotes";
import EmptyPlaceholder from "../../../common/components/emptyPlaceholder/emptyPlaceholder";
import Engagement from "../../../redux/models/engagement";
import { getEngagementColumns } from "./EngagementTableColumns";
import EditEngagementModal from "./editEngagementModal/editEngagementModal";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";
import { isPermissionPresent } from "../../../utilities/generalUtility";
import K from "../../../utilities/constants";

const EngagementView = ({ isActive, candidateJobEntry, isJobDetail }) => {
  const [notes, setNotes] = useState(candidateJobEntry.engagementNotes || "");
  const [isEditNoteOpened, setEditNoteOpened] = useState(false);
  const [engagements, setEngagements] = useState([]);
  const [isEditOpened, setEditOpened] = useState(false);
  const [selectedEngagement, setSelectedEngagement] = useState(null);
  const userSlice = useSelector(selectUser);

  const isSuperAdmin = isPermissionPresent(
    [
      K.Permissions.ManageEngagementsForAssignedClients,
      K.Permissions.SuperAdmin,
    ],
    userSlice.roles,
  );

  const isCandidateTerminated =
    candidateJobEntry.status.name === K.CandidateStatus.Terminated;

  const hasAccessToEngagements = !(isCandidateTerminated && !isSuperAdmin);

  const isRowDisabled = (engagement) =>
    !isSuperAdmin && (engagement.locked || isCandidateTerminated);

  const canEdit = (engagement) =>
    isSuperAdmin || (!engagement.locked && !isCandidateTerminated);

  const canToggleLock = () => isSuperAdmin;

  useEffect(() => {
    isActive && candidateJobEntry && hasAccessToEngagements && getEngagements();
  }, [candidateJobEntry, isActive]);

  useEffect(() => setEditNoteOpened(false), [candidateJobEntry.id]);

  const getEngagements = async () => {
    try {
      const res = await Engagement.get({
        candidateJobEntryId: candidateJobEntry.id,
      });
      setEngagements(res.data || []);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setEngagements(
      engagements.map((e) =>
        e?.id === selectedEngagement?.id ? { ...selectedEngagement } : e,
      ),
    );
  }, [selectedEngagement]);

  const onEditClick = (e) => {
    setEditOpened(!isEditOpened);
    setSelectedEngagement(e);
  };

  const onLockClick = async (e) => {
    try {
      const res = await Engagement.edit(e.id, {
        locked: !e.locked,
      });
      setSelectedEngagement({ ...e, locked: res.data.locked });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {engagements.length ? (
        <>
          <Row>
            <Card
              title={"General Engagement Notes"}
              headStyle={{ borderBottom: "none", padding: 0 }}
              bodyStyle={{ padding: 0 }}
              style={{
                padding: 0,
                display: "flex",
                alignItems: "center",
                border: "none",
              }}
            >
              {!isEditNoteOpened && (
                <Button
                  onClick={() => setEditNoteOpened(!isEditNoteOpened)}
                  type="link"
                  icon={<i className="icon-edit"></i>}
                />
              )}
            </Card>
          </Row>
          {isEditNoteOpened ? (
            <EditEngagementNotes
              notes={notes}
              setNotes={setNotes}
              setClosed={setEditNoteOpened}
              candidateJobEntry={candidateJobEntry}
            />
          ) : (
            <span
              style={
                notes?.length > 0 ? {} : { color: "#161616", opacity: "25%" }
              }
            >
              {notes?.length > 0 ? notes : "No notes added yet"}
            </span>
          )}
          <Table
            columns={getEngagementColumns(
              onEditClick,
              onLockClick,
              canEdit,
              canToggleLock,
              isJobDetail,
            )}
            dataSource={engagements}
            rowKey={(eng) => eng.id}
            size="small"
            pagination={false}
            scroll={{ x: isJobDetail ? false : 900 }}
            style={{ paddingTop: 28 }}
            onRow={(rec) => ({
              style: {
                color: isRowDisabled(rec) && "#aaa",
              },
            })}
          />
        </>
      ) : (
        <EmptyPlaceholder description={"There are no upcoming engagements"} />
      )}
      {isEditOpened && (
        <EditEngagementModal
          isOpen={isEditOpened}
          engagement={selectedEngagement}
          setOpen={setEditOpened}
          setEngagement={setSelectedEngagement}
        />
      )}
    </>
  );
};

export default EngagementView;
