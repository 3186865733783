import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Empty,
  Button,
  message,
  Modal,
  Divider,
  Typography,
  Input,
  Card,
  PageHeader,
} from "antd";

import K from "utilities/constants";
import { lookupTablesForCandidates } from "config/lookupTables";
import "./previewUpload.scss";
import {
  selectCandidates,
  setUploadCandidate,
} from "redux/slices/candidateSlice";
import { selectConfigration } from "redux/slices/configrationSlice";
import LookupTable from "redux/models/lookupTable";

import Job from "redux/models/job";
import Client from "redux/models/client";
import moment from "moment";
import { onFilterTextBoxChanged } from "utilities/tableUtility";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import { arrayToOptions } from "../../../utilities/generalUtility";
const { Search } = Input;
const statusList = [
  { id: 1, name: "Prospect" },
  { id: 2, name: "Candidates" },
  { id: 9, name: "Hired" },
  { id: 10, name: "Terminated" },
];
const subStatusList = [
  { id: 1, name: "Sourced", statusId: 1 },
  { id: 5, name: "Needs Review", statusId: 2 },
  { id: 39, name: "Started Work", statusId: 9 },
  { id: 40, name: "No Show to Day 1", statusId: 10 },
];

const hireTypes = arrayToOptions(K.CandidateType.getAll());

export default function PreviewUploads() {
  const gridRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [columnDefs, setColumnDefs] = useState([]);
  const [modalState, setModalState] = useState({
    isUploadModalVisible: false,
    isLoadingUploadModal: false,
    uploadCSVErrors: null,
  });
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [classList, setClassList] = useState([]);
  const [recruiters, setRecruiters] = useState([]);

  const lookupData = useSelector(selectConfigration).lookup;
  const candidateSlice = useSelector(selectCandidates);

  const lookupSourceCellEditorParams = () => {
    return {
      values: lookupData.source.map((item) => item.id),
      formatValue: (id) => lookupData.source.find((j) => j.id === id)?.name,
    };
  };
  const lookupSourceIdRenderer = (params) => {
    return lookupData.source.find(
      (item) => item.id === +params.data["Source ID"],
    )?.name;
  };

  const lookupCandidateStatusIdRenderer = (params) => {
    return (
      lookupData.candidateStatus.find(
        (item) => item.id === +params.data["Candidate Status ID"],
      )?.name ?? null
    );
  };

  const lookupCandidateStatusCellEditorParams = () => {
    return {
      values: lookupData.candidateStatus.map((item) => item.id),
      formatValue: (id) =>
        lookupData.candidateStatus.find((j) => j.id === id)?.name,
    };
  };

  const lookupATSIdRenderer = (params) => {
    return (
      lookupData.ats.find(
        (item) => item.id === +params.data["ATS Candidate ID"],
      )?.name ?? null
    );
  };

  const lookupATSCellEditorParams = () => {
    return {
      values: lookupData.ats.map((item) => item.id),
      formatValue: (id) => lookupData.ats.find((j) => j.id === id)?.name,
    };
  };

  const hireTypeRenderer = (params) => {
    return (
      hireTypes.find(
        (item) => item.value === params.data["Candidate Hire Type"],
      )?.label ?? null
    );
  };

  const hireTypeCellEditorParams = () => {
    return {
      values: hireTypes.map((item) => item.value),
      formatValue: (value) => {
        return hireTypes.find((ht) => ht.value === value)?.label;
      },
    };
  };

  const jobSourceRenderer = (params) => {
    return (
      jobs.find((item) => item.id === +params.data["Job Source ID"])?.title ??
      null
    );
  };

  const jobSourceCellEditorParams = () => {
    return {
      values: jobs.map((item) => item.id),
      formatValue: (value) => {
        const found = jobs.find((jb) => jb.id === value);
        return `${found?.title}`;
      },
    };
  };

  const classListRenderer = (params) => {
    return (
      classList.find((item) => item.id === +params.data["Class ID"])?.title ??
      null
    );
  };

  const classCellEditorParams = () => {
    return {
      values: classList.map((item) => item.id),
      formatValue: (value) => {
        const found = classList.find((jb) => jb.id === value);
        return `${found?.title}`;
      },
    };
  };

  const recruiterRenderer = (params, key) => {
    return (
      recruiters.find((item) => item.id === +params.data[`${key}`])?.name ??
      null
    );
  };

  const recruiterCellEditorParams = () => {
    return {
      values: recruiters.map((item) => `${item.id}`),
      formatValue: (value) => {
        const found = recruiters.find((jb) => jb.id === +value);
        return `${found?.name} - ${
          found?.clients?.map((item) => item.name) ?? "N/A"
        }`;
      },
    };
  };

  const statusRenderer = (params) => {
    return (
      statusList.find((item) => item.id === +params.data["Status ID"])?.name ??
      null
    );
  };

  const statusCellEditorParams = () => {
    return {
      values: statusList.map((item) => `${item.id}`),

      formatValue: (value) => {
        const found = statusList?.find((jb) => jb.id === +value) ?? null;
        return found ? found?.name : null;
      },
    };
  };
  const subStatusRenderer = (params) => {
    return (
      subStatusList.find((item) => {
        return item.id === +params.data["Sub Status ID"];
      })?.name ?? null
    );
  };

  const subStatusEditorParams = (params) => {
    const selectedStatusId = params.data["Status ID"];
    const allowedSubstatus = selectedStatusId
      ? statusSubStatusMap(selectedStatusId)
      : null;
    return {
      values: allowedSubstatus?.map((item) => `${item.id}`) ?? [],
      formatValue: (value) => {
        const found = subStatusList.find(
          (subStatus) => subStatus.id === +value,
        );

        return found?.name;
      },
    };
  };

  const statusSubStatusMap = (match) => {
    const map = {
      1: [{ id: 1, name: "Sourced" }],
      2: [{ id: 5, name: "Needs Review" }],
      9: [{ id: 39, name: "Started Work" }],
      10: [{ id: 40, name: "No Show to Day 1" }],
    };
    return map[match];
  };

  const dateEditorParams = (key) => {
    return {
      cellRenderer: (params) => {
        if (!params.value) {
          return "";
        }
        const formattedDate = convertIntoDashUSFormat(params.value, false);
        return formattedDate ?? null;
      },
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        const formattedDate = moment(params.value).format("YYYY-MM-DD");
        return formattedDate ?? null;
      },
      cellEditor: "agDateCellEditor",
      editable: true,
    };
  };
  const onCellValueChanged = (params) => {
    const updatedRow = { ...params.node.data };
    const rowData = [];

    gridRef.current.api.forEachNode(function (node) {
      if (node.data.id === updatedRow.id) {
        rowData.push({ ...updatedRow });
      } else {
        rowData.push({ ...node.data });
      }
    });

    gridRef.current.api.setRowData(rowData);
  };

  const onSearchChange = useCallback((event) => {
    onFilterTextBoxChanged(event.target.value, gridRef);
  }, []);

  const columns = [
    { headerName: "ID", field: "id", suppressColumnsToolPanel: true },

    {
      field: "First Name",
      editable: true,
      valueSetter: (params) => {
        const email = params.newValue;
        const data = params.data;
        data["First Name"] = email;
        return true;
      },
      quickFilterText: (params) => params.data["First Name"],
      valueGetter: (params) => params.data["First Name"],
    },
    {
      field: "Last Name",
      editable: true,
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Last Name"] = value;
        return true;
      },
      valueGetter: (params) => {
        if (params.data["Last Name"] === undefined) {
          return "";
        }
        return params.data["Last Name"];
      },
    },

    {
      field: "Email",
      editable: true,
      valueGetter: (params) => params.data["Email"],
      valueSetter: (params) => {
        const email = params.newValue;
        const data = params.data;
        data["Email"] = email;
        return true;
      },
    },
    {
      field: "Mobile Number",
      editable: true,
      valueGetter: (params) => params.data["Mobile Number"],
      valueSetter: (params) => {
        const value = params.newValue;

        const data = params.data;
        data["Mobile Number"] = value;
        return true;
      },
    },

    {
      field: "Candidate Hire Type",
      cellRenderer: hireTypeRenderer,
      editable: true,
      valueGetter: (params) => params.data["Candidate Hire Type"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Candidate Hire Type"] = value;
        return true;
      },
      cellEditor: "agRichSelectCellEditor",

      cellEditorParams: hireTypeCellEditorParams,
    },
    {
      field: "Job Source ID",
      valueGetter: (params) => params.data["Job Source ID"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Job Source ID"] = value;
        return true;
      },
      cellRenderer: jobSourceRenderer,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: jobSourceCellEditorParams,
    },
    {
      field: "Class ID",
      valueGetter: (params) => params.data["Class ID"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Class ID"] = value;
        return true;
      },
      cellRenderer: classListRenderer,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: classCellEditorParams,
    },
    {
      field: "ATS Candidate ID",
      editable: true,
      valueGetter: (params) => params.data["ATS Candidate ID"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["ATS Candidate ID"] = value;
        return true;
      },
    },

    {
      field: "Candidate Status ID",
      editable: true,
      valueGetter: (params) => params.data["Candidate Status ID"],
      valueSetter: (params) => {
        const value = params.newValue;
        console.log("Value", value);
        const data = params.data;
        data["Candidate Status ID"] = value;
        return true;
      },
      cellRenderer: lookupCandidateStatusIdRenderer,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: lookupCandidateStatusCellEditorParams,
    },
    {
      field: "Source ID",
      editable: true,
      valueGetter: (params) => params.data["Source ID"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Source ID"] = value;
        return true;
      },
      cellRenderer: lookupSourceIdRenderer,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: lookupSourceCellEditorParams,
    },
    {
      field: "First Recruiter ID",
      cellRenderer: (params) => recruiterRenderer(params, "First Recruiter ID"),
      valueGetter: (params) => params.data["First Recruiter ID"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["First Recruiter ID"] = value;
        return true;
      },
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: recruiterCellEditorParams,
    },
    {
      field: "Second Recruiter ID",
      cellRenderer: (params) =>
        recruiterRenderer(params, "Second Recruiter ID"),
      valueGetter: (params) => params.data["Second Recruiter ID"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        console.log("Value", value);
        data["Second Recruiter ID"] = value;
        return true;
      },
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: recruiterCellEditorParams,
    },
    {
      field: "Status ID",
      editable: true,
      valueGetter: (params) => params.data["Status ID"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Status ID"] = value;
        return true;
      },
      cellRenderer: statusRenderer,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: statusCellEditorParams,
    },
    {
      field: "Sub Status ID",
      editable: true,
      valueGetter: (params) => params.data["Sub Status ID"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Sub Status ID"] = value;
        return true;
      },
      cellRenderer: subStatusRenderer,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: subStatusEditorParams,
    },
    {
      headerName: "Sourced Date",
      field: "Sourced Date",
      valueGetter: (params) => params.data["Sourced Date"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Sourced Date"] = value;
        return true;
      },
      ...dateEditorParams(),
    },
    {
      headerName: "Application Date",
      field: "Application Date",
      valueGetter: (params) => params.data["Application Date"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Application Date"] = value;
        return true;
      },
      ...dateEditorParams(),
    },
    {
      headerName: "Date Phone Screen Complete",
      field: "Date Phone Screen Complete",
      valueGetter: (params) => params.data["Date Phone Screen Complete"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Date Phone Screen Complete"] = value;
        return true;
      },
      ...dateEditorParams(),
    },
    {
      headerName: "Date Submitted",
      field: "Date Submitted",
      ...dateEditorParams(),
      valueGetter: (params) => params.data["Date Submitted"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Date Submitted"] = value;
        return true;
      },
    },
    {
      headerName: "Interview Date",
      field: "Interview Date",
      valueGetter: (params) => params.data["Interview Date"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Interview Date"] = value;
        return true;
      },
      ...dateEditorParams(),
    },
    {
      headerName: "Date Interview Feedback Received",
      field: "Date Interview Feedback Received",
      valueGetter: (params) => params.data["Date Interview Feedback Received"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Date Interview Feedback Received"] = value;
        return true;
      },
      ...dateEditorParams(),
    },
    {
      headerName: "Date Offer Extended",
      field: "Date Offer Extended",
      valueGetter: (params) => params.data["Date Offer Extended"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Date Offer Extended"] = value;
        return true;
      },
      ...dateEditorParams(),
    },

    {
      headerName: "Date Offer Accepted",
      field: "Date Offer Accepted",
      valueGetter: (params) => params.data["Date Offer Accepted"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Date Offer Accepted"] = value;
        return true;
      },
      ...dateEditorParams(),
    },
    {
      headerName: "Hire Date",
      field: "Hire Date",
      valueGetter: (params) => params.data["Hire Date"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Hire Date"] = value;
        return true;
      },
      ...dateEditorParams(),
    },
    {
      headerName: "Start Date",
      field: "Start Date",
      valueGetter: (params) => params.data["Start Date"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Start Date"] = value;
        return true;
      },
      ...dateEditorParams(),
    },
    {
      headerName: "Termination Date",
      field: "Termination Date",
      valueGetter: (params) => params.data["Termination Date"],
      valueSetter: (params) => {
        const value = params.newValue;
        const data = params.data;
        data["Termination Date"] = value;
        return true;
      },
      ...dateEditorParams(),
    },
  ];
  const getLookUpData = async () => {
    try {
      setLoading(true);
      await Promise.all(
        lookupTablesForCandidates.map(async (item) => {
          await dispatch(LookupTable.getData(item.reduxKey, item.apiEndPoint));
        }),
      );
      setColumnDefs(columns);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getAllJobsForExcelUpload = async () => {
    try {
      const res = await Job.getAllJobsForExcelUpload();
      setJobs(res);
    } catch (err) {
      console.error(err);
    }
  };
  const getAllClassForExcelUpload = async () => {
    try {
      const res = await Client.getAllClassForExcelUpload();
      setClassList(res);
    } catch (err) {
      console.error(err);
    }
  };

  const getAllRecruiterForExcelUpload = async () => {
    try {
      const res = await Client.getAllRecruiterForExcelUpload();
      setRecruiters(res);
    } catch (err) {
      console.error(err);
    }
  };

  const getPageDataFromServer = async () => {
    setLoading(true);
    try {
      await Promise.all([
        getLookUpData(),
        getAllJobsForExcelUpload(),
        getAllRecruiterForExcelUpload(),
        getAllClassForExcelUpload(),
      ]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getRowData = useCallback(async () => {
    try {
      const rowData = [];
      gridRef.current.api.forEachNode(function (node) {
        rowData.push(node.data);
      });

      dispatch(setUploadCandidate(rowData));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const submitData = async () => {
    await getRowData();
    setModalState((prev) => ({
      ...prev,
      isUploadModalVisible: true,
      isLoadingUploadModal: false,
    }));
  };
  useEffect(() => {
    getPageDataFromServer();
  }, []);

  const handleOk = async () => {
    setModalState((prev) => ({
      ...prev,
      isLoadingUploadModal: true,
    }));
    try {
      const rowData = [];
      const data = await gridRef.current.api.forEachNode(function (node) {
        rowData.push(node.data);
      });

      await Client.uploadCSV({
        data: candidateSlice.uploadedCandidate,
      });
      setModalState((prev) => ({
        ...prev,
        isUploadModalVisible: false,
        isLoadingUploadModal: false,
      }));
      history.goBack();
    } catch (err) {
      const {
        error: { data },
      } = err;
      message.error("Failed to upload CSV");
      console.info(data);
      setModalState((prev) => {
        return {
          ...prev,
          isLoadingUploadModal: false,
          uploadCSVErrors: data.errors,
        };
      });
    }
    return false;
  };
  const handleUploadCancel = () => {
    setModalState((prev) => {
      return {
        ...prev,
        isUploadModalVisible: false,
        uploadCSVErrors: null,
      };
    });
  };

  const onCancel = () => {
    history.goBack();
  };
  return loading && columnDefs.length === 0 ? (
    <Empty />
  ) : (
    <>
      <PageHeader
        ghost={false}
        title="Uploaded Candidates"
        className="client-ag-table-header"
      />
      <Card
        className="client-ag-table-card"
        title={
          <>
            <Search
              className="client-search"
              placeholder="Search"
              onChange={onSearchChange}
              style={{
                width: 264,
              }}
            />
          </>
        }
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 675,
          }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={JSON.parse(
              JSON.stringify(candidateSlice.uploadedCandidate),
            )}
            columnDefs={columns}
            singleClickEdit={true}
            onCellValueChanged={onCellValueChanged}
            enableGroupEdit={true}
            // suppressAggFuncInHeader={true}
            enableCellChangeFlash={true}
            {...K.AgGridTable.DefaultProps}
          />
        </div>
        <div className="client-actions-wrap">
          <Button onClick={onCancel}>Cancel </Button>
          <Button type="primary" onClick={submitData}>
            Save
          </Button>
        </div>
      </Card>
      <Modal
        title="Upload CSV File"
        centered
        className="s2-theme-style"
        onOk={handleOk}
        onCancel={handleUploadCancel}
        okButtonProps={{
          loading: modalState.isLoadingUploadModal,
          disabled: !candidateSlice.uploadedCandidate,
        }}
        cancelButtonProps={{ disabled: !candidateSlice.uploadedCandidate }}
        destroyOnClose
        open={modalState.isUploadModalVisible}
      >
        <div className="d-flex mt-3 justify-content-center">
          {/* <Link
            href={SampleCSV}
            download="Source2_Cadidate_upload_Sample_CSV_File.csv"
          >
            Click here to download sample CSV file
          </Link> */}
          Are you sure you want to upload this file ?
        </div>

        <Divider />
        {modalState.uploadCSVErrors && (
          <>
            <Typography.Title level={5} style={{ color: "red" }}>
              Errors in CSV:
            </Typography.Title>
            <ol>
              {Object.keys(modalState.uploadCSVErrors).map((key) => [
                <li key={key} style={{ color: "red", fontWeight: "bold" }}>
                  Errors in Row {key}:
                </li>,
                Object.values(modalState.uploadCSVErrors[key]).map((item) => (
                  <ul>
                    <li key={key} style={{ color: "red" }}>
                      {item}
                    </li>
                  </ul>
                )),
              ])}
            </ol>
          </>
        )}
      </Modal>
    </>
  );
}
