import { DatePicker, Form, Input, message, Modal, Select } from "antd";
import K from "utilities/constants";
import { useEffect, useState } from "react";
import Engagement from "../../../../redux/models/engagement";
import moment from "moment/moment";

const EditEngagementModal = ({
  isOpen,
  engagement,
  setOpen,
  setEngagement,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isRequiredNotes, setRequiredNotes] = useState(
    engagement.status === K.EngagementStatus.All.CompletedAtRisk.value,
  );

  useEffect(() => {
    form.validateFields(["notes"]);
  }, [isRequiredNotes]);

  const initialValues = {
    lastCallDate: moment.utc(engagement.lastCallDate || moment.now()).local(),
    status:
      engagement.status === K.EngagementStatus.All.RecruiterMissed.value
        ? K.EngagementStatus.All.RecruiterMissed.label
        : engagement.status,
    notes: engagement.notes,
  };

  const onSubmit = async () => {
    const values = await form.validateFields();

    if (JSON.stringify(initialValues) === JSON.stringify(values)) {
      setOpen(false);
      return;
    }

    try {
      setLoading(true);
      const { lastCallDate, notes, status } = values;

      const payload = {
        lastCallDate: lastCallDate.format(`${K.DateFormat.ResponseWithTime}Z`),
        notes,
        ...(status !== K.EngagementStatus.All.RecruiterMissed.label && {
          status,
        }),
      };

      const res = await Engagement.edit(engagement.id, payload);
      message.success(res.message);
      setOpen(false);

      setEngagement({
        ...engagement,
        status: res.data.status,
        lastCallDate: res.data.lastCallDate,
        notes: res.data.notes,
        locked: res.data.locked,
      });
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getStatusOptions = () => {
    if (engagement.isPast) {
      return K.EngagementStatus.closed().filter((s) => s.userAccessible);
    }

    return Object.values(K.EngagementStatus.All).filter(
      (s) => s.userAccessible,
    );
  };

  return (
    <Modal
      centered
      width={520}
      open={isOpen}
      okText="Submit"
      onOk={onSubmit}
      onCancel={() => setOpen(false)}
      okButtonProps={{ loading }}
      title={`Edit Milestone Info for ${engagement.milestoneName}`}
      className="s2-theme-style modal-overflow-auto"
      closeIcon={<i className="icon-closeable"></i>}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item
          name="lastCallDate"
          label="Last Call Date and Time"
          rules={[
            {
              required: true,
              message: "The Last Call Date and Time field is required",
            },
          ]}
        >
          <DatePicker
            placeholder={"Select date and time"}
            showTime={{ format: K.TimeFomat.Display }}
            format={`${K.DateFormat.DashUSFormat} ${K.TimeFomat.Display}`}
          />
        </Form.Item>
        <Form.Item
          name="status"
          label="Select Status"
          rules={[{ required: true }]}
        >
          <Select
            onChange={(e) =>
              setRequiredNotes(
                e === K.EngagementStatus.All.CompletedAtRisk.value,
              )
            }
            options={getStatusOptions()}
          />
        </Form.Item>
        <Form.Item
          name="notes"
          label="Notes"
          style={{ fontSize: "14px" }}
          rules={[
            {
              required: isRequiredNotes,
              message: `The Notes field is required when status is ${K.EngagementStatus.All.CompletedAtRisk.label}`,
            },
            {
              whitespace: true,
              message: `The notes field is not allowed to contains empty spaces`,
            },
          ]}
        >
          <Input.TextArea
            maxLength={500}
            style={{ height: 150, resize: "none" }}
            placeholder={"Enter notes here..."}
            showCount={true}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditEngagementModal;
