import {
  candidateName,
  candidateStartDate,
  commissionAmountRenderer,
  commissionEarnedAmountRenderer,
  createdAtRenderer,
  tooltipCandidateName,
  tooltipCandidateStartDate,
  toolTipCommissionAmountRenderer,
  toolTipCommissionEarnedAmountRenderer,
  tooltipCreatedAtRenderer,
} from "../../../config/rendererConfig";
import K from "../../../utilities/constants";
import { nullRenderer } from "../submitToBilling/tableColumns";

export const commissionApprovalsColumns = [
  {
    headerName: "ID",
    field: "id",
    sortable: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    suppressColumnsToolPanel: true,
  },
  {
    headerName: "Client",
    field: "submittedJobBill.client.name",
    sortable: true,
    tooltipField: "submittedJobBill.client.name",
  },
  {
    headerName: "Candidate",
    field: "submittedJobBill.candidateName",
    sortable: true,
    tooltipField: "submittedJobBill.candidateName",
    tooltipComponent: tooltipCandidateName,
    cellRenderer: candidateName,
  },
  {
    headerName: "Candidate Type",
    field: "submittedJobBill.candidateJobEntry.hireType",
    sortable: true,
    cellRenderer: (params) =>
      K.CandidateType[params.value] || nullRenderer.cellRenderer(params),
    tooltipValueGetter: (params) => K.CandidateType[params.value],
  },
  {
    headerName: "Start Date",
    field: "submittedJobBill.candidateJobEntry",
    sortable: true,
    tooltipField: "submittedJobBill.candidateJobEntry",
    tooltipComponent: tooltipCandidateStartDate,
    cellRenderer: candidateStartDate,
  },
  {
    headerName: "Job Title",
    field: "submittedJobBill.jobTitle",
    sortable: true,
    tooltipField: "submittedJobBill.jobTitle",
  },
  {
    headerName: "Billing Type",
    field: "submittedJobBill.billingTypeName",
    sortable: true,
    tooltipField: "submittedJobBill.billingTypeName",
  },
  {
    headerName: "Amount",
    field: "thresholdMaker",
    sortable: true,
    tooltipField: "thresholdMaker",
    tooltipComponent: toolTipCommissionAmountRenderer,
    cellRenderer: commissionAmountRenderer,
  },
  {
    headerName: "Commission Earned",
    field: "duplicateAmount",
    sortable: true,
    tooltipField: "duplicateAmount",
    tooltipComponent: toolTipCommissionEarnedAmountRenderer,
    cellRenderer: commissionEarnedAmountRenderer,
  },
  {
    headerName: "Create Date",
    field: "createdAt",
    sortable: true,
    tooltipField: "createdAt",
    tooltipComponent: tooltipCreatedAtRenderer,
    cellRenderer: createdAtRenderer,
  },
];
