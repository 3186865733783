import { convertIntoUtcLocalTime } from "../../../utilities/dateUtility";
import K from "../../../utilities/constants";
import { Space } from "antd";
import { LockIcon } from "../../../common/icons/LockIcon";
import { UnlockIcon } from "../../../common/icons/UnlockIcon";

const ShortColumnKeys = ["milestoneDate", "status", "edit"];

export const getEngagementColumns = (
  onEditClick,
  onLockClick,
  canEdit,
  canToggleLock,
  short = false,
) => {
  const columns = [
    {
      key: "milestoneDate",
      title: "Milestone Date",
      align: "center",
      dataIndex: "milestoneDate",
      width: 132,
    },
    {
      key: "milestoneName",
      title: "Milestone",
      align: "center",
      dataIndex: "milestoneName",
      width: 120,
    },
    {
      key: "status",
      title: "Status",
      align: "center",
      dataIndex: "status",
      ellipsis: true,
      render: (st) => K.EngagementStatus.byKey(st)?.label,
    },
    {
      key: "notes",
      title: "Notes",
      align: "center",
      dataIndex: "notes",
      ellipsis: true,
    },
    {
      key: "lastCallDate",
      title: "Last Call Details",
      align: "center",
      dataIndex: "lastCallDate",
      render: (date) => convertIntoUtcLocalTime(date),
    },
    {
      key: "edit",
      title: "",
      align: "center",
      width: 88,
      color: "#1890FF",
      render: (engagement, record) => (
        <>
          <Space>
            {canEdit(engagement) && (
              <a
                style={{ color: "#1890FF" }}
                onClick={() => onEditClick(record)}
              >
                Edit
              </a>
            )}
            {canToggleLock() && (
              <>
                {engagement.locked ? (
                  <LockIcon onClick={() => onLockClick(record)} />
                ) : (
                  <UnlockIcon onClick={() => onLockClick(record)} />
                )}
              </>
            )}
          </Space>
        </>
      ),
    },
  ];

  if (short) {
    return columns.filter((c) => ShortColumnKeys.includes(c.key));
  }

  return columns;
};
