import K from "../../../utilities/constants";
import {
  displayDollar,
  displayPercentage,
} from "../../../utilities/tableUtility";

const displayDataUnit = (data, unit) => {
  if (unit === K.Format.Dollar) {
    return displayDollar(data);
  } else {
    return displayPercentage(data);
  }
};

const toolTipDisplayDataUnit = (data, unit) => {
  if (unit === K.Format.Dollar) {
    return <div className="client-list-tooltip">{displayDollar(data)} </div>;
  } else {
    return <div className="client-list-tooltip">{displayPercentage(data)}</div>;
  }
};

const displayThresholdDataUnit = (data, type) => {
  if (type === K.CommissionThresholdType.Amount) {
    return displayDollar(data);
  } else {
    return `${data} hires `;
  }
};

const tooltipDisplayThresholdDataUnit = (data, type) => {
  if (type === K.CommissionThresholdType.Amount) {
    return <div className="client-list-tooltip">{displayDollar(data)}</div>;
  } else {
    return <div className="client-list-tooltip">{`${data} hires `}</div>;
  }
};

export const Columns = [
  {
    headerName: "ID",
    field: "id",
    sortable: true,
    suppressColumnsToolPanel: true,
  },
  {
    headerName: "Plan Name",
    field: "name",
    sortable: true,
    tooltipField: "name",
    cellClass: "cursor-pointer",
  },
  {
    headerName: "Default  Threshold",
    field: "defaultBillingThreshold",
    cellClass: "cursor-pointer",
    tooltipField: "defaultBillingThreshold",
    tooltipComponent: (params) =>
      tooltipDisplayThresholdDataUnit(
        params.data.defaultBillingThreshold,
        params.data.thresholdType,
      ),
    sortable: true,
    cellRenderer: (params) =>
      displayThresholdDataUnit(
        params.data.defaultBillingThreshold,
        params.data.thresholdType,
      ),
  },
  {
    headerName: "Applied Hires",
    field: "appliedHire",
    sortable: true,
    cellClass: "cursor-pointer",
    tooltipField: "appliedHire",
    tooltipComponent: (params) =>
      toolTipDisplayDataUnit(
        params.data.appliedHire,
        params.data.appliedHireUnit,
      ),
    cellRenderer: (params) =>
      displayDataUnit(params.data.appliedHire, params.data.appliedHireUnit),
  },
  {
    headerName: "Sourced Hires",
    field: "sourceHired",
    sortable: true,
    cellClass: "cursor-pointer",
    tooltipField: "sourceHired",
    tooltipComponent: (params) =>
      toolTipDisplayDataUnit(
        params.data.sourceHired,
        params.data.sourceHiredUnit,
      ),
    cellRenderer: (params) =>
      displayDataUnit(params.data.sourceHired, params.data.sourceHiredUnit),
  },
  {
    headerName: "Referrals",
    field: "referral",
    sortable: true,
    cellClass: "cursor-pointer",
    tooltipField: "referral",
    tooltipComponent: (params) =>
      toolTipDisplayDataUnit(params.data.referral, params.data.referralUnit),
    cellRenderer: (params) =>
      displayDataUnit(params.data.referral, params.data.referralUnit),
  },
  {
    headerName: "Cancellations",
    field: "cancellations",
    sortable: true,
    cellClass: "cursor-pointer",
    tooltipField: "cancellations",
    tooltipComponent: (params) =>
      toolTipDisplayDataUnit(
        params.data.cancellations,
        params.data.cancellationsUnit,
      ),
    cellRenderer: (params) =>
      displayDataUnit(params.data.cancellations, params.data.cancellationsUnit),
  },
  {
    headerName: "Hiring Events",
    field: "hiringEvent",
    sortable: true,
    cellClass: "cursor-pointer",
    tooltipField: "hiringEvent",
    tooltipComponent: (params) =>
      toolTipDisplayDataUnit(
        params.data.hiringEvent,
        params.data.hiringEventUnit,
      ),
    cellRenderer: (params) =>
      displayDataUnit(params.data.hiringEvent, params.data.hiringEventUnit),
  },
  {
    headerName: "Rehire Applied",
    field: "reHireApplied",
    sortable: true,
    cellClass: "cursor-pointer",
    tooltipField: "reHireApplied",
    tooltipComponent: (params) =>
      toolTipDisplayDataUnit(
        params.data.reHireApplied,
        params.data.reHireAppliedUnit,
      ),
    cellRenderer: (params) =>
      displayDataUnit(params.data.reHireApplied, params.data.reHireAppliedUnit),
  },
  {
    headerName: "Rehire Referral",
    field: "reHireReferral",
    sortable: true,
    cellClass: "cursor-pointer",
    tooltipField: "reHireReferral",
    tooltipComponent: (params) =>
      toolTipDisplayDataUnit(
        params.data.reHireReferral,
        params.data.reHireReferralUnit,
      ),
    cellRenderer: (params) =>
      displayDataUnit(
        params.data.reHireReferral,
        params.data.reHireReferralUnit,
      ),
  },
  {
    headerName: "Rehire Sourced",
    field: "reHireSourced",
    cellClass: "cursor-pointer",
    tooltipField: "reHireSourced",
    tooltipComponent: (params) =>
      toolTipDisplayDataUnit(
        params.data.reHireSourced,
        params.data.reHireSourcedUnit,
      ),
    sortable: true,
    cellRenderer: (params) =>
      displayDataUnit(params.data.reHireSourced, params.data.reHireSourcedUnit),
  },
  {
    headerName: "Internal",
    field: "internal",
    cellClass: "cursor-pointer",
    tooltipField: "internal",
    tooltipComponent: (params) =>
      toolTipDisplayDataUnit(params.data.internal, params.data.internalUnit),
    sortable: true,
    cellRenderer: (params) =>
      displayDataUnit(params.data.internal, params.data.internalUnit),
  },
];
