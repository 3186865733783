import { Form, Modal } from "antd";
import { ReportConfigForm } from "../reportConfigForm";

export const CreateConfigModal = ({
  open,
  loading,
  onFinish,
  availableClients,
  ...props
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      centered
      open={open}
      onCancel={() => setOpen(false)}
      title="Create Reports Configuration"
      className="s2-theme-style modal-overflow-auto"
      closeIcon={<i className="icon-closeable"></i>}
      okText={"Create"}
      onOk={form.submit}
      okButtonProps={{ loading }}
      {...props}
    >
      <ReportConfigForm
        form={form}
        onFinish={onFinish}
        clients={availableClients}
      />
    </Modal>
  );
};
