import { useQueryParams } from "./useQueryParams";
import { useEffect } from "react";

export const ParseString = (v) => v;
export const ParseNumber = (v) => (v ? +v : v);

export const useQueryState = (
  key,
  parse = ParseString,
  defaultValue = null,
) => {
  const { getParam, setParam } = useQueryParams();

  const valueFromQuery = getParam(key);
  const value = parse(valueFromQuery) || defaultValue;

  const setValue = (value) => {
    setParam(key, value);
  };

  useEffect(() => {
    if (!valueFromQuery) {
      setParam(key, defaultValue);
    }
  }, [valueFromQuery]);

  return [value, setValue];
};
