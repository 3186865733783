import { Row } from "antd";
import K from "utilities/constants";
import { displayDollar, displayPercentage } from "utilities/tableUtility";
import { CommissionDetailItem } from "./commissionDetailItem";

export const CommissionPlanDetails = ({ plan, clientName }) => {
  const getThresholdDisplayedValue = (type, value) => {
    return type === K.CommissionThresholdType.Amount
      ? displayDollar(value)
      : `${value} hires `;
  };

  const getDisplayedValueByUnit = (unit, value) => {
    return unit === K.Format.Dollar
      ? displayDollar(value)
      : displayPercentage(value);
  };

  return (
    <div
      className={
        clientName ? "commission-client-card" : "commission-default-card"
      }
    >
      <h1 className="new-commission-title">{clientName || "Default Rule"}</h1>
      <Row>
        {!clientName && (
          <CommissionDetailItem
            title={
              plan.thresholdType === K.CommissionThresholdType.Amount
                ? `Default Billing Threshold`
                : `Default Hires Threshold`
            }
            value={getThresholdDisplayedValue(
              plan.thresholdType,
              plan.defaultBillingThreshold,
            )}
          />
        )}

        <CommissionDetailItem
          title={"Applied Hires"}
          value={getDisplayedValueByUnit(
            plan.appliedHireUnit,
            plan.appliedHire,
          )}
        />
        <CommissionDetailItem
          title={"Sourced Hires"}
          value={getDisplayedValueByUnit(
            plan.sourceHiredUnit,
            plan.sourceHired,
          )}
        />
        <CommissionDetailItem
          title={"Referrals"}
          value={getDisplayedValueByUnit(plan.referralUnit, plan.referral)}
        />
        <CommissionDetailItem
          title={"Cancellations"}
          value={getDisplayedValueByUnit(
            plan.cancellationsUnit,
            plan.cancellations,
          )}
        />
        <CommissionDetailItem
          title={"Hiring Events"}
          value={getDisplayedValueByUnit(
            plan.hiringEventUnit,
            plan.hiringEvent,
          )}
        />
        <CommissionDetailItem
          title={"Rehire Referral"}
          value={getDisplayedValueByUnit(
            plan.reHireReferralUnit,
            plan.reHireReferral,
          )}
        />
        <CommissionDetailItem
          title={"Rehire Applied"}
          value={getDisplayedValueByUnit(
            plan.reHireAppliedUnit,
            plan.reHireApplied,
          )}
        />
        <CommissionDetailItem
          title={"Rehire Sourced"}
          value={getDisplayedValueByUnit(
            plan.reHireSourcedUnit,
            plan.reHireSourced,
          )}
        />
        <CommissionDetailItem
          title={"Internal"}
          value={getDisplayedValueByUnit(plan.internalUnit, plan.internal)}
        />
      </Row>
    </div>
  );
};
