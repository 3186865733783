import { Card, Space, Checkbox, Input } from "antd";
import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { displayPercentage } from "utilities/tableUtility";

export default function VBDStaticComponent({
  threshold,
  selectedTierId,
  getSelectedTiers,
  data,
  index,
}) {
  console.log("history", data);
  const getTierEntriesByTierId = (tierId) => {
    const entry = threshold.vbdTierEntries.find(
      (item) => parseInt(item.tierId) === tierId,
    );

    return entry;
  };
  return (
    <>
      <Card
        key={index}
        className="threshold-card"
        headStyle={{ padding: "0 10px" }}
        bodyStyle={{ padding: "15px 40px" }}
        actions={
          data.length - 1 === index && [
            <Checkbox
              disabled
              checked={threshold.isInfinite === 1 ? true : false}
            >
              Unlimited end value
            </Checkbox>,
          ]
        }
        title={
          <>
            Threshold{" "}
            <Space className="field-wrap">
              <Input
                disabled={true}
                min={1}
                className="count-field"
                value={threshold.min}
              ></Input>

              <ArrowRightOutlined />

              {threshold.isInfinite == 1 ? (
                <Input
                  disabled={true}
                  className="count-field"
                  style={{ fontSize: 23 }}
                  value={"∞"}
                ></Input>
              ) : (
                <Input
                  disabled={true}
                  className="count-field"
                  value={threshold.max}
                ></Input>
              )}
            </Space>
          </>
        }
      >
        {getSelectedTiers(selectedTierId).map((tier) => (
          <Input
            key={tier.id}
            disabled={true}
            className="ptage-field"
            size="large"
            value={displayPercentage(
              getTierEntriesByTierId(tier.id)?.percentageVal ?? 0,
            )}
          ></Input>
        ))}
      </Card>
    </>
  );
}
