import moment from "moment";
import momentBusiness from "moment-business-days";
import K from "./constants";
import {
  convertIntoDashUSFormat,
  formatDisplayTime,
  getCurrentDateTime,
} from "./dateUtility";
import history from "./history";
import {
  flatMap,
  isNil,
  isObject,
  keyBy,
  map,
  merge,
  template,
  uniqBy,
} from "lodash";

export const snakeCaseToSentence = (string) => {
  return string
    ?.split("_")
    ?.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    ?.join(" ");
};

export const lowerCaseAllExceptFirstLetter = (word) =>
  word.charAt(0) + word.substring(1).toLowerCase();

export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0;
};

export const getUserPermissions = (roles) => {
  return uniqBy(
    flatMap(roles, (r) => r.permissions),
    "name",
  );
};

export const hasSuperAdminPermission = (permissions) => {
  return permissions.some((p) => p.name === K.Permissions.SuperAdmin);
};

export const hasAccessToRoute = (route, roles, clients, isSuperAdmin) => {
  if (isSuperAdmin || (!route.permission && !route.extraCondition)) {
    return true;
  }

  if (route.extraCondition && !route.permission) {
    return route.extraCondition(clients);
  }

  const hasPermission = isPermissionPresent(route.permission, roles);

  if (route.extraCondition && route.permission) {
    return hasPermission && route.extraCondition(clients);
  }

  return hasPermission;
};

// TODO: Create a separated selector in redux like "selectUserPermissions"
// TODO: Re-write this function to user permissions from redux
/**
 * Checks if the specified permission is present for the given roles.
 *
 * This function will return true if the permission is null, undefined, or an empty array.
 * It also returns true if the user has super admin permissions.
 * If none of these conditions are met, it checks if the provided permission exists
 * in the user's permissions based on the provided roles.
 *
 * @param {string|Array<string>|null|undefined} permission - The permission to check.
 * Can be a single permission name, an array of permission names, or null/undefined.
 * @param {Array<string>} roles - The roles assigned to the user.
 * Used to retrieve the user's permissions.
 * @returns {boolean} - Returns true if the permission is present or if the user is a super admin,
 * otherwise returns false.
 */
export const isPermissionPresent = (permission, roles) => {
  if (isNil(permission) || (Array.isArray(permission) && !permission.length)) {
    return true;
  }

  const permissions = getUserPermissions(roles);

  if (hasSuperAdminPermission(permissions)) {
    return true;
  }

  return permissions.some((p) => {
    if (Array.isArray(permission)) {
      return permission.includes(p.name);
    }

    return p.name === permission;
  });
};

export const redirectToLogin = (error = "") => {
  if (typeof window !== "undefined") {
    let newUrl =
      window.location.protocol +
      "//" +
      K.Network.URL.Client.BaseHost +
      ":" +
      K.Network.URL.Client.BasePort +
      "/login";
    if (error !== "") {
      newUrl += `?err=${error}`;
    }
    // TODO: change location by history.push or window.location.assign(newUrl);
    // TODO: add the test case for this function (using mock window.location.assign)
    window.location = newUrl;
  }
};

export const redirectToUrl = (path) => {
  window.location =
    window.location.protocol +
    "//" +
    K.Network.URL.Client.BaseHost +
    ":" +
    K.Network.URL.Client.BasePort +
    path;

  // TODO: change location by history.push or window.location.assign(newUrl);
  // TODO: add the test case for this function (using mock window.location.assign)
};

export const setFieldErrorsFromServer = (error, form, values = undefined) => {
  if (error.error === undefined) return;
  const errors = error.error.data.errors;
  if (typeof errors === "string" || errors instanceof String) {
    return;
  }
  let fieldErrors = [];
  for (let key in errors) {
    if (errors.hasOwnProperty(key)) {
      // let fieldError = errors[key].map((error) => {
      //     return error;
      // });
      fieldErrors.push({
        name: key,
        errors: errors[key],
        value: values && values[key] ? values[key] : undefined,
      });
    }
  }
  form.setFields(fieldErrors);
};

export const snakeToCamel = (str) => {
  return str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", ""),
    );
};
export const pascalCase = (str) => {
  return str.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

export const camelCaseKeys = (obj) => {
  if (isNil(obj) || !isObject(obj)) {
    return obj;
  }

  return Object.keys(obj)?.reduce(
    (ccObj, field) => ({
      ...ccObj,
      [snakeToCamel(field)]: obj[field],
    }),
    {},
  );
};

export const camelCaseKeysRecursively = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelCaseKeysRecursively(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeToCamel(key)]: camelCaseKeysRecursively(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

export const convertIntoTitleCase = (s) =>
  s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());

export const convertIntoSnakeCase = (string = "") => {
  // Replace spaces and hyphens with underscores
  const noSpaces = string.replace(/ /g, "_").replace(/-/g, "_");

  // Split the string into words
  const words = noSpaces.split(/[^a-zA-Z0-9]+/);

  // Convert each word to lowercase and join with underscores
  const snakeCaseString = words.map((word) => word.toLowerCase()).join("_");

  return snakeCaseString;
};

export const deleteQueryParam = (key) => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.delete(key);
  history.push({
    search: queryParams.toString(),
  });
};

export const validateMobileNumber = (value) => {
  const format = /[ `!@#$%^&*()_+-=[]{};':"\|,.<>\/?~]/;
  if (!format.test(value)) {
    return value.match(/\d/g).join("").substring(1).length === 9;
  } else {
    return value.match(/\d/g).join("").substring(1).length === 10;
  }
};

export const noTrailingSpaceAllowedRule = () => ({
  pattern: new RegExp("^[^\\s]+(\\s+[^\\s]+)*$", "g"),
  message: "Leading and trailing spaces are not allowed ",
});

export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const titleCase = (string) => {
  const sentence = string.toLowerCase();
  const titleCaseSentence =
    sentence.charAt(0).toUpperCase() + sentence.substring(1, sentence.length);
  return titleCaseSentence;
};

export const removeUnderScore = (sentence) => {
  return sentence
    ? sentence
        .split("_")
        .map((word) => titleCase(word))
        .join(" ")
    : K.NullPlaceholder;
};

export const toolTipRemoveUnderScore = (sentence) => {
  return (
    <div className="client-list-tooltip">
      {sentence
        ? sentence
            .split("_")
            .map((word) => titleCase(word))
            .join(" ")
        : K.NullPlaceholder}
    </div>
  );
};

export const removeSpacesAndPascalCase = (inputString) => {
  return inputString
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join("");
};
export const removeSpacesAndPascalSentenceCase = (inputString) => {
  return inputString
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join(" ");
};

export const checkStatusExist = (statusName = "", subStatusName = "") => {
  const status = Object.keys(K.Status).find((key) => {
    return key === removeSpacesAndPascalCase(statusName);
  });

  if (status) {
    if (subStatusName !== "") {
      const subStatus = Object.keys(
        K.Status[removeSpacesAndPascalCase(statusName)].subStatus,
      ).find((key) => key === removeSpacesAndPascalCase(subStatusName));
      return subStatus ? true : false;
    }
    return status ? true : false;
  }
  return false;
};

export const checkNullPlaceHolder = (value) => {
  return value ? value : K.NullPlaceholder;
};
export const toolTipCheckNullPlaceHolder = (value) => {
  return (
    <div className="client-list-tooltip">
      {value ? value : K.NullPlaceholder}
    </div>
  );
};

export const snakeCaseToSentenceWithSpace = (string) => {
  const result = string.replace(/([a-z])([A-Z])/g, "$1 $2");
  const finalResult = result
    .split(" ")
    .map((word, index) =>
      index === 0 || word.toLowerCase() !== "to"
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word.toLowerCase(),
    )
    .join(" ");
  return finalResult;
};
export const stopPropagationFunction = (e) => {
  e.stopPropagation();
};

export const formatClientRulesEntries = (rule) => {
  const formValues = {},
    billingTypes = new Set();
  rule.clientRuleEntries.forEach((el) => {
    billingTypes.add(el.clientLevelBillingTypesId);
    if (el.clientLevelBillingTypesId in formValues) {
      const prev = formValues[el.clientLevelBillingTypesId];
      formValues[el.clientLevelBillingTypesId] = [
        ...prev,
        {
          alias: el.aliasName,
          amount: el.amount,
          currency: el.currency,
          ...(el.clientLevelBillingType.type === K.BillingTypes.Recursive &&
            getRecursiveValues(el.calendarEntry)),
        },
      ];
    } else {
      formValues[el.clientLevelBillingTypesId] = [
        {
          alias: el.aliasName,
          amount: el.amount,
          currency: el.currency,
          ...(el.clientLevelBillingType.type === K.BillingTypes.Recursive &&
            getRecursiveValues(el.calendarEntry)),
        },
      ];
    }
  });
  formValues.billingTypes = [...billingTypes];
  return formValues;
};

export const formatJobRulesEntries = (rule) => {
  const formValues = {
    billingTypes: [],
    guaranteeDays: rule.guaranteeDays,
    isWorkingDays: rule.isWorkingDays === 1 ? true : false,
  };

  rule.billingRuleEntries.forEach((el) => {
    formValues.billingTypes.push(el.jobBillingTypesId);
    if (el.jobBillingType.type === K.BillingTypes.Retention) {
      formValues[el.jobBillingTypesId] = {
        amountType: el.amountType,
        currency: el.currency,
        isCommissionable: el.isCommissionable,
        noOfMonths: el.retentionEntries.length,
        retentionType: el.retentionType,
        retentionEntries: el.retentionEntries.map((entry) => ({
          monthOrder: entry.monthOrder,
          amount: entry.amount,
          referral: entry.referral,
          reHired: entry.reHired,
          internal: entry.internal,
        })),
      };
    } else {
      formValues[el.jobBillingTypesId] = {
        amount: el.amount,
        amountType: el.amountType,
        currency: el.currency,
        isCommissionable: el.isCommissionable,
        reHired: el.reHired,
        referral: el.referral,
        internal: el.internal,
        markup: el.markupRatio,
        ...(el.jobBillingType.type === K.BillingTypes.TimeBased && {
          days: el.days,
        }),
        ...(el.jobBillingType.type === K.BillingTypes.Recursive &&
          getRecursiveValues(el.calendarEntry)),
      };
    }
  });
  return formValues;
};

export const getRecursiveValues = (calendarEntry) => {
  const recursiveValues = {
    recurringType: calendarEntry.repeatType.toUpperCase(),
    repetitiveQuantity: calendarEntry.repetitiveQuantity,
    isInfinite: calendarEntry.isInfinite,
    ...(!calendarEntry.isInfinite && {
      endAfterOccurances:
        calendarEntry.endAfterOccurances > 0
          ? calendarEntry.endAfterOccurances
          : 0,
    }),
  };

  if (recursiveValues.recurringType === K.RecurringType.Month) {
    recursiveValues.repetitiveMonthDay = calendarEntry.repetitiveMonthDay;
  } else {
    const selectedDays = [];
    for (let i = 0; i < 7; i++) {
      if (calendarEntry[`repetitiveWeekDay_${i}`]) {
        selectedDays.push(i);
      }
    }
    recursiveValues.selectedDays = selectedDays;
  }

  return recursiveValues;
};

export const downloadFile = (data, fileName) => {
  console.log(getCurrentDateTime());
  const href = URL.createObjectURL(data);
  const link = document.createElement("a");

  link.href = href;
  link.setAttribute("download", `${fileName}_${getCurrentDateTime()}.xlsx`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const customUserHandleSearch = (input, option) => {
  input = input.trim()?.toLowerCase();
  console.log("Option", option);
  return (
    //search on name
    option.children.props.children.toLowerCase().indexOf(input) >= 0 ||
    //search on user email
    option.children.props.userEmail.toLowerCase().indexOf(input) >= 0 ||
    //search on client name
    option.children.props?.clients?.filter(
      ({ name }) => name.toLowerCase().indexOf(input) >= 0,
    ).length > 0
  );
};
export const customJobHandleSearch = (input, option) => {
  input = input.trim()?.toLowerCase();
  console.log("option", option);
  return (
    //search on name
    option.children.props.children.toLowerCase().indexOf(input) >= 0 ||
    //search on user email
    option.children.props.title.toLowerCase().indexOf(input) >= 0 ||
    //search on job loation
    option.children.props.location.toLowerCase().indexOf(input) >= 0 ||
    //search on client name
    option.children.props.client.name.toLowerCase().indexOf(input) >= 0 ||
    //search on ats id
    option.children.props.extAtsId?.toLowerCase().indexOf(input) >= 0
  );
};

export const percentageFormatter = (value) =>
  `${value} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const calculateWorkingDays = (startDate, endDate) => {
  momentBusiness.updateLocale("us", {
    nextBusinessDayLimit: 31,
  });

  let a = momentBusiness(startDate);
  let b = momentBusiness(endDate);

  let days = b.businessDiff(a, true);

  return days;
};

export const multiValuecomparator = (value1, value2, field) => {
  // Split the values by commas and trim spaces
  const values1 = value1.map((v) => v[`${field}`].trim());
  const values2 = value2.map((v) => v[`${field}`].trim());

  // Compare the sorted values
  for (let i = 0; i < Math.min(values1.length, values2.length); i++) {
    const comparison = values1[i].localeCompare(values2[i]);

    if (comparison !== 0) {
      return comparison;
    }
  }

  // If all values are the same so far, compare the lengths
  return values1.length - values2.length;
};

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

//This method is responsible to filter and create a new udf object for edit or create payload and also removes the raw udf values from the
export const generateUdfValuesObject = (udfs, values) => {
  return udfs.map((item) => {
    let parsedValue = null;
    if (K.UDF.Fields[item.fieldTypeId].isDate) {
      parsedValue = values[item.id]
        ? moment(values[item.id]).format(K.DateFormat.DashUSFormat)
        : null;
    } else if (K.UDF.Fields[item.fieldTypeId].isTime) {
      parsedValue = values[item.id]
        ? moment(values[item.id]).format(K.TimeFomat.Response)
        : null;
    } else if (
      K.UDF.Fields[item.fieldTypeId].isOption === true &&
      K.UDF.Fields[item.fieldTypeId].isMultiValue === false
    ) {
      parsedValue = values[item.id] ? [values[item.id]] : [];
    } else {
      parsedValue = values[item.id] ?? null;
    }
    return {
      udfId: item.id,
      value: !Array.isArray(parsedValue) ? parsedValue : null,
      optionIds: Array.isArray(parsedValue) ? parsedValue : [],
    };
  });
};

//remove scattered keys from onfinish values
export const removeUdfKeysFromObject = (obj, udfs) => {
  const udfIds = udfs.map((u) => u.id);
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !udfIds.includes(Number(key))),
  );
};

//To sett he udf form set field value
export const generateUdfSetFieldValues = (udfs) => {
  return udfs.reduce((prev, curr) => {
    const defiendValue = curr.userDefineValues.map((item) => ({
      id: item.id,
      value: item.value,
      optionIds: item.udfMultiValueFieldTypeValsId,
    }));

    let formatValue = undefined;
    const value = [null, ""].includes(
      defiendValue[defiendValue.length - 1]?.value,
    )
      ? null
      : defiendValue[defiendValue.length - 1]?.value;

    if (defiendValue.length > 0) {
      if (K.UDF.Fields[curr.fieldTypeId].isTime) {
        formatValue = value ? moment(value, K.TimeFomat.Display) : null;
      } else if (K.UDF.Fields[curr.fieldTypeId].isDate) {
        formatValue = value ? moment(value) : null;
      } else if (
        K.UDF.Fields[curr.fieldTypeId].isMultiValue === false &&
        K.UDF.Fields[curr.fieldTypeId].isOption === false
      ) {
        formatValue = value;
      } else if (
        K.UDF.Fields[curr.fieldTypeId].isMultiValue === false &&
        K.UDF.Fields[curr.fieldTypeId].isOption === true
      ) {
        formatValue = +defiendValue[defiendValue.length - 1]?.optionIds ?? null;
      } else {
        formatValue = defiendValue.map((val) => {
          return +val.optionIds ?? null;
        });
      }
    }
    return {
      ...prev,
      [curr.id]: formatValue,
    };
  }, {});
};

export const displayUdfFieldValues = (udfs) => {
  return udfs.reduce((prev, curr) => {
    const defiendValue = curr.userDefineValues.map((item) => ({
      id: item.id,
      value: item.value,
      optionIds: item.udfMultiValueFieldTypeValsId,
    }));

    let formatValue = null;
    const value = [null, "", undefined].includes(
      defiendValue[defiendValue.length - 1]?.value,
    )
      ? null
      : defiendValue[defiendValue.length - 1]?.value;

    if (K.UDF.Fields[curr.fieldTypeId].isTime) {
      formatValue = formatDisplayTime(value);
    } else if (K.UDF.Fields[curr.fieldTypeId].isDate) {
      formatValue = convertIntoDashUSFormat(value, false);
    } else if (
      K.UDF.Fields[curr.fieldTypeId].isOption === false &&
      K.UDF.Fields[curr.fieldTypeId].isMultiValue === false
    ) {
      formatValue = value;
    } else {
      formatValue = mapping(
        curr.udfMultiValueFieldTypeVal,
        curr.userDefineValues,
      )
        .map((item) => item.value)
        .join(" , ");
    }

    return [
      ...prev,
      {
        label: curr.label,
        value: formatValue,
        ...curr,
      },
    ];
  }, []);
};

export const mapping = (optionObj, userValues) => {
  return userValues.reduce((prev, curr) => {
    const data = optionObj.find(
      (value) => +curr?.udfMultiValueFieldTypeValsId === value?.id,
    );

    return [...prev, { id: curr.id, value: data?.value }];
  }, []);
};

export const getIsVisibleLookups = (data, selectedValue) => {
  selectedValue = Array.isArray(selectedValue)
    ? selectedValue
    : [selectedValue];
  return data.filter(
    (item) => selectedValue.includes(item.id) || item.isVisible === 1,
  );
};

export const mergeArrayOfObjects = (arr1, arr2, getKey = (i) => i.id) => {
  const lookup = keyBy(arr2, getKey);

  return map(arr1, (item) => {
    return merge({}, item, lookup[getKey(item)] || {});
  });
};

export const arrayToOptions = (array) => {
  return array.map((v) => ({
    value: v.key,
    label: v.label,
  }));
};

export const interpolateUrl = (url, values) => {
  return template(url, { interpolate: /:([\w]+)/g })(values);
};
