import NetworkCall from "network/networkCall";
import Request from "network/request";
import { downloadFile } from "utilities/generalUtility";

export default class Export {
  static async getReportJobsVacancies(params, useLoading = true) {
    const res = await NetworkCall.fetch(
      Request.v2.exportReportJobsVacancies(params),
      useLoading,
    );
    downloadFile(res, "job_data_export");
  }

  static async getReportCandidateJobEntries(params, useLoading = true) {
    return NetworkCall.fetch(
      Request.v2.exportReportCandidateJobEntries(params),
      useLoading,
    );
  }

  static async getReportEngagements(params) {
    return NetworkCall.fetch(Request.v2.exportEngagements(params));
  }
}
