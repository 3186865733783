import { AgGridReact } from "ag-grid-react";
import { Button, message, Tag } from "antd";
import CustomPaginationComponent from "common/components/CustomPagination/customPagination";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import Candidate from "redux/models/candidate";
import LookupTable from "redux/models/lookupTable";
import User from "redux/models/user";
import {
  selectCandidates,
  setCandidateList,
  setShouldReinitialize,
} from "redux/slices/candidateSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  camelToSnakeCase,
  checkNullPlaceHolder,
  checkStatusExist,
} from "utilities/generalUtility";
import { filterColumnListing, stringSorting } from "utilities/tableUtility";

const tagColors = {
  Prospect: "#D9D9D9",
  Candidates: "#F6A2E6",
  Submitted: "#BDD7EE",
  Offer: "#99FF99",
  Withdrew: "#F9B367",
  Declined: "#FF9999",
  Hired: "#00CC66",
  Terminated: "#FF9999",
  "S2 Interview": "#33CCFF",
  "Client Interview": "#CC99FF",
  "No Show to Day 1": "#FF9999",
  "Within Guarantee": "#FFB66D",
  "Within Retention Period": "#FFB66D",
  "Outside Retention Period": "#FF9999",
};

const pageSize = 25;

export default function CandidateListing({
  candidateActivity,
  setCandidateActivity,
  setModalVisible,
  gridRef,
  searchRef,
  setRefreshTable,
  refreshTable,
  setSelectedData,
  selectedData,
  currentPage,
  setCurrentPage,
}) {
  const statusRef = useRef(null);
  const selectedRowRef = useRef(0);

  const getRowStyle = (params) => {
    if (params.data?.isConsiderable === 0) {
      return { opacity: 0.5 }; // Disable row
    }

    return null; // Enable row
  };

  const isRowSelectable = (params) => {
    return params.data?.isConsiderable === 1; // Allow selection if age is less than or equal to 35
  };
  const sortRef = useRef({ sortCol: "candidate_job_entry.id", sort: "asc" });
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);

  const history = useHistory();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [gridApi, setGridApi] = useState(null);

  const userDetails = useSelector(selectUser).details;
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const onPageChanged = (newPage) => {
    setCurrentPage((prev) => ({
      ...prev,
      pageNo: newPage,
      shouldUpdate: !prev.shouldUpdate,
    }));
    searchParams.set("pageNo", newPage);
    history.push({ search: searchParams.toString() });
  };

  const candidateNameToolip = (params) => (
    <div className="client-list-tooltip">
      {params.data.candidate?.firstName + " " + params.data.candidate?.lastName}
    </div>
  );
  const candidateTypeToolip = (params) => (
    <div className="client-list-tooltip">
      {checkNullPlaceHolder(K.CandidateType[params.value])}
    </div>
  );
  const statusToolip = (params) => (
    <div className="client-list-tooltip">{params.data.status.name}</div>
  );
  const subStatusToolip = (params) => (
    <div className="client-list-tooltip">{params.data.subStatus.name}</div>
  );

  const candidateStatusToolip = (params) => (
    <div className="client-list-tooltip">
      {params.data?.candidateStatus?.name}
    </div>
  );
  const candidateStatusRenderer = (params) =>
    params.data?.candidateStatus?.name ?? "N/A";

  const candidateIdRenderer = (params) => params.data?.candidate.id ?? "N/A";

  const candidateIdToolip = (params) => (
    <div className="client-list-tooltip">{params.data?.candidate?.id}</div>
  );
  const candidateEmailRenderer = (params) =>
    params.data?.candidate.email ?? "N/A";
  const candidateEmailToolip = (params) => (
    <div className="client-list-tooltip">{params.data?.candidate?.email}</div>
  );

  const { jobId, statusesId, statusName, subStatusesId, subStatusName } =
    useParams();
  const candidates = useSelector(selectCandidates);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "candidates.id",
      sortable: true,
      tooltipField: "candidate.id",
      checkboxSelection: true,
      suppressColumnsToolPanel: true,
      cellRenderer: candidateIdRenderer,
      tooltipComponent: candidateIdToolip,
    },

    {
      headerName: "Name",
      field: "candidates.firstName",
      sortable: true,
      tooltipField: "candidates.firstName",
      tooltipComponent: candidateNameToolip,
      getQuickFilterText: (params) => {
        return [
          params.data.candidate?.firstName,
          params.data.candidate?.lastName,
        ].join(" ");
      },
      cellRenderer: (params) => (
        <div>
          {[
            params.data.candidate?.firstName,
            params.data.candidate?.lastName,
          ].join(" ")}
        </div>
      ),
    },
    {
      headerName: "Email",
      field: "candidates.email",
      sortable: true,
      tooltipField: "candidates.email",
      cellRenderer: candidateEmailRenderer,
      tooltipComponent: candidateEmailToolip,
    },
    {
      headerName: "Mobile Number",
      field: "candidates.mobileNumber",
      sortable: true,
      tooltipField: "candidates.mobileNumber",
      getQuickFilterText: (params) => params.candidate.mobileNumber,
      cellRenderer: (params) =>
        checkNullPlaceHolder(params.data.candidate.mobileNumber),
    },
    {
      headerName: "Candidate Type",
      field: "hireType",
      sortable: true,
      tooltipField: "hireType",
      getQuickFilterText: (params) => K.CandidateType[params.value],
      cellRenderer: (params) =>
        checkNullPlaceHolder(K.CandidateType[params.value]),
      tooltipComponent: candidateTypeToolip,
    },
    {
      headerName: "Candidate Status",
      field: "candidate_statuses.id",
      sortable: true,
      tooltipField: "candidateStatus",
      getQuickFilterText: candidateStatusRenderer,
      cellRenderer: candidateStatusRenderer,
      tooltipComponent: candidateStatusToolip,
    },
    {
      headerName: "Current Title",
      field: "candidates.currentTitle",
      sortable: true,
      tooltipField: "candidates.currentTitle",
      getQuickFilterText: (params) => params.candidate.currentTitle,
      cellRenderer: (params) =>
        checkNullPlaceHolder(params.data.candidate.currentTitle),
    },
    {
      headerName: "Status",
      field: "statuses.name",
      sortable: true,
      tooltipField: "statuses.name",
      getQuickFilterText: (params) => params.data.status.name,
      tooltipComponent: statusToolip,
      comparator: (a, b, nodeA, nodeB, isDescending) =>
        stringSorting(a, b, "name"),
      cellRenderer: (params) => {
        return (
          <Tag
            className="tagTextColor"
            color={tagColors[params.data.status.name]}
          >
            {params.data.status.name}
          </Tag>
        );
      },
    },
    {
      headerName: "Sub Status",
      field: "subStatuses.name",
      sortable: true,
      tooltipField: "subStatuses.name",
      tooltipComponent: subStatusToolip,
      getQuickFilterText: (params) => params.data.subStatus.name,

      comparator: (a, b, nodeA, nodeB, isDescending) =>
        stringSorting(a, b, "name"),
      cellRenderer: (params) => {
        const colorHexValue =
          params.data.status.name === "Terminated"
            ? tagColors[params.data.subStatus.name]
            : tagColors[params.data.status.name];
        return (
          <Tag className="tagTextColor" color={colorHexValue}>
            {params.data.subStatus.name}
          </Tag>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      sortable: false,
      resizable: false,
      headerClass: "text-center",
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => (
        <Button
          type="link"
          className="view-activity-btn view-activity-btn-clr"
          onClick={(e) => {
            params.api.forEachNode((node) => {
              if (node.rowIndex === params.rowIndex) {
                node.setSelected(true);
              } else {
                node.setSelected(false);
              }
            });
            // params.node.setSelected(true);
            selectedRowRef.current = 1;

            fetchCandidateDetails(params);
          }}
        >
          View Activity
        </Button>
      ),
    },
  ]);

  const showModalViewActivity = () => {
    setModalVisible((prev) => {
      return {
        ...prev,
        isActivityModalVisible: true,
      };
    });
  };

  const fetchCandidateDetails = async (params) => {
    try {
      const res = await Candidate.getActivity(params.data.id);

      if (selectedRowRef.current === 1 || params.data.isConsiderable === 0) {
        setCandidateActivity(res);
        showModalViewActivity();
        dispatch(setShouldReinitialize());
      }
    } catch (err) {
      message.error("Failed to fetch candidate!");
      console.error(err);
    }
  };

  const getCandidateByJobId = async (body) => {
    try {
      const data = await Candidate.getByJobId(body);
      return data;
    } catch (err) {
      console.error(err);
    }
  };
  const sortCandidateByStatus = async (statusId, subStatusId = null) => {
    try {
      statusRef.current = {
        statusId: statusesId ? statusesId : statusId,
        subStatusId: subStatusesId ? subStatusesId : subStatusId,
      };
      if (searchParams.get("pageNo" !== 1)) {
        searchParams.set("pageNo", 1);
        history.push({ search: searchParams.toString() });
      }
      fetchDataFromServer();
    } catch (err) {
      console.error(err);
    }
  };
  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.JobDetailCandidateColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "candidates.id")
          return {
            ...item,
            checkboxSelection: true,
            cellRenderer: candidateIdRenderer,
            tooltipComponent: candidateIdToolip,
          };
        else if (item.field === "candidates.firstName")
          return {
            ...item,

            tooltipComponent: candidateNameToolip,
            getQuickFilterText: (params) => {
              return [
                params.data.candidate?.firstName,
                params.data.candidate?.lastName,
              ].join(" ");
            },
            cellRenderer: (params) => (
              <div>
                {[
                  params.data.candidate?.firstName,
                  params.data.candidate?.lastName,
                ].join(" ")}
              </div>
            ),
          };
        else if (item.field === "candidates.email")
          return {
            ...item,
            cellRenderer: candidateEmailRenderer,
            tooltipComponent: candidateEmailToolip,
          };
        else if (item.field === "candidates.mobileNumber")
          return {
            ...item,
            getQuickFilterText: (params) => params.candidate.mobileNumber,
            cellRenderer: (params) =>
              checkNullPlaceHolder(params.data.candidate.mobileNumber),
          };
        else if (item.field === "candidate_statuses.id")
          return {
            ...item,
            getQuickFilterText: candidateStatusRenderer,
            cellRenderer: candidateStatusRenderer,
            tooltipComponent: candidateStatusToolip,
          };
        else if (item.field === "hireType")
          return {
            ...item,
            getQuickFilterText: (params) => K.CandidateType[params.value],
            cellRenderer: (params) =>
              checkNullPlaceHolder(K.CandidateType[params.value]),
            tooltipComponent: candidateTypeToolip,
          };
        else if (item.field === "candidates.currentTitle")
          return {
            ...item,
            getQuickFilterText: (params) => params.candidate.currentTitle,
            cellRenderer: (params) =>
              checkNullPlaceHolder(params.data.candidate.currentTitle),
          };
        else if (item.field === "statuses.name")
          return {
            ...item,
            getQuickFilterText: (params) => params.data.status.name,
            tooltipComponent: statusToolip,
            comparator: (a, b, nodeA, nodeB, isDescending) =>
              stringSorting(a, b, "name"),
            cellRenderer: (params) => {
              return (
                <Tag
                  className="tagTextColor"
                  color={tagColors[params.data.status.name]}
                >
                  {params.data.status.name}
                </Tag>
              );
            },
          };
        else if (item.field === "subStatuses.name")
          return {
            ...item,
            tooltipComponent: subStatusToolip,
            getQuickFilterText: (params) => params.data.subStatus.name,

            comparator: (a, b, nodeA, nodeB, isDescending) =>
              stringSorting(a, b, "name"),
            cellRenderer: (params) => {
              const colorHexValue =
                params.data.status.name === "Terminated"
                  ? tagColors[params.data.subStatus.name]
                  : tagColors[params.data.status.name];
              return (
                <Tag className="tagTextColor" color={colorHexValue}>
                  {params.data.subStatus.name}
                </Tag>
              );
            },
          };
        else if (item.field === "actions")
          return {
            ...item,
            resizable: false,
            headerClass: "text-center",
            cellStyle: { textAlign: "center" },
            cellRenderer: (params) => (
              <Button
                type="link"
                className="view-activity-btn view-activity-btn-clr"
                onClick={(e) => {
                  params.api.forEachNode((node) => {
                    if (node.rowIndex === params.rowIndex) {
                      node.setSelected(true);
                    } else {
                      node.setSelected(false);
                    }
                  });
                  selectedRowRef.current = 1;

                  fetchCandidateDetails(params);
                }}
              >
                View Activity
              </Button>
            ),
          };

        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchDataFromServer = async (newPage) => {
    const page = searchParams.get("pageNo")
      ? parseInt(searchParams.get("pageNo"))
      : newPage ?? 1;

    console.log(camelToSnakeCase("candidates"));
    const body = {
      page,
      pageSize,
      job_source_id: jobId,
      search: searchRef.current,
      filter: statusRef.current,
      sortBy: sortRef.current.sortCol,
      sortOrder: sortRef.current.sort,
    };
    try {
      const data = await getCandidateByJobId(body);

      dispatch(setCandidateList(data.candidate));

      const totalCount = data.totalCount; // Assuming data.totalCount is the total count of items
      const totalPages = Math.ceil(totalCount / pageSize);
      setTotalPages(totalPages);
      setTotalCount(totalCount);
      setCurrentPage((prev) => ({
        ...prev,
        currentCount: data.candidate.length,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const updateColumns = async (event) => {
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    if (shouldUpdate)
      try {
        await User.saveColumnSort({
          usersId: userDetails.id,
          tableName: K.AgGridTable.Keys.JobDetailCandidateColumn,
          configJson: JSON.stringify(cols),
        });
        message.success("Columns order saved successfully");
      } catch (err) {
        console.error(err);
      }
    else setShouldUpdate(true);
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userDetails.id,
        tableName: K.AgGridTable.Keys.JobDetailCandidateColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const handleSortChanged = (params) => {
    const sortModel = params.columnApi
      .getColumnState()
      .filter((col) => col.sort);
    if (sortModel.length > 0) {
      const sortedColumn = sortModel[0].colId;
      sortRef.current = {
        sortCol: camelToSnakeCase(sortedColumn),
        sort: sortModel[0].sort,
      };
      fetchDataFromServer();
    }
  };

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();

    setSelectedData((prev) => ({
      ...prev,
      isDisabled: selectedRows.length === 0,
      candidateJobEntryIds: selectedRows.map(({ id }) => id),
    }));
  };

  const getCandidateStatus = async () => {
    try {
      await dispatch(
        LookupTable.getData(
          K.Redux.CandidateStatus,
          K.Network.URL.Lookup.CandidateStatus,
        ),
      );
    } catch (err) {
      console.error(err);
    }
  };

  const onRowSelected = (params) => {
    let selectedRows = [];
    let candidateJobEntryId = [];
    let isDisabled = true;

    selectedRows = params.api.getSelectedRows();

    candidateJobEntryId = selectedRows.map((item) => item.id);
    if (selectedRows >= 1) {
      isDisabled = false;
    }
    if (selectedRows.length > 1) {
      setCandidateActivity(null);
    }
    setSelectedData((prev) => ({
      ...prev,
      candidateJobEntryIds: candidateJobEntryId,
      isDisabled: isDisabled,
    }));

    selectedRowRef.current = selectedRows.length;
  };

  useEffect(() => {
    if (statusesId && checkStatusExist(statusName, subStatusName)) {
      sortCandidateByStatus();
    } else {
      statusRef.current = null;
      fetchDataFromServer();
    }
  }, [statusesId, subStatusesId, currentPage.shouldUpdate, refreshTable]);

  useEffect(() => {
    searchParams.set("pageNo", 1);
    history.push({ search: searchParams.toString() });
    getColumnsConfigrations();
    getCandidateStatus();
  }, []);

  return (
    <>
      <div
        className="ag-theme-alpine s2-theme-style"
        style={{
          height: 560,
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={candidates.listing}
          columnDefs={columnDefs}
          suppressRowClickSelection
          rowSelection="multiple"
          onColumnMoved={updateColumns}
          onSelectionChanged={onSelectionChanged}
          onColumnPinned={onColumnVisible}
          onColumnVisible={onColumnVisible}
          getRowStyle={getRowStyle}
          isRowSelectable={isRowSelectable}
          onGridReady={onGridReady}
          defaultColDef={K.AgGridTable.DefaultColDef}
          loadingCellRenderer={false}
          onRowSelected={onRowSelected}
          onSortChanged={handleSortChanged}
        />

        <CustomPaginationComponent
          totalPages={totalPages}
          pageSize={pageSize}
          totalCount={totalCount}
          currentPage={currentPage.pageNo}
          currentCount={currentPage.currentCount}
          onPageChange={onPageChanged}
        />
      </div>
    </>
  );
}
