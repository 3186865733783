import { UserOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Select,
  Table,
  Tag,
  Typography,
} from "antd";
import "assets/fonticons/style.css";
import PdfLogo from "assets/images/pdflogo.png";
import LayoutCss from "layout/layout.module.scss";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import BillingApproval from "redux/models/billingApproval";
import Invoice from "redux/models/invoice";
import K from "utilities/constants";
import {
  calculateDaysDifference,
  convertIntoDashUSFormat,
  convertIntoUSFormat,
  disabledDatesPriorMonth,
  getMonthName,
} from "utilities/dateUtility";
import {
  checkNullPlaceHolder,
  convertIntoTitleCase,
  removeUnderScore,
  isPermissionPresent,
} from "utilities/generalUtility";
import { displayDollar, displayPercentage } from "utilities/tableUtility";
import BillingDetails from "../submitToBilling/billingDetails";
import styles from "./clientInvoices.module.scss";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { selectUser } from "redux/slices/userSlice";
import { useSelector } from "react-redux";

const { Title, Paragraph, Text } = Typography;
const {
  Invoice: { Type },
} = K;
const tagColors = {
  CONFLICTED: "error",
  DRAFT: "warning",
  PAID: "success",
  PENDING: "blue",
};

export default function InvoiceDetails() {
  const { Option } = Select;

  const { id } = useParams();
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const userSlice = useSelector(selectUser);
  const history = useHistory();
  const [pageData, setPageData] = useState({
    details: null,
    overDueDays: 0,
    isEditMode: false,
    selectedRecord: null,
    conversionHistory: [],
    isModalVisible: false,
    isClientBillingRow: false,
    isBillingModalVisible: false,
    isReadOnly: true,
  });
  const { details } = pageData;
  const candidateHireTypeRenderer = (hireType) =>
    hireType ? convertIntoTitleCase(hireType) : K.NullPlaceholder;

  const clientColumns = [
    {
      title: "Description",
      dataIndex: "title",
      align: "center",
      render: (params) => checkNullPlaceHolder(params),
    },
    {
      title: "Billing Type",
      dataIndex: "billingTypeName",
      align: "center",
      render: (params) => checkNullPlaceHolder(params),
    },
    {
      title: "Price",
      dataIndex: "dublicateAmount",
      align: "center",
      render: (text) => displayDollar(+text.toFixed(2)),
    },
    {
      title: "Billing Month",
      align: "center",
      render: (params) => {
        return params.billMonth != null && params.billMonth !== "0000-00-00"
          ? getMonthName(params.billMonth)
          : getMonthName(params.createdAt, true);
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      align: "center",
      render: (source) => checkNullPlaceHolder(source?.name),
    },
  ];

  const jobColumns = [
    {
      title: "Candidate",
      dataIndex: "candidateName",
      align: "center",
      render: (params) => checkNullPlaceHolder(params),
    },
    {
      title: "Candidate Type",
      dataIndex: "candidateJobEntry",
      align: "center",
      render: (params) => candidateHireTypeRenderer(params?.hireType),
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      align: "center",
    },
    {
      title: "Billing Type",
      dataIndex: "billingTypeName",
      align: "center",
      render: (params) => checkNullPlaceHolder(params),
    },
    {
      title: "Job Location",
      dataIndex: "jobSource",
      align: "center",
      render: (params) => checkNullPlaceHolder(params?.jobLocation?.name),
    },
    {
      title: "Job Department",
      dataIndex: "jobSource",
      align: "center",
      render: (params) => checkNullPlaceHolder(params?.jobDepartment?.name),
    },
    {
      title: "Job Group",
      dataIndex: "jobSource",
      align: "center",
      render: (params) => checkNullPlaceHolder(params?.jobGroup?.name),
    },

    {
      title: "Original Price",
      align: "center",
      render: (params) => displayDollar(+params?.amount),
    },
    {
      title: "Discount %",
      dataIndex: "discountPercentage",
      align: "center",
      render: (text) => (text ? displayPercentage(text) : 0.0),
    },
    {
      title: "Price",
      dataIndex: "dublicateAmount",
      align: "center",
      render: (text) => (text ? displayDollar(text) : 0.0),
    },
    {
      title: "Candidate ATS ID",
      dataIndex: "candidateJobEntry",
      align: "center",
      render: (params) => checkNullPlaceHolder(params?.atsLookupId),
    },

    {
      title: "Start Date",
      dataIndex: "candidateJobEntry",
      align: "center",
      render: (params) => convertIntoDashUSFormat(params?.startDate, false),
    },
    {
      title: "Billing Month",
      dataIndex: "createdAt",
      align: "center",
      render: (params) => {
        return getMonthName(params, true);
      },
    },
    {
      title: "Source",
      dataIndex: "candidateJobEntry",
      align: "center",
      render: (data) => checkNullPlaceHolder(data?.source?.name),
    },
    {
      title: "Credit Reason",
      dataIndex: "issueCreditReason",
      align: "center",
    },
  ];

  const columns = !pageData.isClientBillingRow ? jobColumns : clientColumns;

  const getBillHistory = async (payload) => {
    try {
      const res = await BillingApproval.getSubmittedBillHistory(payload);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const editAmount = async () => {
    const payload = {
      edit: true,
      dublicateAmount: +inputRef.current.value,
      currency: pageData.selectedRecord.convertToCurrency,
    };
    try {
      const res = pageData.isClientBillingRow
        ? await BillingApproval.editClientSubmittedBilling({
            ...payload,
            clientSubmittedBillId: pageData.selectedRecord.id,
          })
        : await BillingApproval.editJobSubmittedBilling({
            ...payload,
            jobSubmittedBillId: pageData.selectedRecord.id,
          });
      const history = await getBillHistory({
        type: pageData.isClientBillingRow ? Type.Client : Type.Job,
        billingId: res.id,
      });
      getInvoiceDetails();
      setPageData((prev) => {
        return {
          ...prev,
          selectedRecord: res,
          conversionHistory: history,
          isEditMode: !prev.isEditMode,
        };
      });
    } catch (err) {
      message.error("Failed to edit amount.");
      console.error(err);
    }
  };

  const toggleEdit = () => {
    setPageData((prev) => ({ ...prev, isEditMode: !prev.isEditMode }));
  };

  const showEditModal = () => {
    form.setFieldsValue({
      invoiceNumber: details?.invoiceNumber ?? "",
      status: details?.status ?? null,

      //! dueDate is used to save backend cost due date is not used any where i
      //! the backend only start date is used but at the time of creation of invoice backend set due date.
      startDate: details?.dueDate ? moment(details?.dueDate) : null,
    });

    setPageData((prev) => ({ ...prev, isModalVisible: true }));
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: async (event) => {
        const history = await getBillHistory({
          type: pageData.isClientBillingRow ? Type.Client : Type.Job,
          billingId: record.id,
        });
        setPageData((prev) => ({
          ...prev,
          selectedRecord: record,
          conversionHistory: history,
          isBillingModalVisible: true,
          isReadOnly: details?.invoiceNumber ? true : false,
        }));
      },
    };
  };
  const onFinish = async (values) => {
    const { startDate, status, invoiceNumber } = values;

    try {
      const res = await Invoice.edit({
        invoiceId: id,
        invoiceNumber: invoiceNumber,
        status,
        startDate: startDate.format(K.DateFormat.Response),
      });
      setPageData((prev) => ({
        ...prev,
        isModalVisible: false,
        details: { ...details, ...res },
      }));
    } catch (err) {
      setPageData((prev) => ({ ...prev, isModalVisible: false }));
      console.error(err);
    }
  };

  const deleteInvoice = async () => {
    try {
      await Invoice.delete({
        invoiceId: id,
        type: pageData.isClientBillingRow ? Type.Client : Type.Job,
      });
      history.goBack();
      message.success("Invoice deleted successfully");
    } catch (err) {
      setPageData((prev) => ({ ...prev, isModalVisible: false }));
      console.error(err);
    }
  };

  const getInvoiceDetails = async () => {
    try {
      const res = await Invoice.getInvoiceDetail(id);
      setPageData((prev) => ({
        ...prev,
        details: res,
        isClientBillingRow: res.type === Type.Client,
        overDueDays: calculateDaysDifference(res.dueDate),
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleInvoiceDetail = async (id) => {
    try {
      await Invoice.exportInvoiceDetail(id);
      message.success("Invoice detail exported");
    } catch (error) {
      console.log(error);
      message.success("something went wrong");
    }
  };

  useEffect(() => {
    getInvoiceDetails();
  }, [id]);

  const statusDisable = (selectedStatus) => {
    // return status are those that are disable
    if (details?.status === K.Invoice.Status.Draft) {
      return [K.Invoice.Status.Paid, K.Invoice.Status["Not Paid"]].includes(
        selectedStatus,
      );
    } else if (details?.status === K.Invoice.Status["Billed in QuickBooks"])
      return [K.Invoice.Status.Draft].includes(selectedStatus);

    return true;
  };

  return (
    <>
      <div className="pdf-wrap">
        <div className="pdf-header">
          <div className="pdf-heading">
            <Title level={5}>Source 2</Title>
          </div>
          <div className="pdf-header-img">
            <img src={PdfLogo} alt="" />
          </div>
        </div>
        <div className="report-list-wrap">
          <Paragraph className="report-para">
            941 W Morse Boulevard, Suite 100<br></br>Winter Park, FL 32789 US
            <br></br>
            accounting@source2.com<br></br> www.source2.com
          </Paragraph>
          <Title level={2}>INVOICE</Title>
          <Title level={5}>BILL TO</Title>
          <ul className="report-list-ul">
            <li>
              <b>Client ID:</b>
              <Text>{details?.client.id}</Text>
            </li>
            <li>
              <b>INVOICE #</b>
              <Text>{details?.invoiceNumber ?? K.NullPlaceholder}</Text>
            </li>
          </ul>
          <ul className="report-list-ul">
            <li>
              <b>Client Name:</b>
              <Text>{details?.client.name}</Text>
            </li>
            <li>
              <b>DATE</b>
              <Text>{convertIntoUSFormat(details?.startDate)}</Text>
            </li>
          </ul>

          <ul className="report-list-ul">
            <li>
              <b>Status:</b>
              <Text>
                <Tag color={tagColors[details?.status]}>
                  {removeUnderScore(details?.status)}
                </Tag>
              </Text>
            </li>
            <li>
              <b>DUE DATE</b>
              <Text>{convertIntoUSFormat(details?.dueDate)}</Text>
            </li>
          </ul>
        </div>
        <div className="header-divider"></div>
      </div>
      <div className={styles.wrapPageHeader}>
        <PageHeader
          className={LayoutCss.appPageHeader}
          ghost={false}
          onBack={() => window.history.back()}
          title="Invoice Details"
          extra={
            <div className={styles.invoiceModal}>
              <Button
                type="primary"
                onClick={showEditModal}
                className={styles.sendButton}
              >
                Edit
              </Button>
              {isPermissionPresent(
                K.Permissions.DeleteInvoice,
                userSlice.roles,
              ) && (
                <Popconfirm
                  placement="bottom"
                  title={"Are you sure you want to delete this invoice?"}
                  onConfirm={deleteInvoice}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{ marginLeft: 10 }}
                    type="primary"
                    danger={true}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </div>
          }
        />
      </div>
      <div className={styles.topInfoCard}>
        <Card className={styles.generateInvoiceCard}>
          <div className={styles.topCardDetail}>
            <div className={styles.cardBox}>
              <ul>
                <li>
                  <span className={styles.cardIdDate}> Invoice #: </span>
                  <span className={styles.cardIdDate}>
                    {details?.invoiceNumber ?? K.NullPlaceholder}
                  </span>
                </li>
                <li>
                  Invoice Date:
                  <span>
                    {convertIntoDashUSFormat(details?.dueDate, false)}
                    {/* {pageData.overDueDays < 0
                      ? ` (Overdue by ${-pageData.overDueDays} Days)`
                      : null} */}
                  </span>
                </li>
                <li>
                  Invoice Month:
                  <span>{getMonthName(details?.dueDate)}</span>
                </li>
                <li>
                  <span className={styles.cardIdDate}>Status:</span>
                  <span className={styles.cardIdDate}>
                    <Tag color={tagColors[details?.status]}>
                      {removeUnderScore(details?.status)}
                    </Tag>
                  </span>
                </li>
              </ul>
            </div>
            <div className={styles.cardRightSide}>
              <ul>
                <li className={`${styles.cardDetails} ${styles.clientBalance}`}>
                  Client:{" "}
                </li>
                <li> {details?.client.name}</li>
                <li className={styles.cardDetails}>Balance:</li>
                <li
                  className={`${styles.cardDetails} ${styles.invoicesDetails}`}
                >
                  {displayDollar(+details?.total.toFixed(2) ?? 0)}
                </li>
                <div className={styles.invoicesPrint}>
                  <Button
                    type="link"
                    className={styles.cardDetails + " hover-underlined"}
                    onClick={() => {
                      window.print();
                    }}
                  >
                    PDF
                  </Button>
                  {!pageData.isClientBillingRow && (
                    <Button
                      type="link"
                      className={styles.cardDetails + " hover-underlined"}
                      onClick={() => {
                        handleInvoiceDetail(id);
                      }}
                    >
                      Excel
                    </Button>
                  )}
                </div>
              </ul>
            </div>
          </div>
        </Card>
      </div>

      <Card className={styles.bottomInfoCard}>
        <div className={styles.bottomCardList}>
          <div className={styles.bottomCardIcons}>
            <ul>
              <li>
                <div className={styles.invoiceIcon}>
                  <i className="icon-invoice"></i>
                </div>
                <div className={styles.invoiceIconDetail}>
                  <p className={styles.listName}>Invoice Number</p>
                  <Title level={5} className={styles.listDes}>
                    {details?.invoiceNumber ?? K.NullPlaceholder}
                  </Title>
                </div>
              </li>
              <li>
                <div className={styles.invoiceIcon}>
                  <UserOutlined className={styles.cardIcon} />
                </div>
                <div className={styles.invoiceIconDetail}>
                  <p className={styles.listName}>Client</p>
                  <Title level={5} className={styles.listDes}>
                    {details?.client.name}
                  </Title>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={
            pageData.isClientBillingRow
              ? details?.submittedClientBills ?? []
              : details?.submittedJobBills ?? []
          }
          size="small"
          pagination={false}
          className="invoiceTable"
          scroll={{ x: true }}
          onRow={onRow}
        />
      </Card>
      <Modal
        className="s2-theme-style"
        centered
        title="Edit Invoice"
        open={pageData.isModalVisible}
        closeIcon={<i className="icon-closeable"></i>}
        onOk={form.submit}
        onCancel={() => {
          setPageData((prev) => ({ ...prev, isModalVisible: false }));
        }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.status !== curValues.status
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="invoiceNumber"
                label="Invoice Number"
                rules={
                  details?.invoiceNumber ||
                  getFieldValue("status") ===
                    K.Invoice.Status["Billed in QuickBooks"]
                    ? [
                        {
                          required: true,
                          message: "Invoice number is required",
                        },
                      ]
                    : []
                }
              >
                <Input placeholder="Invoice Number" />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            name="status"
            label="Invoice Status"
            shouldUpdate={true}
            initialValue={K.Invoice.Status.Draft}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              placeholder="Status"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {Object.keys(K.Invoice.Status).map((item, i) => (
                <Option
                  value={K.Invoice.Status[item]}
                  disabled={statusDisable(K.Invoice.Status[item])}
                >
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="startDate"
            label="Invoice Date"
            rules={[{ required: true, message: "Invoice Date is required" }]}
          >
            <DatePicker
              placeholder="Invoice Date"
              className={styles.datePicker}
              format={K.DateFormat.DashUSFormat}
              disabledDate={disabledDatesPriorMonth}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        centered
        width={572}
        footer={false}
        title={details?.client.name}
        className="clientBillingModal modal-overflow-auto"
        open={pageData.isBillingModalVisible}
        onOk={() =>
          setPageData((prev) => ({ ...prev, isBillingModalVisible: false }))
        }
        onCancel={() =>
          setPageData((prev) => ({ ...prev, isBillingModalVisible: false }))
        }
        closeIcon={
          <span className="closeIcon">
            <i className="icon-closeable"></i>
          </span>
        }
      >
        <BillingDetails
          pageStates={pageData}
          inputRef={inputRef}
          editAmount={editAmount}
          toggleEdit={toggleEdit}
        />
      </Modal>
    </>
  );
}
