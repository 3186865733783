import { ServerSideDataGrid } from "../../../../common/components/serverSideDataGrid/serverSideDataGrid";
import { EditConfigButton } from "./editConfigButton/editConfigButton";

export const getColumns = (clients, onConfigUpdated) => [
  {
    field: "id",
    headerName: "ID",
    lockVisible: true,
    maxWidth: 150,
  },
  {
    field: "clientName",
    headerName: "Client",
  },
  {
    field: "recruiterProductivityReportLink",
    headerName: "Recruiter Productivity Report Source",
    headerTooltip: "Recruiter Productivity Report Source",
    tooltipField: "recruiterProductivityReportLink",
  },
  {
    field: "weeklyReportLink",
    headerName: "Weekly Report Source",
    headerTooltip: "Weekly Report Source",
    tooltipField: "weeklyReportLink",
  },
  {
    field: "monthlyReportLink",
    headerName: "Monthly Report Source",
    headerTooltip: "Monthly Report Source",
    tooltipField: "monthlyReportLink",
  },
  {
    field: "dashboardReportLink",
    headerName: "Dashboard Report Source",
    headerTooltip: "Dashboard Report Source",
    tooltipField: "dashboardReportLink",
  },
  {
    field: "actions",
    headerName: "Actions",
    lockVisible: true,
    pinned: "right",
    suppressMovable: true,
    lockPinned: true,
    width: 150,
    cellRenderer: (params) => {
      return (
        <EditConfigButton
          configuration={params.data}
          clients={clients}
          onSuccess={onConfigUpdated}
        />
      );
    },
  },
];

export const DefaultColDef = {
  ...ServerSideDataGrid.DefaultColDef,
  sortable: false,
};
