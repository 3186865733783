import { useState } from "react";
import { Button, message } from "antd";
import { ClientTableauConfigs } from "../../../../../redux/models/clientTableauConfigs";
import { EditConfigModal } from "./editConfigModal";

export const EditConfigButton = ({ configuration, clients, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const availableClients = clients.map((c) => ({ label: c.name, value: c.id }));

  const onFinish = async (values) => {
    const { clientId, ...data } = values;

    try {
      setLoading(true);
      const res = await ClientTableauConfigs.update(clientId, data);

      onSuccess(res.data);
      setOpen(false);
      message.success("Reports configuration successfully updated");
    } catch (e) {
      message.error(e.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        type="link"
        style={{ color: "#1890ff" }}
        disabled={loading}
        onClick={() => setOpen(true)}
      >
        Edit
      </Button>
      {open && (
        <EditConfigModal
          open={open}
          onCancel={() => setOpen(false)}
          loading={loading}
          onFinish={onFinish}
          availableClients={availableClients}
          configuration={configuration}
        />
      )}
    </>
  );
};
