import { Col, Typography } from "antd";
const { Title, Paragraph } = Typography;
import styles from "assets/scss/jobAndClientBillingRules.module.scss";

export const CommissionDetailItem = ({ title, value }) => {
  return (
    <Col xs={24} sm={12} md={8}>
      <Title level={5} className={styles.cbrLabel}>
        {title}
      </Title>
      <Paragraph className={styles.cbrValue}>{value}</Paragraph>
    </Col>
  );
};
