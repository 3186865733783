import { AgGridReact } from "ag-grid-react";
import K from "../../../utilities/constants";
import {
  filterColumnListing,
  valueFormatter,
} from "../../../utilities/tableUtility";
import { forwardRef, useCallback, useMemo, useState } from "react";
import { mergeArrayOfObjects } from "../../../utilities/generalUtility";
import { debounce } from "lodash";
import { message } from "antd";

export const DefaultColDef = {
  ...K.AgGridTable.DefaultColDef,
  valueFormatter: valueFormatter(),
  sortable: true,
};

export const ServerSideDataGrid = forwardRef(
  (
    {
      getData,
      getColumnConfig,
      saveColumnConfig,
      defaultColumnConfig,
      onDataFetchSuccess,
      onDataFetchFailed,
      ...props
    },
    ref,
  ) => {
    const [columnDefs, setColumnDefs] = useState(null);

    const dataSource = useMemo(() => {
      return {
        getRows: async (params) => {
          const { startRow, endRow, sortModel } = params.request;

          const pageSize = endRow - startRow;
          const page = startRow / pageSize + 1;

          const body = {
            page,
            pageSize,
            sortBy: sortModel[0]?.colId,
            sortOrder: sortModel[0]?.sort,
          };

          try {
            const res = await getData(body);

            if (!res.total) {
              params.api.showNoRowsOverlay();
            }

            const data = { rowData: res.data, rowCount: res.total };
            params.success(data);
            onDataFetchSuccess?.(data);
          } catch (err) {
            params.fail();
            onDataFetchFailed?.(err);
          }
        },
      };
    }, [getData, onDataFetchSuccess, onDataFetchFailed]);

    const onGridReady = useCallback(async () => {
      try {
        const res = await getColumnConfig();

        if (res) {
          const config = JSON.parse(res.config);
          const data = mergeArrayOfObjects(
            config,
            defaultColumnConfig,
            (i) => i.field,
          );
          setColumnDefs(data);
        } else {
          setColumnDefs(defaultColumnConfig);
        }
      } catch (err) {
        console.error(err);
      }
    }, [getColumnConfig, setColumnDefs, defaultColumnConfig]);

    const updateColumns = useCallback(
      debounce(async ({ columnApi }) => {
        try {
          const data = filterColumnListing(
            defaultColumnConfig,
            columnApi.getColumnState(),
          );

          await saveColumnConfig(data);
          message.success("Table configuration saved successfully");
        } catch (err) {
          console.error(err);
        }
      }, 1000),
      [saveColumnConfig, defaultColumnConfig],
    );

    const getRowId = (params) => {
      return params.data.id;
    };

    return (
      <div
        className="ag-theme-alpine s2-theme-style"
        style={{
          height: 735,
        }}
      >
        <AgGridReact
          {...K.AgGridTable.DefaultProps}
          ref={ref}
          defaultColDef={DefaultColDef}
          columnDefs={columnDefs}
          serverSideDatasource={columnDefs ? dataSource : null}
          cacheBlockSize={25}
          onGridReady={onGridReady}
          rowModelType="serverSide"
          onColumnMoved={updateColumns}
          onColumnPinned={updateColumns}
          onColumnVisible={updateColumns}
          getRowId={getRowId}
          {...props}
        />
      </div>
    );
  },
);

ServerSideDataGrid.DefaultColDef = DefaultColDef;
