import { Form, Input, Select } from "antd";

export const ReportConfigForm = ({
  form,
  onFinish,
  clients,
  disableClientSelection = false,
  defaultValues = {},
  onDirtyChange,
}) => {
  const values = {
    clientId: defaultValues?.clientId ?? null,
    recruiterProductivityReportLink:
      defaultValues?.recruiterProductivityReportLink ?? "",
    weeklyReportLink: defaultValues?.weeklyReportLink ?? "",
    monthlyReportLink: defaultValues?.monthlyReportLink ?? "",
    dashboardReportLink: defaultValues?.dashboardReportLink ?? "",
  };

  const getUrlFieldRules = (label) => [
    { required: true, message: `${label} is required` },
    { type: "url", message: `${label} must be a valid url` },
  ];

  const onValuesChange = (v, newValues) => {
    onDirtyChange?.(JSON.stringify(values) !== JSON.stringify(newValues));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      initialValues={values}
    >
      <Form.Item
        required
        name={"clientId"}
        label={"Client"}
        rules={[{ required: true, message: "Client is required" }]}
      >
        <Select
          placeholder={"Select Client"}
          options={clients}
          disabled={disableClientSelection}
          showSearch
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        required
        name={"recruiterProductivityReportLink"}
        label={"Recruiter Productivity Report Source"}
        rules={getUrlFieldRules("Recruiter Productivity Report Source")}
      >
        <Input placeholder={"Enter Recruiter Productivity Report Source"} />
      </Form.Item>
      <Form.Item
        required
        name={"weeklyReportLink"}
        label={"Weekly Report Source"}
        rules={getUrlFieldRules("Weekly Report Source")}
      >
        <Input placeholder={"Enter Weekly Report Source"} />
      </Form.Item>
      <Form.Item
        required
        name={"monthlyReportLink"}
        label={"Monthly Report Source"}
        rules={getUrlFieldRules("Monthly Report Source")}
      >
        <Input placeholder={"Enter Monthly Report Source"} />
      </Form.Item>
      <Form.Item
        required
        name={"dashboardReportLink"}
        label={"Dashboard Report Source"}
        rules={getUrlFieldRules("Dashboard Report Source")}
      >
        <Input placeholder={"Enter Dashboard Report Source"} />
      </Form.Item>
    </Form>
  );
};
