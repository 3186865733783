import { useState, useEffect, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Card, message, PageHeader } from "antd";
import { Link, useHistory } from "react-router-dom";
import LayoutCss from "layout/layout.module.scss";
import CommissionPlan from "redux/models/commissionPlan";
import K from "utilities/constants";
import User from "redux/models/user";
import { filterColumnListing } from "utilities/tableUtility";
import { selectUser } from "redux/slices/userSlice";
import { useSelector } from "react-redux";
import {
  isPermissionPresent,
  mergeArrayOfObjects,
} from "utilities/generalUtility";
import { debounce } from "lodash";
import { Columns } from "./columns";

export default function CommissionPlans() {
  const history = useHistory();
  const userSlice = useSelector(selectUser);
  const userId = userSlice.details.id;
  const [commissionPlans, setCommissionPlans] = useState([]);

  const [columnDefs, setColumnDefs] = useState(null);

  const saveConfig = (params) =>
    User.saveColumnSort({
      ...params,
      tableName: K.AgGridTable.Keys.CommissionPlansVisibleColumn,
    });

  const getConfig = () =>
    User.getConfigrations(K.AgGridTable.Keys.CommissionPlansVisibleColumn);

  const updateColumns = useCallback(
    debounce(async ({ columnApi }) => {
      try {
        const data = filterColumnListing(Columns, columnApi.getColumnState());
        await saveConfig({ usersId: userId, configJson: JSON.stringify(data) });
        message.success("Table configuration saved successfully");
      } catch (err) {
        console.error(err);
      }
    }, 1000),
    [saveConfig, userId],
  );

  const onGridReady = useCallback(async () => {
    try {
      const res = await getConfig();

      if (res) {
        const config = JSON.parse(res.config);
        const data = mergeArrayOfObjects(config, Columns, (i) => i.field);
        setColumnDefs(data);
      } else {
        setColumnDefs(Columns);
      }
    } catch (err) {
      console.error(err);
    }
  }, [getConfig, setColumnDefs]);

  useEffect(() => {
    CommissionPlan.getAll()
      .then((res) => setCommissionPlans(res))
      .catch((err) => console.error(err));
  }, []);

  const rowClickedHandler = (event) => {
    history.push(
      `/configurations/commission-plans/detail/${event.data.commissionId}`,
    );
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title="Commission Plans"
        extra={
          isPermissionPresent(
            K.Permissions.CreateCommissionPlan,
            userSlice.roles,
          ) && (
            <Link to="/configurations/commission-plans/create">
              <Button type="primary" className={LayoutCss.appHeaderBtn}>
                Create
              </Button>
            </Link>
          )
        }
        className={`${LayoutCss.appPageHeader} ${LayoutCss.pageHeader}`}
      />

      <Card
        className={LayoutCss.appListingCardCommissionPlansTAble}
        bordered={false}
        style={{ marginTop: "20px" }}
      >
        <div className="ag-theme-alpine s2-theme-style" style={{ height: 735 }}>
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            rowData={commissionPlans}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            defaultColDef={K.AgGridTable.DefaultColDef}
            onRowClicked={rowClickedHandler}
            onColumnMoved={updateColumns}
            onColumnVisible={updateColumns}
            onColumnPinned={updateColumns}
          />
        </div>
      </Card>
    </>
  );
}
