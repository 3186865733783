import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Form,
  List,
  Row,
  Select,
  Typography,
  message,
  Space,
} from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";

import LayoutCss from "layout/layout.module.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import K from "utilities/constants";
import { disabledDate, epochTimeToDashUSFormat } from "utilities/dateUtility";

import VBDHistoryRule from "./vbdHistoryRule";

import VBDComponent from "./vbdComponent";

import BillingDiscount from "redux/models/billingDiscount";
import VBDUpcomingRule from "./vbdUpcomingRule";
import { isPermissionPresent } from "utilities/generalUtility";
import { selectUser } from "redux/slices/userSlice";

const { Title } = Typography;

export default function VBDRuleDetail({ form, tierListing }) {
  const { id } = useParams();

  const [billingRule, setBillingRule] = useState("history");
  const userSlice = useSelector(selectUser);
  const [pageStates, setPageStates] = useState({
    render: false,
    tierData: [],
    selectedTiers: [],
    initialSelectedTiers: [],
    initialSelectedCandidateHireTypes: [],
    selectedCandidateHireTypes: [],
    isLoading: false,
    currentRule: null,
    isModalVisible: false,
    isUnlimited: false,
    isAddNewDisable: false,
    history: [],
    upcoming: [],
    isEdit: true,
  });

  const onFinish = async (values) => {
    try {
      const thresholdData = values.thresholdForm?.map((item, index) => {
        const tiers = Object.keys(item.tiers).reduce((prev, curr) => {
          return [...prev, { id: curr, value: item.tiers[curr] }];
        }, []);
        const infinite =
          values.thresholdForm?.length - 1 === index && pageStates.isUnlimited;
        return {
          ...item,
          max: isNaN(item.max) ? null : item.max,
          tiers: tiers,
          hasInfiniteEndValue: infinite ? 1 : 0,
        };
      });

      const createdData = {
        clientId: id,
        thresholds: thresholdData ?? [],
        tiers: pageStates.selectedTiers.map((item) => item.id),
        candidateHireTypes: pageStates.selectedCandidateHireTypes,
        effectiveSince: values.effectiveSince.unix(),
      };

      await BillingDiscount.createVBD(createdData);
      setPageStates((prev) => {
        return { ...prev, render: !prev.render, isEdit: true };
      });
      form.resetFields();
    } catch (error) {
      console.log(error);
    }
  };

  const getVBDRules = async () => {
    try {
      const res = await BillingDiscount.getVBDRule(id);
      setPageStates((prev) => {
        return {
          ...prev,
          history: res.history,
          upcoming: res.upComing,
          isUnlimited: false,
          isAddNewDisable: false,
        };
      });

      const selectedTier = getSelectedTiers(res.current?.tierIds ?? "");
      const selectedHireTypes = getSelectedCandidateTypes(
        res.current?.hireTypes ?? "",
      );
      setPageStates((prev) => {
        return {
          ...prev,
          currentRule: res.current,
          selectedTiers: selectedTier,
          initialSelectedTiers: selectedTier,
          initialSelectedCandidateHireTypes: selectedHireTypes,
          selectedCandidateHireTypes: selectedHireTypes,
        };
      });

      populateFormValues(res.current);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVBDRules();
  }, [pageStates.render]);

  const handleChange = (value) => {
    setBillingRule(value);
  };
  const getSelectedTiers = (tierIds) => {
    const selectedTierIds = tierIds.split(",");
    return tierListing?.filter((tier) =>
      selectedTierIds.includes(`${tier.id}`),
    );
  };
  const getSelectedCandidateTypes = (hireTypes) => {
    return hireTypes.split(",");
  };

  const VBDBillingRules = {
    upcoming: (
      <VBDUpcomingRule
        upcomingRules={pageStates.upcoming}
        getSelectedTiers={getSelectedTiers}
        tierListing={tierListing}
        getSelectedCandidateTypes={getSelectedCandidateTypes}
      />
    ),
    history: (
      <VBDHistoryRule
        historyRules={pageStates.history}
        getSelectedTiers={getSelectedTiers}
        tierListing={tierListing}
        getSelectedCandidateTypes={getSelectedCandidateTypes}
      />
    ),
  };

  const thresholdValues = () => {
    const thresholdForm = form.getFieldValue("thresholdForm");

    if (thresholdForm.length > 1) {
      const updated = thresholdForm.map((item, index) => {
        if (thresholdForm.length - 1 === index) {
          const preMaxValue = [...thresholdForm][index - 1]["max"];
          return { ...item, min: parseInt(preMaxValue) + 1 };
        }
        return item;
      });
      form.setFieldsValue({
        thresholdForm: updated,
      });
    }
  };

  //Threshold min max value validation
  const maxthresholdValidation = (_, value, callback, index) => {
    const minValue = form.getFieldValue(["thresholdForm", index, "min"]);

    if (value == null) {
      message.error("Max Value is Required");
      setPageStates((prev) => {
        return { ...prev, isAddNewDisable: true };
      });
      return Promise.reject();
    }

    if (value && minValue && parseInt(value) <= parseInt(minValue)) {
      message.error("Value Must be Greater Than " + minValue);
      setPageStates((prev) => {
        return { ...prev, isAddNewDisable: true };
      });
      return Promise.reject();
    } else {
      // callback();
      setPageStates((prev) => {
        return { ...prev, isAddNewDisable: false };
      });
      return Promise.resolve();
    }
  };
  const minthresholdValidation = (_, value, callback, index) => {
    const maxValue = form.getFieldValue(["thresholdForm", index, "max"]);

    if (value && maxValue && parseInt(value) >= parseInt(maxValue)) {
      message.error(`value must be less than the  ${maxValue}`);
      setPageStates((prev) => {
        return { ...prev, isAddNewDisable: true };
      });
      return Promise.reject();
    } else {
      setPageStates((prev) => {
        return { ...prev, isAddNewDisable: false };
      });
      return Promise.resolve();
    }
  };

  //Threshold min max value validation
  const isInfiniteValue = (e) => {
    const thresholdForm = form.getFieldValue("thresholdForm");
    setPageStates((prev) => {
      return {
        ...prev,
        isAddNewDisable: prev.isAddNewDisable || e.target.checked,
        isUnlimited: e.target.checked,
      };
    });

    if (thresholdForm.length >= 1) {
      const updated = thresholdForm.map((item, index) => {
        if (thresholdForm.length - 1 === index) {
          const currMinValue = [...thresholdForm][index]["min"];
          return e.target.checked
            ? { ...item, max: `∞` }
            : { ...item, max: null };
        }
        return item;
      });

      form.setFieldsValue({
        thresholdForm: updated,
      });
    }
  };
  const thresholdValueDisabled = (index) => {
    const thresholdForm = form.getFieldValue("thresholdForm");
    //*checking is this last index
    if (thresholdForm.length - 1 === index) {
      return false;
    }
    return true;
  };
  const handleTierSetting = (value) => {
    const filterTierListing = tierListing.filter((item) =>
      value.includes(item.id),
    );
    if (filterTierListing?.length === 0) {
      form.resetFields();
      form.setFieldsValue({ thresholdForm: [] });
      setPageStates((prev) => {
        return {
          ...prev,
          isUnlimited: false,
          isAddNewDisable: false,
        };
      });
    }
    setPageStates((prev) => {
      return {
        ...prev,
        selectedTiers: filterTierListing,
      };
    });
  };

  const handleCandidateHireTypeSetting = (value) => {
    setPageStates((prev) => {
      return {
        ...prev,
        selectedCandidateHireTypes: value,
      };
    });
  };

  const populateFormValues = (currentRule) => {
    console.log("Current Rule", currentRule);

    const formEntries = currentRule?.vbdThresholds?.reduce((prev, curr) => {
      const tiers = curr.vbdTierEntries.reduce((prev, curr) => {
        return { ...prev, [curr.tierId]: curr.percentageVal };
      }, {});

      return [
        ...prev,
        {
          min: curr.min,
          max: curr.max === null ? `∞` : curr.max,
          tiers: tiers,
        },
      ];
    }, []);

    const isUnlimitedCheck = currentRule?.vbdThresholds?.some(
      (th) => th.max === null,
    );
    console.log(
      "currentRule?.vbdThresholds",
      currentRule?.vbdThresholds,
      formEntries,
    );

    setPageStates((prev) => {
      return {
        ...prev,
        isUnlimited: isUnlimitedCheck,
        isAddNewDisable: formEntries ?? [].length > 0,
      };
    });

    form.setFieldsValue({ thresholdForm: formEntries });
  };

  const onCancel = () => {
    form.resetFields();

    setPageStates((prev) => {
      return {
        ...prev,
        selectedTiers: prev.initialSelectedTiers,
        selectedCandidateHireTypes: prev.initialSelectedCandidateHireTypes,
        isEdit: true,
      };
    });
    populateFormValues(pageStates.currentRule);
  };

  return (
    <>
      {isPermissionPresent(K.Permissions.EditClientVBD, userSlice.roles) && (
        <div>
          <div className="text-right mb-4">
            {pageStates.isEdit ? (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    setPageStates((prev) => {
                      return {
                        ...prev,
                        isEdit: false,
                        isAddNewDisable: prev.isUnlimited,
                      };
                    });
                    form.setFieldsValue({ effectiveSince: null });
                  }}
                >
                  Edit
                </Button>
                <Title className="date-updated" level={5}>
                  {`Last updated at ${epochTimeToDashUSFormat(
                    pageStates.currentRule?.effectiveSince,
                  )}`}
                </Title>
              </>
            ) : (
              <>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      form.submit();
                    }}
                    style={{ color: "white" }}
                  >
                    Save & Update
                  </Button>
                  <Button onClick={() => onCancel()}>Cancel</Button>
                </Space>
              </>
            )}
          </div>
        </div>
      )}
      <Form form={form} onFinish={onFinish}>
        <div className={styles.currentBillingRule}>
          {!pageStates.isEdit && (
            <div>
              <Form.Item
                className="effective-date"
                label="Effective Date"
                name="effectiveSince"
                rules={[
                  { required: true, message: "Please select effective date!" },
                ]}
              >
                <DatePicker
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  className="effective-date-picker"
                  disabledDate={disabledDate}
                  format={K.DateFormat.DashUSFormat}
                  size="large"
                />
              </Form.Item>
            </div>
          )}
        </div>
        <Card
          title="Settings"
          bodyStyle={{ paddingBottom: 0 }}
          className={"appCard mb-3 " + LayoutCss.appCard}
        >
          <Row>
            <Col md={12} className="tier-wrap">
              <Title level={5} className="mb-4">
                Tier Listings
              </Title>

              <Checkbox.Group
                className="w-100"
                disabled={pageStates.isEdit}
                value={pageStates.selectedTiers?.map(({ id }) => id)}
                onChange={handleTierSetting}
              >
                <Row gutter={[0, 20]}>
                  {tierListing?.map((item, index) => (
                    <Col xs={24} sm={12} md={8} key={index}>
                      <Checkbox checked id={item.id} value={item.id}>
                        {item.name}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Col>
            <Col className="tier-wrap" md={12}>
              <Title level={5} className="mb-4">
                Candidate Type
              </Title>
              <Checkbox.Group
                disabled={pageStates.isEdit}
                value={pageStates.selectedCandidateHireTypes}
                onChange={handleCandidateHireTypeSetting}
              >
                <Row gutter={[0, 20]}>
                  {K.hireType.map((type, index) => (
                    <Col xs={24} sm={12} md={8} key={index}>
                      <Checkbox id={type.value} value={type.value}>
                        {type.label === "Rehire"
                          ? `${type.label} (all 3 types)`
                          : type.label}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Col>
          </Row>
        </Card>
        <Card
          className={
            styles.clientBillingRulesCard + "appCard mb-3 " + LayoutCss.appCard
          }
          title="Discounts"
        >
          {pageStates.selectedTiers?.length > 0 ? (
            <div className="threshold-wrap">
              <List
                className="teir-list"
                dataSource={pageStates?.selectedTiers ?? []}
                renderItem={(item) => (
                  <List.Item key={item?.name}>{item?.name}</List.Item>
                )}
              />

              <Form.List name="thresholdForm" initialValue={[]}>
                {(fields, { add, remove }) => (
                  <>
                    <div className="threshold-card-wrap">
                      {fields.map((field, index) => (
                        <VBDComponent
                          form={form}
                          index={index}
                          key={index}
                          field={field}
                          remove={remove}
                          fields={fields}
                          minthresholdValidation={minthresholdValidation}
                          maxthresholdValidation={maxthresholdValidation}
                          isInfiniteValue={isInfiniteValue}
                          pageStates={pageStates}
                          setPageStates={setPageStates}
                          thresholdValueDisabled={thresholdValueDisabled}
                          isEdit={pageStates.isEdit}
                        />
                      ))}
                      <Button
                        className="new-threshold"
                        disabled={pageStates.isAddNewDisable}
                        icon={<i className="icon-plus"></i>}
                        type="dashed"
                        onClick={() => {
                          add();
                          thresholdValues();
                          setPageStates((prev) => {
                            return { ...prev, isAddNewDisable: true };
                          });
                        }}
                      >
                        New Threshold
                      </Button>
                    </div>
                  </>
                )}
              </Form.List>
            </div>
          ) : (
            <Empty />
          )}
        </Card>
      </Form>
      <div className={LayoutCss.invoicesButton}>
        <Select
          showSearch={false}
          defaultValue="history"
          onChange={handleChange}
          className={LayoutCss.invoicesButtonSet}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          options={[
            { key: 1, value: "history", label: "History" },
            { key: 2, value: "upcoming", label: "Upcoming" },
          ]}
        />
      </div>
      <Card
        bodyStyle={{ paddingTop: 0 }}
        className={styles.collapseCard + " mt-4"}
        title={billingRule === "history" ? "History" : "Upcoming"}
      >
        {VBDBillingRules[billingRule]}
      </Card>
    </>
  );
}
