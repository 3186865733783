import { Collapse as AntCollapse } from "antd";
import styles from "./collapse.module.scss";

export const Collapse = ({ onChange, children }) => {
  return (
    <AntCollapse
      expandIconPosition={"end"}
      className={styles.collapse}
      onChange={onChange}
    >
      {children}
    </AntCollapse>
  );
};

Collapse.Panel = AntCollapse.Panel;
