import { Card, Col, Input, message, Row, Space } from "antd";
import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedClientIds } from "../../../redux/slices/globalSelectorSlice";
import K from "../../../utilities/constants";
import { Columns } from "./columns";
import { selectUserId, selectUserRoles } from "../../../redux/slices/userSlice";
import { isPermissionPresent } from "../../../utilities/generalUtility";
import { debounce } from "lodash";
import { ExportButton } from "./exportButton";
import { useHistory } from "react-router-dom";
import { RecruitersSelect } from "./recruitersSelect";

export const EngagementsContainer = ({
  getData,
  getConfig,
  saveConfig,
  onExport,
  renderTable,
}) => {
  const history = useHistory();

  const clients = useSelector(selectSelectedClientIds);
  const userId = useSelector(selectUserId);
  const roles = useSelector(selectUserRoles);

  const gridRef = useRef(null);

  const [totalCount, setTotalCount] = useState(null);
  const [search, setSearch] = useState(null);
  const [recruiters, setRecruiters] = useState([]);

  const isExportLimitExceeded = totalCount > K.Limits.Engagement.export;
  const isExportDisabled = totalCount === 0 || isExportLimitExceeded;

  const hasAccessToCandidateProfile = isPermissionPresent(
    K.Permissions.ViewCandidateJobs,
    roles,
  );

  const hasAccessToRecruiters = isPermissionPresent(
    K.Permissions.ManageEngagementsForAssignedClients,
    roles,
  );

  const onExportRequested = async () => {
    try {
      const columnApi = gridRef.current.columnApi;
      const sortColumn = columnApi.getColumnState().find((c) => c.sort);

      const params = {
        client: clients,
        sortBy: sortColumn?.colId,
        sortOrder: sortColumn?.sort,
        search: search ?? undefined,
        recruiter: recruiters,
      };

      await onExport(params);
      message.success(
        "Engagement export request successfully received! Check your email shortly",
      );
    } catch (err) {
      console.error(err);
    }
  };

  const onSearchChange = useCallback(
    debounce((e) => {
      setSearch(e.target.value || null);
    }, 500),
    [setSearch],
  );

  const onRowClicked = useCallback(
    ({ data }) => {
      if (!hasAccessToCandidateProfile) {
        return;
      }

      const { candidateId, candidateJobEntryId } = data;
      const params = {
        tab: "jobs",
        candidateJobEntryId,
        jobTab: "engagements",
      };

      history.push(`/candidates/detail/${candidateId}`, params);
    },
    [hasAccessToCandidateProfile, history],
  );

  const getServerSideData = useCallback(
    (options) => {
      return getData({
        ...options,
        client: clients,
        search: search ?? undefined,
        recruiter: recruiters,
      });
    },
    [getData, clients, search, recruiters],
  );

  const saveColumnConfig = useCallback(
    (config) => {
      return saveConfig({
        usersId: userId,
        configJson: JSON.stringify(config),
      });
    },
    [saveConfig, userId],
  );

  const onDataFetchSuccess = useCallback(
    (data) => {
      setTotalCount(data.rowCount);
    },
    [setTotalCount],
  );

  return (
    <Card bodyStyle={{ padding: 16 }}>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row justify={"space-between"} gutter={[8, 8]}>
          <Col style={{ flex: 1 }}>
            <Row gutter={[8, 8]}>
              <Col style={{ width: "100%", maxWidth: 264, minWidth: 200 }}>
                <Input.Search
                  allowClear
                  placeholder="Search"
                  onChange={onSearchChange}
                />
              </Col>
              {hasAccessToRecruiters && (
                <Col style={{ width: "100%", maxWidth: 200, minWidth: 150 }}>
                  <RecruitersSelect
                    onChange={setRecruiters}
                    clients={clients}
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col>
            <ExportButton
              disabled={isExportDisabled}
              onClick={onExportRequested}
              isExportLimitExceeded={isExportLimitExceeded}
            />
          </Col>
        </Row>

        {renderTable({
          ref: gridRef,
          getData: getServerSideData,
          getColumnConfig: getConfig,
          saveColumnConfig: saveColumnConfig,
          defaultColumnConfig: Columns,
          onDataFetchSuccess: onDataFetchSuccess,
          onRowClicked: onRowClicked,
          rowStyle: {
            cursor: hasAccessToCandidateProfile ? "pointer" : "default",
          },
        })}
      </Space>
    </Card>
  );
};
