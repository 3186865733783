import {
  Tooltip,
  Row,
  Col,
  Card,
  Descriptions,
  Typography,
  Divider,
} from "antd";
import K from "utilities/constants";
import { titleCase } from "utilities/generalUtility";
import { displayDollar, getCalendarDay } from "utilities/tableUtility";

const { Title } = Typography;

export default function ClientAndJobBillingComponent({ currentRule }) {
  const getFormattedAmount = (item, value, isTitle) => {
    const amountType = isTitle ? (
      <sup>{displayAmountType(item)}</sup>
    ) : (
      displayAmountType(item)
    );
    const displayedValue = displayDollar(value, false);

    return (
      <>
        {item.amountType === K.Format.Percentage ? displayedValue : amountType}
        {item.amountType === K.Format.Percentage ? amountType : displayedValue}
      </>
    );
  };

  const displayAmountType = (item) => {
    if (item.amountType === K.Format.Percentage) {
      return "%";
    } else if (item.currency === "CAD") {
      return K.CurrencyType.CAD;
    } else {
      return K.CurrencyType.Dollar;
    }
  };
  const getWeekBillingRule = (item) => {
    return (
      <Card className="feeCard">
        <Descriptions
          className="feeCardDescription"
          title={getFormattedAmount(item, item.amount, true)}
          extra={item.currency}
        ></Descriptions>
        <Divider />
        <Descriptions
          column={{ xs: 1, sm: 1, md: 1 }}
          colon={false}
          className="feeCardDescription jobFeeDescription"
        >
          <Descriptions.Item label="Referral Fee">
            {getFormattedAmount(item, item.referral)}
          </Descriptions.Item>
          <Descriptions.Item label="Rehire Fee">
            {getFormattedAmount(item, item.reHired)}
          </Descriptions.Item>
          <Descriptions.Item label="Internal Fee">
            {getFormattedAmount(item, item.internal)}
          </Descriptions.Item>
          <Descriptions.Item label="Repeat Every">
            {item.calendarEntry.repetitiveQuantity}
          </Descriptions.Item>
          <Descriptions.Item label="Repeat Type">
            {titleCase(item.calendarEntry.repeatType)}
          </Descriptions.Item>

          <Descriptions.Item className="daysLabel" label="Days">
            {getCalendarDay(item.calendarEntry).length
              ? getCalendarDay(item.calendarEntry)
              : K.NullPlaceholder}
          </Descriptions.Item>
          <Descriptions.Item label="Occurrences">
            {item.calendarEntry.endAfterOccurances !== null
              ? item.calendarEntry.isInfinite === 1
                ? "Ongoing"
                : item.calendarEntry.endAfterOccurances
              : K.NullPlaceholder}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    );
  };

  const getMonthBillingRule = (item) => {
    return (
      <Card className="feeCard">
        <Descriptions
          className="feeCardDescription"
          title={getFormattedAmount(item, item.amount, true)}
          extra={item.currency}
        ></Descriptions>
        <Divider />
        <Descriptions
          column={{ xs: 1, sm: 1, md: 1 }}
          colon={false}
          className="feeCardDescription jobFeeDescription"
        >
          <Descriptions.Item label="Referral Fee">
            {getFormattedAmount(item, item.referral)}
          </Descriptions.Item>
          <Descriptions.Item label="Rehire Fee">
            {getFormattedAmount(item, item.reHired)}
          </Descriptions.Item>
          <Descriptions.Item label="Internal Fee">
            {getFormattedAmount(item, item.internal)}
          </Descriptions.Item>
          <Descriptions.Item label="Repeat Every">
            {item.calendarEntry.repetitiveQuantity}
          </Descriptions.Item>
          <Descriptions.Item label="Repeat Type">
            {titleCase(item.calendarEntry.repeatType)}
          </Descriptions.Item>

          <Descriptions.Item label="On this date">
            {item.calendarEntry.repetitiveMonthDay ?? K.NullPlaceholder}
          </Descriptions.Item>
          <Descriptions.Item label="Occurrences">
            {item.calendarEntry.endAfterOccurances !== null
              ? item.calendarEntry.isInfinite === 1
                ? "Ongoing"
                : item.calendarEntry.endAfterOccurances
              : K.NullPlaceholder}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    );
  };

  const getDataSource = (item) => {
    if (item.calendarEntry && item.calendarEntry.repeatType === "week") {
      return getWeekBillingRule(item);
    } else if (
      item.calendarEntry &&
      item.calendarEntry.repeatType === "month"
    ) {
      return getMonthBillingRule(item);
    } else if (item.jobBillingType.type === "TIME_BASED") {
      return (
        <Card className="feeCard">
          <Descriptions
            className="feeCardDescription"
            title={getFormattedAmount(item, item.amount, true)}
            extra={item.currency}
          ></Descriptions>
          <Divider />
          <Descriptions
            column={{ xs: 1, sm: 1, md: 1 }}
            colon={false}
            className="feeCardDescription jobFeeDescription"
          >
            <Descriptions.Item label="Referral Fee">
              {getFormattedAmount(item, item.referral)}
            </Descriptions.Item>
            <Descriptions.Item label="Rehire Fee">
              {getFormattedAmount(item, item.reHired)}
            </Descriptions.Item>
            <Descriptions.Item label="Internal Fee">
              {getFormattedAmount(item, item.internal)}
            </Descriptions.Item>
            <Descriptions.Item label="Days">
              <>{item.days ?? "N/A"}</>
            </Descriptions.Item>
          </Descriptions>
        </Card>
      );
    } else {
      return (
        <Card className="feeCard">
          <Descriptions
            className="feeCardDescription"
            title={getFormattedAmount(item, item.amount, true)}
            extra={item.currency}
          ></Descriptions>
          <Divider />
          <Descriptions
            column={{ xs: 1, sm: 1, md: 1 }}
            colon={false}
            className="feeCardDescription jobFeeDescription"
          >
            <Descriptions.Item label="Referral Fee">
              {getFormattedAmount(item, item.referral)}
            </Descriptions.Item>
            <Descriptions.Item label="Rehire Fee">
              {getFormattedAmount(item, item.reHired)}
            </Descriptions.Item>
            <Descriptions.Item label="Internal Fee">
              {getFormattedAmount(item, item.internal)}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      );
    }
  };
  return (
    <>
      <Row className="job-billing-wrap" gutter={[0, 30]}>
        {currentRule?.billingRuleEntries.map((item, i) =>
          !item.retentionEntries.length ? (
            <Col key={item.id} md={12} lg={12} xl={8}>
              <Card
                className="job-billing-card"
                title={
                  <div className="d-flex justify-content-center">
                    {item.isCommissionable ? (
                      <Tooltip title="Is Commissionable">
                        &nbsp;
                        <i
                          className="icon-commission1"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </Tooltip>
                    ) : null}
                    <Tooltip title={item.jobBillingType.name}>
                      <span className="title-ellipse">
                        {item.jobBillingType.name}
                      </span>
                    </Tooltip>
                  </div>
                }
              >
                {getDataSource(item)}
              </Card>
            </Col>
          ) : null,
        )}
      </Row>

      {currentRule?.billingRuleEntries.map((item, i) =>
        item.retentionEntries.length ? (
          <Card
            key={item.id}
            className="job-billing-card retention-card"
            title={
              <>
                {item.isCommissionable ? (
                  <Tooltip title="Is Commissionable">
                    &nbsp;
                    <i
                      className="icon-commission1"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </Tooltip>
                ) : null}
                <Tooltip title={item.jobBillingType.name}>
                  {item.jobBillingType.name}
                </Tooltip>
              </>
            }
          >
            <Row gutter={[30, 30]}>
              {item.retentionEntries.map((entry, j) => (
                <Col md={12} lg={8} key={entry.id}>
                  <Card className="feeCard">
                    <Title level={5}>
                      {item.retentionType === K.RecurringType.Day
                        ? `${entry.monthOrder} Days`
                        : `Month ${entry.monthOrder}`}
                    </Title>
                    <Descriptions
                      className="feeCardDescription"
                      title={getFormattedAmount(item, entry.amount, true)}
                      extra={item.currency}
                    ></Descriptions>
                    <Divider />
                    <Descriptions
                      column={{ xs: 1, sm: 1, md: 1 }}
                      colon={false}
                      className="feeCardDescription jobFeeDescription"
                    >
                      <Descriptions.Item label="Referral Fee">
                        {getFormattedAmount(item, entry.referral)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Rehire Fee">
                        {getFormattedAmount(item, entry.reHired)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Internal Fee">
                        {getFormattedAmount(item, entry.internal)}
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        ) : null,
      )}
    </>
  );
}
