import { PageHeader } from "antd";
import { TableauReportContainer } from "./TableauReportsContainer/tableauReportContainer";
import K from "utilities/constants";
import LayoutCss from "../../layout/layout.module.scss";

export const RecruiterPerformanceReporting = () => {
  return (
    <>
      <PageHeader
        ghost={false}
        title="Recruiter Performance"
        className={LayoutCss.appPageHeader}
      />
      <TableauReportContainer
        type={K.Clients.TableauReports.Type.RecruiterProductivity}
      />
    </>
  );
};
