import { createSelector, createSlice } from "@reduxjs/toolkit";
import K from "utilities/constants";
import { uniq } from "lodash";

const { Redux } = K;

const initialState = {
  [Redux.SelectedClients]: [],
  [Redux.SelectedRecruiters]: [],
  [Redux.ClientSelection]: "all",
  [Redux.DashboardTab]: "historicalPerformance",
  [Redux.DashboardStatusModalVisible]: false,
};

export const globalSelectorSlice = createSlice({
  name: "globalSelector",
  initialState,
  reducers: {
    setGlobalClients: (state, action) => {
      state[Redux.SelectedClients] = action.payload;
    },
    setClientSelectionType: (state, action) => {
      state[Redux.ClientSelection] = action.payload;
    },
    setDashboardActiveTab: (state, action) => {
      state[Redux.DashboardTab] = action.payload;
    },
    setDashboardStatusModal: (state, action) => {
      state[Redux.DashboardStatusModalVisible] = action.payload;
    },
    setGlobalRecruiters: (state, action) => {
      state[Redux.SelectedRecruiters] = action.payload;
    },
    setInitialSelector: (state, action) => {
      const { data, isSuperAdmin } = action.payload;
      if (isSuperAdmin) {
        state[Redux.SelectedClients] = [];
        state[Redux.SelectedRecruiters] = [];
      } else {
        const recruiters = [];
        if (data.basicDetail.isManager) {
          state[Redux.SelectedClients] = data.basicDetail.clients.map(
            ({ id, users }) => {
              recruiters.push(...users);
              return id;
            },
          );
        } else {
          state[Redux.SelectedClients] = data.basicDetail.clients.map(
            ({ id }) => {
              recruiters.push({
                id: data.basicDetail.id,
                name: data.basicDetail.name,
              });
              return id;
            },
          );
        }
        state[Redux.SelectedRecruiters] = uniq(recruiters.map(({ id }) => id));
        state[Redux.ClientSelection] = K.Clients.SelectionType.None;
      }
    },
  },
});

export const selectGlobalSelector = (state) => state.globalSelector;

export const selectClientSelection = createSelector(
  selectGlobalSelector,
  (globalSelector) => globalSelector.clientSelection,
);

export const selectSelectedClientIds = createSelector(
  selectGlobalSelector,
  (globalSelector) => globalSelector.selectedClients,
);

export const {
  setGlobalClients,
  setInitialSelector,
  setGlobalRecruiters,
  setClientSelectionType,
  setDashboardActiveTab,
} = globalSelectorSlice.actions;
export default globalSelectorSlice.reducer;
