import { Button, Card, Collapse, Popconfirm } from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";
import LayoutCss from "layout/layout.module.scss";
import { epochTimeToDashUSFormat } from "utilities/dateUtility";
import CommissionPlan from "redux/models/commissionPlan";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder.jsx";
import { CommissionPlanDetails } from "./CommissionPlanDetails";

const { Panel } = Collapse;

export default function UpcomingCommissionPlans({
  plansDetails,
  setPlansDetails,
}) {
  const confirm = async (commissionId) => {
    try {
      const data = await CommissionPlan.deteteUpcommingPlan(commissionId);
      if (data.message)
        setPlansDetails((prevPlans) => {
          const upcomingRules = prevPlans.upcomingRules.filter(
            (item) =>
              item.defaultPlan.plan.commissionTimelineId !== commissionId,
          );
          return { ...prevPlans, upcomingRules: upcomingRules };
        });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {plansDetails.upcomingRules.length === 0 ? (
        <Card
          title="Upcoming Rules"
          bordered={false}
          className={LayoutCss.appListingCard}
          style={{ marginTop: "20px" }}
        >
          <EmptyPlaceholder />
        </Card>
      ) : (
        <Card
          title="Upcoming Rules"
          bordered={false}
          className={LayoutCss.appListingCard}
          style={{ marginTop: "20px" }}
        >
          {plansDetails.upcomingRules.map((item, index) => (
            <Collapse
              key={index}
              defaultActiveKey={["0"]}
              expandIconPosition="end"
              bordered={false}
            >
              <Panel
                header={epochTimeToDashUSFormat(item.defaultPlan.startDate)}
                key="1"
                extra={
                  <Popconfirm
                    title="Are you sure to delete this record?"
                    onConfirm={() => {
                      confirm(item.defaultPlan.plan.commissionTimelineId);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="link"
                      icon={
                        <i className={"icon-delet " + styles.deletIcon}></i>
                      }
                    />
                  </Popconfirm>
                }
              >
                <Card className={styles.clientBillingRulesCard}>
                  <CommissionPlanDetails plan={item.defaultPlan.plan} />
                </Card>
              </Panel>
            </Collapse>
          ))}
        </Card>
      )}
    </>
  );
}
