import {
  Card,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Radio,
} from "antd";
import LayoutCss from "layout/layout.module.scss";
import styles from "./commissionPlans.module.scss";
import K from "utilities/constants";
import { disabledDate } from "utilities/dateUtility";
import { noTrailingSpaceAllowedRule } from "utilities/generalUtility";
import { CommissionPlanRule } from "./commissionPlanRule";

export default function CreateCommissionPlanComponent({ form, planId }) {
  return (
    <Card
      title={planId ? "Add New Rule" : "Create Rule"}
      className={`${styles.cardWrap} ${LayoutCss.appCard}`}
    >
      <Form layout="vertical" className={styles.commissionForm} form={form}>
        <Row gutter={[16, 0]}>
          <Col sm={12} xs={24}>
            <Form.Item
              label="Commission Threshold Type"
              name="thresholdType"
              initialValue={"AMOUNT"}
              rules={[
                { required: true, message: "Threshold Type is required." },
              ]}
            >
              <Radio.Group>
                <Radio value={"AMOUNT"}>Billing Amount</Radio>
                <Radio value={"TOTAL_HIRE"}>Total Hires</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          {!planId && (
            <Col sm={12} xs={24}>
              <Form.Item
                label="Plan Name"
                name="planName"
                rules={[
                  { required: true, message: "Plan Name is required." },
                  noTrailingSpaceAllowedRule(),
                ]}
              >
                <Input placeholder="Enter" size="medium" />
              </Form.Item>
            </Col>
          )}
          {planId && (
            <Col sm={12} xs={24} id="effectiveDateIdParent">
              <Form.Item
                className={styles.brFormItems}
                label="Select Start Date"
                name="startDate"
                rules={[{ required: true, message: "Start Date is required." }]}
              >
                <DatePicker
                  getPopupContainer={() =>
                    document.getElementById("effectiveDateIdParent")
                  }
                  disabledDate={disabledDate}
                  className={styles.datePicker}
                  format={K.DateFormat.DashUSFormat}
                />
              </Form.Item>
            </Col>
          )}
          <Col sm={12} xs={24}>
            <Form.Item shouldUpdate={true}>
              {({ getFieldValue }) => (
                <Form.Item
                  label={
                    getFieldValue("thresholdType") ===
                    K.CommissionThresholdType.Amount
                      ? "Default Billing Threshold"
                      : "Default Hires Threshold"
                  }
                  name="defaultBillingThreshold"
                  rules={[
                    { required: true, message: "Default Billing is required." },
                  ]}
                >
                  <InputNumber placeholder="Enter" size="medium" min={0} />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <CommissionPlanRule label={"Applied Hires"} itemKey={"appliedHire"} />
          <CommissionPlanRule label={"Source Hired"} itemKey={"sourceHired"} />
          <CommissionPlanRule label={"Referrals"} itemKey={"referral"} />
          <CommissionPlanRule
            label={"Cancellations"}
            itemKey={"cancellations"}
          />
          <CommissionPlanRule label={"Hiring Event"} itemKey={"hiringEvent"} />
          <CommissionPlanRule
            label={"Rehire Referral"}
            itemKey={"reHireReferral"}
          />
          <CommissionPlanRule
            label={"Rehire Applied"}
            itemKey={"reHireApplied"}
          />
          <CommissionPlanRule
            label={"Rehire Sourced"}
            itemKey={"reHireSourced"}
          />
          <CommissionPlanRule label={"Internal"} itemKey={"internal"} />
        </Row>
      </Form>
    </Card>
  );
}
