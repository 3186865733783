import { Col, Form, InputNumber, Row, Select } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { CommissionPlanRule } from "./commissionPlanRule";

export default function ClientException({
  form,
  parentIndex,
  clientListing,
  setIsDisabled,
}) {
  const isDisabledOption = (itemId) => {
    const list =
      form.getFieldValue("clientExceptions")?.map(({ clientId }) => clientId) ??
      [];
    if (list.includes(itemId)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Row gutter={[16, 0]}>
      <Col sm={12} xs={24}>
        <Form.Item
          label="Select Client "
          name={[parentIndex, "clientId"]}
          rules={[{ required: true, message: "Client is required." }]}
        >
          <Select
            showSearch
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placeholder="Select"
            optionFilterProp="label"
            className={LayoutCss.appListingCardStatusSelect}
            allowClear
            onChange={() => {
              setIsDisabled((prevData) => !prevData);
            }}
            options={clientListing.map((item) => ({
              value: item.id,
              label: item.name,
              disabled: isDisabledOption(item.id),
            }))}
          />
        </Form.Item>
      </Col>
      <CommissionPlanRule
        label={"Applied Hires"}
        itemKey={"appliedHire"}
        parentIndex={parentIndex}
      />
      <CommissionPlanRule
        label={"Source Hired"}
        itemKey={"sourceHired"}
        parentIndex={parentIndex}
      />
      <CommissionPlanRule
        label={"Referrals"}
        itemKey={"referral"}
        parentIndex={parentIndex}
      />
      <CommissionPlanRule
        label={"Cancellations"}
        itemKey={"cancellations"}
        parentIndex={parentIndex}
      />
      <CommissionPlanRule
        label={"Hiring Event"}
        itemKey={"hiringEvent"}
        parentIndex={parentIndex}
      />
      <CommissionPlanRule
        label={"Rehire Referral"}
        itemKey={"reHireReferral"}
        parentIndex={parentIndex}
      />
      <CommissionPlanRule
        label={"Rehire Applied"}
        itemKey={"reHireApplied"}
        parentIndex={parentIndex}
      />
      <CommissionPlanRule
        label={"Rehire Sourced"}
        itemKey={"reHireSourced"}
        parentIndex={parentIndex}
      />
      <CommissionPlanRule
        label={"Internal"}
        itemKey={"internal"}
        parentIndex={parentIndex}
      />
    </Row>
  );
}
