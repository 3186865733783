import { Input, Card, Space, Button, Checkbox, Form, InputNumber } from "antd";
import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";

import { percentageFormatter } from "utilities/generalUtility";
export default function VBDComponent({
  index,
  remove,
  fields,
  pageStates,
  setPageStates,
  minthresholdValidation,
  maxthresholdValidation,
  isInfiniteValue,
  thresholdValueDisabled,
  isEdit,
}) {
  return (
    <>
      <Card
        className="threshold-card"
        headStyle={{ padding: "0 10px" }}
        bodyStyle={{ padding: "15px 40px" }}
        actions={
          fields.length - 1 === index && [
            <Checkbox
              key={index}
              disabled={isEdit}
              checked={pageStates.isUnlimited}
              onChange={(value) => isInfiniteValue(value)}
            >
              Unlimited end value
            </Checkbox>,
            <Button
              className="del-icon"
              size="large"
              key={index}
              hidden={isEdit}
              type="text"
              icon={<i className="icon-del-threshold"></i>}
              onClick={() => {
                remove(index);
                setPageStates((prev) => {
                  return {
                    ...prev,
                    isUnlimited: false,
                    isAddNewDisable: false,
                  };
                });
              }}
            ></Button>,
          ]
        }
        title={
          <>
            Threshold{" "}
            <Space className="field-wrap">
              <Form.Item
                className="mb-0"
                name={[index, "min"]}
                initialValue={1}
                rules={[
                  {
                    validator: (_, value, callback) =>
                      minthresholdValidation(_, value, callback, index),
                  },
                  {
                    required: true,
                    message: "min value is required",
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  disabled={
                    index !== 0 ||
                    thresholdValueDisabled(index) ||
                    pageStates.isUnlimited ||
                    isEdit
                  }
                  className="count-field"
                />
              </Form.Item>
              <ArrowRightOutlined />

              <Form.Item
                className="mb-0"
                name={[index, "max"]}
                rules={[
                  {
                    validator: (_, value, callback) =>
                      maxthresholdValidation(_, value, callback, index),
                  },
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                {fields.length - 1 === index && pageStates.isUnlimited ? (
                  <Input
                    className="count-field"
                    disabled={pageStates.isUnlimited || isEdit}
                    style={{ fontSize: 23 }}
                  ></Input>
                ) : (
                  <InputNumber
                    min={1}
                    className="count-field"
                    disabled={
                      pageStates.isUnlimited ||
                      thresholdValueDisabled(index) ||
                      isEdit
                    }
                    onChange={(e) => {
                      console.log("value", e);
                      //check value is empty or not
                    }}
                  />
                )}
              </Form.Item>
            </Space>
          </>
        }
      >
        {pageStates.selectedTiers?.map((tier) => (
          <Form.Item
            key={tier.id}
            name={[index, "tiers", `${tier.id}`]}
            initialValue={0}
            rules={[{ required: true, message: "Discount value is required" }]}
          >
            <InputNumber
              disabled={isEdit}
              max={100}
              formatter={percentageFormatter}
              className="ptage-field"
              size="large"
              step={"0.10"}
            />
          </Form.Item>
        ))}
      </Card>
    </>
  );
}
