import K from "utilities/constants";
import { PageHeader } from "antd";
import LayoutCss from "../../layout/layout.module.scss";
import { TableauReportContainer } from "./TableauReportsContainer/tableauReportContainer";

export const InternalReporting = () => {
  return (
    <>
      <PageHeader
        ghost={false}
        title="Internal Reporting"
        className={LayoutCss.appPageHeader}
      />
      <TableauReportContainer type={K.Clients.TableauReports.Type.Dashboard} />
    </>
  );
};
