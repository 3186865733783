import { Card, Typography, Divider, Button } from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { epochTimeToDashUSFormat } from "utilities/dateUtility";
import { CommissionPlanDetails } from "./CommissionPlanDetails";

const { Title, Paragraph } = Typography;

export default function CurrentCommissionPlans({ plansDetails }) {
  const history = useHistory();
  const { planId } = useParams();

  return (
    <Card className={styles.clientBillingRulesCard}>
      <div className={"newCommissionPlan " + styles.currentBillingRule}>
        <div className={styles.cbrTitle}>
          <Button
            type="link"
            className="backButton"
            onClick={() => history.push("/configurations/commission-plans")}
          >
            <i className="icon-back-arrow"></i>Back
          </Button>
          <Title level={5}>{plansDetails.basicInfo.name}</Title>
          <Paragraph>
            <i className="icon-calendar"></i>
            {epochTimeToDashUSFormat(
              plansDetails?.currentRule?.defaultPlan?.startDate,
            )}
          </Paragraph>
        </div>
        <Divider className={styles.dividerLine} plain></Divider>
        <Button
          className={styles.addButton}
          onClick={() =>
            history.push(`/configurations/commission-plan/add-new/${planId}`)
          }
        >
          Add New Rule
        </Button>
      </div>

      <CommissionPlanDetails plan={plansDetails.currentRule.defaultPlan.plan} />
      {plansDetails.currentRule.clinetException.map((item, index) => {
        return (
          <CommissionPlanDetails
            plan={item.plan}
            clientName={item.plan.client.name}
          />
        );
      })}
    </Card>
  );
}
