import { PageHeader } from "antd";
import LayoutCss from "../../../layout/layout.module.scss";
import { ClientReportConfigsContainer } from "./clientReportConfigsContainer/clientReportConfigsContainer";

export const TableauReports = () => {
  return (
    <>
      <PageHeader
        ghost={false}
        title="Tableau Reports"
        className={LayoutCss.appPageHeader}
      />
      <ClientReportConfigsContainer />
    </>
  );
};
