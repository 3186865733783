import { AgGridReact } from "ag-grid-react";
import { Button, Card, Typography } from "antd";

import LayoutCss from "layout/layout.module.scss";

import { useSelector } from "react-redux";
import BillingApproval from "redux/models/billingApproval";
import CommissionPlan from "redux/models/commissionPlan";

import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";

import { isPermissionPresent } from "utilities/generalUtility";
import { displayDollar, displayHire } from "utilities/tableUtility";
import "../submitToBilling//submitToBilling.scss";
import { Collapse } from "../../../common/components/collapse/collapse";

const { Title } = Typography;

export default function CommissionApprovalComponent({
  currentIndexRef,
  pageStates,
  setPageStates,
  columnDefs,
  dataList,
  setDataList,
  item,
  onColumnVisible,
  fetchRecruiterTitle,
  updateColumns,
  recruiterBillingThreshold,
}) {
  const userSlice = useSelector(selectUser);

  const showModal = (index, threshold) => {
    currentIndexRef.current = index;
    recruiterBillingThreshold.current = threshold;
    setPageStates((prev) => ({
      ...prev,
      isModalVisible: true,
    }));
  };

  const onRowClicked = async (event) => {
    const history = await getBillHistory({
      type: K.Invoice.Type.Commission,
      billingId: event.data.id,
    });
    setPageStates((prev) => ({
      ...prev,
      conversionHistory: history,
      selectedRecord: event.data,
      isDetailModalVisible: true,
    }));
  };

  const getBillHistory = async (payload) => {
    try {
      const res = await BillingApproval.getSubmittedBillHistory(payload);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const calculateTotal = (thresholdType, prev, curr) => {
    if (thresholdType === K.CommissionThresholdType.Amount) {
      return prev.total + curr.thresholdMaker;
    }
    if (thresholdType === K.CommissionThresholdType.TotalHire) {
      if (
        (curr.submittedJobBill.billingRuleEntry &&
          curr.submittedJobBill.billingRuleEntry.jobBillingTypesId === 1) ||
        (curr.submittedJobBill.billingTypeName === "Hire Fee (credit)" &&
          curr.thresholdMaker < 0)
      ) {
        if (curr.thresholdMaker > 0) {
          return prev.total + 1;
        } else {
          return prev.total - 1;
        }
      }
      return prev.total;
    }
    return prev.total;
  };

  const onSelectionChanged = (
    event,
    index,
    thresholdType = K.CommissionThresholdType.Amount,
  ) => {
    const selectedRows = event.api.getSelectedRows();

    const { total, selected, recruiterId } = selectedRows.reduce(
      (prev, curr) => ({
        thresholdType: thresholdType,
        total: calculateTotal(thresholdType, prev, curr),
        selected: [...prev.selected, curr.id],
        recruiterId: curr.recrutorId,
      }),
      {
        total: 0,
        selected: [],
        recruiterId: null,
        thresholdType: K.CommissionThresholdType.Amount,
      },
    );
    setPageStates((prev) => ({
      ...prev,
      checkedRows: {
        ...prev.checkedRows,
        [index]: { total, selected, recruiterId, thresholdType },
      },
    }));
  };

  const getCommissionApprovals = async (recruiterID) => {
    setDataList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await CommissionPlan.getCommissionApproval(recruiterID, true);
      const config = {};

      res.forEach((element) => {
        config[element.recruiterBasic.id] = {
          total: 0,
          recruiterId: null,
          selected: [],
        };
      });

      setPageStates((prev) => {
        return {
          ...prev,
          listing: { ...prev.listing, [`${res[0].recruiterBasic.id}`]: res[0] },
          checkedRows: { ...prev.checkedRows, ...config },
        };
      });
    } catch (err) {
      console.error(err);
    }
    setDataList((prev) => ({ ...prev, loading: false }));
  };

  const fetchDataFromServer = (recruiterID) => {
    if (recruiterID.length > 0) {
      getCommissionApprovals(recruiterID);
    }
  };

  return (
    <Collapse onChange={fetchDataFromServer}>
      <Collapse.Panel header={fetchRecruiterTitle(item?.id)} key={item?.id}>
        {dataList.loading && pageStates.listing[item?.id]?.recruiterBasic ? (
          <Card loading />
        ) : (
          <Card
            bodyStyle={{ padding: 0 }}
            headStyle={{ padding: 0 }}
            className={
              "clientBillingCard table-pagination " + LayoutCss.appCard
            }
            bordered={false}
            title={
              pageStates.listing[item?.id]?.thresholdType ===
              K.CommissionThresholdType.Amount
                ? `Default Billing Threshold: ${displayDollar(
                    pageStates.listing[item?.id]?.defaultBillingThreshold,
                  )}`
                : `Default Hire Threshold:
              ${displayHire(
                pageStates.listing[item?.id]?.defaultBillingThreshold,
              )}`
            }
            extra={
              <div className="clientBilingBtn">
                <Title level={5} className="mb-0">
                  Total:{" "}
                  <span
                    className={
                      pageStates.listing[item?.id]?.defaultBillingThreshold >
                      pageStates.checkedRows[item?.id]?.total
                        ? "rag-red"
                        : "rag-green"
                    }
                  >
                    {pageStates.listing[item?.id]?.thresholdType ===
                    K.CommissionThresholdType.TotalHire
                      ? displayHire(pageStates.checkedRows[item.id]?.total)
                      : displayDollar(pageStates.checkedRows[item.id]?.total)}
                  </span>
                </Title>
                {isPermissionPresent(
                  K.Permissions.CreateCommissionStatement,
                  userSlice.roles,
                ) && (
                  <Button
                    type="primary"
                    className={LayoutCss.appHeaderBtn}
                    onClick={() =>
                      showModal(
                        pageStates.listing[item.id]?.recruiterBasic.id,
                        pageStates.listing[item.id]?.defaultBillingThreshold ??
                          null,
                      )
                    }
                    disabled={!pageStates.checkedRows[item.id]?.selected.length}
                  >
                    Create Statement
                  </Button>
                )}
              </div>
            }
          >
            <div
              className="ag-theme-alpine s2-theme-style"
              style={{
                height: 675,
              }}
            >
              <AgGridReact
                onRowClicked={onRowClicked}
                onSelectionChanged={(event) =>
                  onSelectionChanged(
                    event,
                    item.id,
                    pageStates.listing[item.id]?.thresholdType,
                  )
                }
                rowSelection="multiple"
                suppressRowClickSelection
                rowData={pageStates.listing[item.id]?.recruiterBasic?.approvals}
                columnDefs={columnDefs}
                defaultColDef={K.AgGridTable.DefaultColDef}
                onColumnPinned={onColumnVisible}
                onColumnVisible={onColumnVisible}
                onColumnMoved={updateColumns}
                {...K.AgGridTable.DefaultProps}
              />
            </div>
          </Card>
        )}
      </Collapse.Panel>
    </Collapse>
  );
}
